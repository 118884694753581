import classNames from 'classnames';
import { Icon } from '../../atoms/icon';

function Socials({ networks, className, ...props }) {
  return (
    <div className={classNames('socials', className)} {...props}>
      {Object.keys(networks).map(net =>
        networks[net] ? (
          <a
            className="socials__item"
            href={networks[net]}
            key={net}
            rel="noopener noreferrer"
            target="_blank"
            title={net}
          >
            <Icon name={net} />
          </a>
        ) : null,
      )}
    </div>
  );
}

export default Socials;
