import { FC, ReactNode, Ref, useEffect, useState } from 'react';

import { Drawer } from 'vaul';

import styles from './portal.module.scss';
import classNames from 'classnames';

interface IPortalProps {
  children: ReactNode;
  direction?: 'right' | 'left' | 'top' | 'bottom';
  isOpen: boolean;
  toggle?: () => void;
  ignoreOutsideClick?: boolean;
  showIconTouch?: boolean;
  classOverlay?: string;
  classContent?: string | undefined;
  noBodyStyles?: boolean;
  snapPoints?: Array<number | string>;
  activeSnapPoint?: number | string;
  disablePreventScroll?: boolean;
  nested?: boolean;
  ref?: Ref<HTMLDivElement | null>;
}

const Portal: FC<IPortalProps> = ({
  children,
  isOpen,
  toggle,
  direction,
  classOverlay,
  classContent,
  ignoreOutsideClick = false,
  showIconTouch = true,
  nested = false,
  snapPoints = ['100%', '75%', '50%', '25%'],
  activeSnapPoint,
  ref,
  ...props
}) => {
  const [snap, setSnap] = useState<number | string | null>(
    activeSnapPoint ?? snapPoints[0],
  );

  useEffect(() => {
    if (activeSnapPoint) {
      setSnap(activeSnapPoint);
    }
  }, [activeSnapPoint]);

  return (
    <>
      <Drawer.Root
        activeSnapPoint={snap}
        direction={direction}
        modal={!ignoreOutsideClick}
        nested={nested}
        onClose={toggle}
        open={isOpen}
        setActiveSnapPoint={setSnap}
        shouldScaleBackground={false}
        snapPoints={snapPoints}
        {...props}
      >
        <Drawer.Portal>
          <Drawer.Overlay
            className={classNames(styles.drawer__overlay, classOverlay)}
            onClick={!ignoreOutsideClick ? toggle : () => {}}
          />
          <Drawer.Content
            className={classNames(styles.drawer__content, classContent)}
            ref={ref}
          >
            <div className={classNames(styles.drawer__content_body)}>
              {showIconTouch && (
                <span className={styles.drawer__content_icon} />
              )}
              {children}
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </>
  );
};

export default Portal;
