import { FC, KeyboardEvent, memo, useRef } from 'react';
import { useRouter } from 'next/navigation';

import { GProduct } from '@/types';
import {
  Button,
  Close,
  Heading,
  Icon,
  IconSvg,
  Input,
  Link,
  Placeholder,
  Product,
  ProductPlaceholder,
  Slider,
} from '@/components';
import { useFeaturedFlag, useSpacingBetweenProductCard } from '@/hooks';
import { PAGE_ROUTES } from '@/constants';
import { useUiStoreSelector } from '@/data';

import {
  useSearchProducts,
  useSearchSuggestions,
  useSearchTerm,
} from './context';

import styles from './searchheader.module.scss';
import classNames from 'classnames';

const ENTER_KEY = 'Enter';

export const CustomSearchInput = memo(() => {
  const { setState } = useUiStoreSelector(['is_search_open']);
  const { searchTerm, updateSearchTerm } = useSearchTerm();
  const router = useRouter();
  const searchBoxRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setState({ is_search_open: true });
  };

  const handleEnterKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    setState({ is_search_open: true });
    if (event.key === ENTER_KEY) {
      setState({ is_search_open: false });
      searchBoxRef?.current?.blur();
      router.push(`${PAGE_ROUTES.SHOP}?query=${searchTerm}`, { scroll: false });
    }
  };

  const handleSearch = () => {
    if (searchTerm !== '') {
      setState({ is_search_open: false });
      router.push(`${PAGE_ROUTES.SHOP}?query=${searchTerm}`, {
        scroll: false,
      });
    }
  };

  return (
    <div className={styles.search_box}>
      <Input
        autoComplete="off"
        className={styles.search_box__input}
        isSmall
        name={'searchBox'}
        onChange={e => {
          updateSearchTerm(e?.currentTarget?.value);
        }}
        onFocus={handleFocus}
        onKeyDown={handleEnterKeyPress}
        placeholder="Search"
        ref={searchBoxRef}
        type="search"
        value={searchTerm}
      />
      <Button
        className={styles.search_box__button}
        color="primary"
        onClick={() => handleSearch()}
      >
        <IconSvg name="search" />
      </Button>
    </div>
  );
});

export const CustomSearchBox = memo(() => {
  const { setState } = useUiStoreSelector(['is_search_open']);
  const { searchTerm, updateSearchTerm } = useSearchTerm();
  const router = useRouter();

  const handleEnterKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      setState({ is_search_open: false });
      router.push(`${PAGE_ROUTES.SHOP}?query=${searchTerm}`, {
        scroll: false,
      });
    }
  };

  return (
    <div className={styles.search__header}>
      <div className={styles.search__header__box}>
        <input
          autoComplete="off"
          autoFocus
          className={styles.search__header__box__input}
          name={'searchBox'}
          onChange={e => {
            updateSearchTerm(e?.currentTarget?.value);
          }}
          onKeyDown={handleEnterKeyPress}
          placeholder="Search"
          value={searchTerm}
        />
        <Close onClick={() => setState({ is_search_open: false })} />
      </div>
    </div>
  );
});

export const CustomSearchIcon = () => {
  const { setState } = useUiStoreSelector([]);

  return (
    <button
      className={styles.search__icon}
      onClick={() => {
        setState({ is_search_open: true });
      }}
    >
      <IconSvg name="search" />
    </button>
  );
};

interface ISearchBodyProps {
  seeAllHref: string;
  closeButtonHidden?: boolean;
}

export const SearchResultsBody: FC<ISearchBodyProps> = ({
  seeAllHref,
  closeButtonHidden,
}) => {
  const { isErrorProductsSuggestions } = useSearchSuggestions();
  const { setState } = useUiStoreSelector(['is_search_open']);
  const { searchTerm } = useSearchTerm();
  const { products, isLoadingProducts, isErrorProductsByTerm } =
    useSearchProducts();
  const showError = isErrorProductsSuggestions || isErrorProductsByTerm;

  return (
    <div
      className={classNames(styles['search__results-body'], {
        [styles.embedded_input]: closeButtonHidden,
      })}
    >
      {!closeButtonHidden && (
        <Close
          className={styles.search__body_action}
          onClick={() => setState({ is_search_open: false })}
        />
      )}
      {showError ? (
        <div className={styles.empty_results}>
          <Icon name="error" />
          <Heading level={5}>{"We could't load the content"}</Heading>
          <p>If the error persist please contact support</p>
        </div>
      ) : !products?.length && !isLoadingProducts ? (
        <div className={styles.empty_results}>
          <Icon name="search" />
          <Heading level={5}>No search results found</Heading>
          <p>
            {`No results match the criteria ${
              searchTerm !== '' && `( ${searchTerm} )`
            }. Remove criteria to search again`}
          </p>
        </div>
      ) : (
        <>
          <div className={styles.search__suggestions}>
            <SuggestionResult />
          </div>
          <div className={styles.search__results}>
            <SearchIndex seeAllHref={seeAllHref} />
          </div>
        </>
      )}
    </div>
  );
};

const SuggestionResult = () => {
  const { suggestions, isLoadingSuggestions } = useSearchSuggestions();
  const { setState } = useUiStoreSelector(['is_search_open']);

  const handleItemClick = () => {
    setState({ is_search_open: false });
  };

  return (
    <div className={styles.suggestion__hits}>
      <ol>
        {isLoadingSuggestions
          ? Array.from({ length: 10 }).map((_, index) => (
              <Placeholder
                className={styles['suggestion_hits-placeholder']}
                key={index}
              />
            ))
          : suggestions.slice(0, 6).map(({ name, productId }) => (
              <li className={styles.highlighted} key={productId}>
                <Link
                  href={{
                    pathname: PAGE_ROUTES.SHOP,
                    query: { query: name },
                  }}
                  onClick={handleItemClick}
                  shallow={true}
                >
                  {name}
                </Link>
              </li>
            ))}

        {!suggestions.length && <>Not available</>}
      </ol>
      <div className={styles['suggestion__hits-header']}>
        <Heading className={styles['heading-2']} level={2}>
          Top Suggestions
        </Heading>
      </div>
    </div>
  );
};

const SearchIndex = ({ seeAllHref }: { seeAllHref: string }) => {
  const { searchTerm } = useSearchTerm();
  const { products, isLoadingProducts } = useSearchProducts();
  const { is_search_open, setState } = useUiStoreSelector(['is_search_open']);

  return (
    <div className={styles.search__hits}>
      <CustomHits hits={products} isLoading={isLoadingProducts} />
      <div className={styles['search__hits-header']}>
        <Heading className={styles['heading-2']} level={2}>
          Search Result
        </Heading>
        <Link
          className={styles['products__see-all']}
          href={`${seeAllHref ?? 'deals'}?query=${searchTerm}`}
          onClick={() =>
            setState({
              is_search_open: !is_search_open,
            })
          }
          shallow
        >
          Shop All
        </Link>
      </div>
    </div>
  );
};

const CustomHits = ({
  hits,
  isLoading,
}: {
  hits: GProduct[];
  isLoading: boolean;
}) => {
  const {
    breakpoints,
    spacing_between_card,
    defaultSlidePerView,
    defaultSpaciBetween,
  } = useSpacingBetweenProductCard(true);
  const { isProductCardLargImage } = useFeaturedFlag();
  return (
    <Slider
      activatedHover
      breakpoints={breakpoints}
      centeredSlides={false}
      freeMode
      isNotPaddingMobile={spacing_between_card === 'none'}
      showSliderHeader={false}
      showSlidesOutsideContainer={true}
      slidesPerView={defaultSlidePerView}
      spaceBetween={defaultSpaciBetween}
    >
      {isLoading
        ? new Array(8)
            .fill(null)
            .map((_, index) => (
              <ProductPlaceholder
                key={index}
                largeCard={isProductCardLargImage}
              />
            ))
        : hits.map((item, index) => {
            return (
              <Product
                className={classNames(styles['search_product-card'], {
                  [styles['search_product-card-large']]: isProductCardLargImage,
                })}
                key={index}
                product={item}
              />
            );
          })}
    </Slider>
  );
};
