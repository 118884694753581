import NextLink, { LinkProps } from 'next/link';
import classNames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import { UrlObject } from 'url';

export interface ILinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
  hidden?: boolean;
  href: string | UrlObject;
  rel?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  replace?: boolean;
  scroll?: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
  style?: CSSProperties;
}

const Link: FC<ILinkProps> = ({
  children,
  className,
  hidden,
  href,
  onClick,
  onMouseEnter,
  onMouseLeave,
  replace,
  rel,
  scroll,
  shallow,
  target,
  style,
}) => {
  const cls = classNames('link', className);

  href = href ?? '/';
  const client = typeof href === 'object' || href?.startsWith('/');

  if (client) {
    return (
      <NextLink
        className={cls}
        hidden={hidden}
        href={href}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        rel={rel}
        replace={replace}
        role="button"
        scroll={scroll}
        shallow={shallow}
        style={style}
        target={target}
      >
        {children}
      </NextLink>
    );
  }

  return (
    <a
      className={cls}
      href={href as string}
      onClick={onClick}
      rel={rel}
      style={style}
      target={target}
    >
      {children}
    </a>
  );
};

export { Link };
