import { FC } from 'react';
import { asText } from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { Container, Heading, Icon, Link } from '@/components';
import { ImageWithTitleSlice } from '@/types';
import classNames from 'classnames';
import style from './imagewithtitle.module.scss';
import { getSlicePadding } from '@/utils';

const ImageWithTitle: FC<ImageWithTitleSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;

  const Image =
    slice.primary && slice.primary.image?.url ? slice.primary.image : null;

  const ctaType =
    slice.primary && slice.primary?.cta_type === 'button' ? 'primary' : 'link';

  const ctaText =
    slice.primary && slice.primary?.cta_text ? slice.primary.cta_text : null;

  const heading = slice?.primary?.heading;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames(style.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <div
        className={classNames(style.body, {
          [style.body__heading]: heading,
        })}
      >
        <Heading
          className={style.body__title}
          color={slice.primary?.text_color as string}
          level={2}
        >
          {asText(slice.primary?.title)}
        </Heading>
        {heading && (
          <Heading className={style.body__text_heading} level={3}>
            {asText(slice?.primary?.heading)}
          </Heading>
        )}
        <PrismicRichText field={slice.primary?.description} />
        <figure className={style.body__figure}>
          {Image ? (
            <PrismicNextImage
              fallbackAlt=""
              field={Image}
              fill
              style={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
        {ctaText && (
          <Link
            className={classNames({
              [style.body__button_primary]: ctaType === 'primary',
            })}
            href={slice.primary?.cta_link ?? ''}
            style={{
              background: slice.primary?.cta_background as string,
              color: slice.primary?.cta_text_color as string,
            }}
          >
            {ctaText}
          </Link>
        )}
      </div>
    </Container>
  );
};

export default ImageWithTitle;
