import { FC } from 'react';
import { Container } from '@/components';
import {
  ContactUsForm,
  ContactUsHeader,
  ContactUsMap,
  IconsRow,
} from './ContactUs.component';
import classNames from 'classnames';
import styles from './contactus.module.scss';
import { ContactUsSlice } from '@/types';
import { getSlicePadding } from '@/utils';
//import { asText } from '@prismicio/client';
//import { PrismicRichText } from '@prismicio/react';

const ContactUs: FC<ContactUsSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const mapTop = slice?.variation === 'mapTop';
  const mapLeft = slice?.variation === 'mapLeft';
  const mapDefault = slice?.variation === 'default';
  const mapVariation = mapDefault || mapLeft;
  const iconsVariation =
    slice?.variation === 'iconsRow' ||
    slice?.variation === 'formCentered' ||
    slice?.variation === 'formSideBySide';
  const formVariation =
    mapDefault ||
    mapTop ||
    mapLeft ||
    slice?.variation === 'formCentered' ||
    slice?.variation === 'formSideBySide';

  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(styles.contactus, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ background: slice.primary?.background_color as string }}
    >
      <Container
        className={classNames(styles.contactus_container, {
          [styles.contactus_container__iconrow]:
            slice?.variation === 'iconsRow',
          [styles.contactus_container__maptop]: mapTop,
          [styles.contactus_container__mapleft]: mapLeft,
        })}
      >
        {slice?.variation === 'mapTop' && <ContactUsMap {...slice} />}
        {!mapVariation && <ContactUsHeader {...slice} />}
        <div
          className={classNames(styles.contactus__body, {
            [styles.contactus__body_sidebyside]:
              slice?.variation === 'formSideBySide',
            [styles.contactus__body_centered]:
              slice?.variation === 'formCentered' || mapTop,
            [styles.contactus__body_map_top]: mapTop,
            [styles.contactus__body_map_left]: mapLeft,
            [styles.contactus__body_map_default]: mapDefault,
          })}
        >
          {formVariation && <ContactUsForm {...slice} />}
          {mapVariation && <ContactUsMap {...slice} />}
          {iconsVariation && (
            <>
              <IconsRow {...slice} />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
