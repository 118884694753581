import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import {
  FooterSectionsSlice,
  FooterSectionsSliceDefaultItem,
  FooterSectionsSliceVariation,
  IPrismicSlice,
} from '@/types';
import { formatEnUSPhoneNumber, htmlSerializerWithProps } from '@/utils';
import * as prismicH from '@prismicio/helpers';
import { Alert, Copyright, Heading, IconSvg, Subscribe } from '@/components';

import style from './footer.module.scss';
import Link from 'next/link';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import {
  ColorField,
  ImageFieldImage,
  KeyTextField,
  NumberField,
  RichTextField,
} from '@prismicio/client';
import classNames from 'classnames';
import { SubscribeBottomVariation } from './SubscribeBottomVariation';
import { DefaultVariation } from './DefaultVariation';
import { WithBackgroundImageVariation } from './WithBackgroundImageVariation';
import { LinksAndSubscribeCenterVariation } from './LinksAndSubscribeCenterVariation';

const SUBSCRIBE_BUTTON_LABEL = 'SUBSCRIBE';

const RenderFooter: FC<FooterSectionsSlice> = slice => {
  const sections: IFooterSection[] = useMemo(
    () => filterSections(slice?.items ?? []),
    [slice?.items],
  );

  if (slice.variation === 'subscribeBottom') {
    return <SubscribeBottomVariation sections={sections} slice={slice} />;
  }

  if (slice.variation === 'withBackgroundImage') {
    return <WithBackgroundImageVariation sections={sections} slice={slice} />;
  }

  if (slice.variation === 'linksAndSubscribeCenter') {
    return (
      <LinksAndSubscribeCenterVariation sections={sections} slice={slice} />
    );
  }

  return <DefaultVariation sections={sections} slice={slice} />;
};

export const FooterAddressSection = ({
  address_location,
  address_phone,
  address_email,
  address_section_title,
  header_color,
  label_color,
  useLabel,
  hasIcons,
}: {
  address_location?: KeyTextField;
  address_phone?: NumberField;
  address_email?: KeyTextField;
  address_section_title?: KeyTextField;
  header_color?: ColorField;
  label_color?: ColorField;
  label_color_hover?: ColorField;
  header_color_hover?: ColorField;
  useLabel?: boolean;
  hasIcons?: boolean;
}) => {
  const title = address_section_title ?? null;
  const url = 'https://www.google.com/search?q=';
  const formatPhoneNumber = address_phone
    ? formatEnUSPhoneNumber(address_phone.toString())
    : null;

  if (address_location || formatPhoneNumber || address_email) {
    return (
      <>
        {title && (
          <Heading
            className={classNames(style.section_title, 'footer_section_title')}
            level={4}
            style={{ color: header_color || undefined }}
          >
            {title}
          </Heading>
        )}
        <address className={style.__address_section}>
          {address_location ? (
            <div className={style.address_item}>
              {hasIcons && <IconSvg name="pin" />}
              {useLabel && <span>Address: </span>}
              <Link
                className="footer_section_link"
                href={`${url}${address_location}`}
                style={{ color: label_color || undefined }}
                target="_blank"
              >
                {address_location}
              </Link>
            </div>
          ) : null}
          {address_email ? (
            <div className={style.address_item}>
              {hasIcons && <IconSvg name="email" />}
              {useLabel && <span>Email: </span>}
              <Link
                className="footer_section_link"
                href={`mailto:${address_email}`}
                style={{ color: label_color || undefined }}
                target="_blank"
              >
                {address_email}
              </Link>
            </div>
          ) : null}
          {formatPhoneNumber ? (
            <div className={style.address_item}>
              {hasIcons && <IconSvg name="mobile" />}
              {useLabel && <span>Phone: </span>}
              <Link
                className="footer_section_link"
                href={`tel:${formatPhoneNumber}`}
                style={{ color: label_color || undefined }}
                target="_blank"
              >
                {formatPhoneNumber}
              </Link>
            </div>
          ) : null}
        </address>
      </>
    );
  }
};

export const FooterLogo = ({
  logo,
  variation,
}: {
  logo: ImageFieldImage | null | undefined;
  variation?: string;
}) => {
  if (!logo) {
    return null;
  }

  return (
    <Link
      className={classNames({
        [style.logo]: variation === 'default',
        [style.subscribe_bottom_variation__logo]:
          variation === 'subscribeBottom',
        [style.with_background_image_variation__logo]:
          variation === 'withBackgroundImage',
        [style.links_and_subscribe_center_variation__footer__body__logo]:
          variation === 'linksAndSubscribeCenter',
      })}
      href="/"
    >
      <figure>
        <PrismicNextImage
          fallbackAlt=""
          field={logo}
          height={logo?.dimensions?.height}
          quality={100}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
          width={logo?.dimensions?.width}
        />
      </figure>
    </Link>
  );
};

export const FooterLink = ({ item, header_color, label_color }) => {
  if (item?.header && !item?.link) {
    return (
      <>
        <Heading
          className={classNames(style.section_title, 'footer_section_title')}
          level={4}
          style={{ color: header_color || '' }}
        >
          {item.label}
        </Heading>
      </>
    );
  }

  return (
    <>
      <Link
        className={classNames(style.section_link, 'footer_section_link')}
        href={item.link ?? ''}
        key={item.label}
        style={{ color: label_color }}
      >
        {item.header && (
          <Heading
            className={classNames(style.section_title, 'footer_section_title')}
            level={4}
            style={{ color: header_color }}
          >
            {item.label}
          </Heading>
        )}
        {!item.header && item.label}
      </Link>
    </>
  );
};

interface Error {
  isError: boolean;
  isOpen: boolean;
  message: string;
}

interface FooterSubscribeSectionProps {
  slice: FooterSectionsSliceVariation;
  error: Error;
  setError: Dispatch<SetStateAction<Error>>;
  onSubscribe: (_email: any) => void;
}

export const FooterSubscribeSection = ({
  slice,
  error,
  setError,
  onSubscribe,
}: FooterSubscribeSectionProps) => {
  const subscribe_header = slice?.primary?.subscribe_header;
  const subscribe_header_color = slice?.primary?.subscribe_header_color;
  const subscribe_input_placeholder =
    slice?.primary?.subscribe_input_placeholder;
  const subscribe_description_color =
    slice.variation === 'default' ||
    slice.variation === 'withBackgroundImage' ||
    slice.variation === 'linksAndSubscribeCenter'
      ? slice?.primary?.subscribe_description_color
      : '';
  const subscribe_description =
    slice.variation === 'default' ||
    slice.variation === 'withBackgroundImage' ||
    slice.variation === 'linksAndSubscribeCenter'
      ? slice?.primary?.subscribe_description
      : '';

  return (
    <div className={style.footer__subscribe}>
      {(subscribe_header || subscribe_description) && (
        <div className={style.footer__subscribe_header}>
          {subscribe_header ? (
            <Heading
              className={style.subscribe__header_title}
              level={6}
              style={{
                color: subscribe_header_color ?? '',
              }}
            >
              {subscribe_header}
            </Heading>
          ) : null}
          {subscribe_description ? (
            <p
              style={{
                color: subscribe_description_color ?? '',
              }}
            >
              {subscribe_description}
            </p>
          ) : null}
        </div>
      )}
      <div className={style.footer__form}>
        <Alert
          error={error.isError}
          isOpen={error.isOpen}
          toggle={() =>
            setError({
              isError: false,
              isOpen: false,
              message: '',
            })
          }
        >
          {error.message}
        </Alert>
        <Subscribe
          buttonIcon={slice.variation === 'default' ? 'arrow-right' : ''}
          className={style.container__subscribe}
          inFooter
          inputIcon={
            slice.variation === 'withBackgroundImage' ||
            slice.variation === 'linksAndSubscribeCenter'
          }
          isStandard={
            slice.variation !== 'withBackgroundImage' &&
            slice.variation !== 'linksAndSubscribeCenter'
          }
          label={
            slice.variation === 'subscribeBottom' ||
            slice.variation === 'withBackgroundImage' ||
            slice.variation === 'linksAndSubscribeCenter'
              ? SUBSCRIBE_BUTTON_LABEL
              : ''
          }
          onSubscribe={onSubscribe}
          placeholder={subscribe_input_placeholder ?? 'Enter your email'}
        />
      </div>
      {slice.variation === 'default' && <FooterSocialSection slice={slice} />}
    </div>
  );
};

export const FooterSocialSection = ({
  slice,
}: {
  slice: FooterSectionsSliceVariation;
}) => {
  return (
    <div
      className={classNames({
        [style.subscribe_bottom_variation__socials_container]:
          slice.variation === 'subscribeBottom',
        [style.with_background_image_variation__socials_container]:
          slice.variation === 'withBackgroundImage',
        [style.__socials_container]:
          slice.variation === 'linksAndSubscribeCenter',
        [style.socials_container]: slice.variation === 'default',
      })}
    >
      <div className={style.socials}>
        {Array.from({ length: 5 }).map((_, index) => {
          const { primary } = slice;
          const netLink =
            prismicH.asLink(primary[`social_link_${index + 1}`]) ?? '';
          const icon = primary[`social_${index + 1}`];

          if (!icon || Object.entries(icon).length === 0) return null;

          return (
            <Link
              className={style.socials__item}
              href={netLink}
              key={index}
              rel="noopener noreferrer"
              target="_blank"
            >
              <figure>
                <PrismicNextImage
                  className={style.socials__icon}
                  fallbackAlt=""
                  field={icon}
                  fill
                  sizes="24px"
                  style={{
                    objectFit: 'contain',
                  }}
                />
              </figure>
            </Link>
          );
        })}
      </div>
      {slice.variation === 'default' && <FooterCompanyBrand slice={slice} />}
    </div>
  );
};

export const FooterCompanyBrand = ({
  slice,
}: {
  slice: FooterSectionsSliceVariation;
}) => {
  const { footer_image_link, footer_image } = slice.primary;
  if (!footer_image?.url) {
    return null;
  }
  return (
    <Link
      className={style.footer_image}
      href={prismicH.asLink(footer_image_link) ?? ''}
      rel="noopener noreferrer"
    >
      <figure>
        <PrismicNextImage
          fallbackAlt=""
          field={footer_image}
          fill
          sizes="72px"
          style={{
            objectFit: 'contain',
          }}
        />
      </figure>
    </Link>
  );
};

export const FooterLicense = ({
  license,
  license_text_color,
}: {
  license: RichTextField;
  license_text_color?: ColorField;
}) => {
  if (!license) {
    return null;
  }

  return (
    <div className={style.license_container}>
      <ul>
        {license?.map((item, index) => {
          return (
            <li key={index} style={{ color: license_text_color ?? '' }}>
              <span>{item?.text}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const FooterInfoLegalSection = ({
  slice,
}: {
  slice: FooterSectionsSliceVariation;
}) => {
  const {
    warning_text,
    warning_text_color,
    license,
    license_text_color,
    company,
  } = slice.primary;

  const term_and_use =
    slice.variation === 'default' ||
    slice.variation === 'withBackgroundImage' ||
    slice.variation === 'linksAndSubscribeCenter'
      ? slice?.primary?.term_and_use
      : '';
  const term_and_use_text_color =
    slice.variation === 'default' ||
    slice.variation === 'withBackgroundImage' ||
    slice.variation === 'linksAndSubscribeCenter'
      ? slice?.primary?.term_and_use_text_color
      : '';

  const isTerm =
    slice.variation === 'default' ||
    slice.variation === 'withBackgroundImage' ||
    slice.variation === 'linksAndSubscribeCenter'
      ? prismicH.asText(slice?.primary?.term_and_use)
      : undefined;
  const isWarning = prismicH.asText(slice.primary.warning_text);

  const isLicense = prismicH.asText(license);

  if (slice.variation == 'subscribeBottom') {
    return (
      <div className={style.subscribe_bottom_variation__info_legal}>
        {company && <span>{company}</span>}
        {(isLicense || slice?.primary?.footer_image?.url) && (
          <div className={style.company_info}>
            <FooterLicense
              license={license}
              license_text_color={license_text_color}
            />
            <FooterCompanyBrand slice={slice} />
          </div>
        )}

        {isTerm && (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: term_and_use_text_color,
            })}
            field={term_and_use as RichTextField}
          />
        )}
        {isWarning && (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: warning_text_color,
            })}
            field={warning_text}
          />
        )}
      </div>
    );
  }

  if (slice.variation == 'linksAndSubscribeCenter') {
    return (
      <div
        className={
          style.links_and_subscribe_center_variation__footer__body__info_legal
        }
      >
        <div className={style.logo_and_terms_container}>
          <FooterCompanyBrand slice={slice} />
          {isTerm && (
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: term_and_use_text_color,
              })}
              field={term_and_use as RichTextField}
            />
          )}
        </div>
        {isWarning && (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: warning_text_color,
            })}
            field={warning_text}
          />
        )}
        {(isLicense || slice?.primary?.footer_image?.url) && (
          <div className={style.company_info}>
            <FooterLicense
              license={license}
              license_text_color={license_text_color}
            />
          </div>
        )}
        <FooterCopyright slice={slice} />
      </div>
    );
  }

  return (
    <div className={style.footer__info_legal}>
      {isTerm && (
        <PrismicRichText
          components={htmlSerializerWithProps({
            color: term_and_use_text_color,
          })}
          field={term_and_use as RichTextField}
        />
      )}
      {isWarning !== '' && (
        <PrismicRichText
          components={htmlSerializerWithProps({
            color: warning_text_color,
          })}
          field={warning_text}
        />
      )}
      {license && (
        <ul>
          {license.map((item, index) => {
            return (
              <li key={index} style={{ color: license_text_color ?? '' }}>
                <span>{item?.text}</span>
                {index < license.length - 1 && <span>|</span>}
              </li>
            );
          })}
        </ul>
      )}
      {slice.variation === 'default' && <FooterCopyright slice={slice} />}
    </div>
  );
};

export const FooterCopyright = ({
  slice,
}: {
  slice: FooterSectionsSliceVariation;
}) => {
  const { company, rights_text_color } = slice.primary;

  return (
    <div
      className={classNames({
        [style.footer__copyright_container]: slice.variation === 'default',
        [style.subscribe_bottom_variation__footer__copyright_container]:
          slice.variation === 'subscribeBottom',
        [style.with_background_image_variation__footer__copyright_container]:
          slice.variation === 'withBackgroundImage',
        [style.links_and_subscribe_center_variation__footer__copyright_container]:
          slice.variation === 'linksAndSubscribeCenter',
      })}
    >
      <Copyright
        business={company ?? ''}
        className={style.copyright}
        colorText={rights_text_color ?? ''}
        showCards={slice.variation !== 'linksAndSubscribeCenter'}
      />
    </div>
  );
};

export const filterSections = (
  items: FooterSectionsSliceDefaultItem[],
): IFooterSection[] => {
  const subgrupos: IFooterSection[] = [];
  let subgrupoActual: IFooterSection | null = null;

  for (const elemento of items) {
    if (elemento.header) {
      subgrupoActual = { header: elemento, links: [] };
      subgrupos.push(subgrupoActual);
    } else if (subgrupoActual) {
      subgrupoActual?.links?.push(elemento);
    } else {
      subgrupoActual = { header: undefined, links: [elemento] };
      subgrupos.push(subgrupoActual);
    }
  }

  return subgrupos;
};

export { RenderFooter };

export interface IFooterSection {
  header?: FooterSectionsSliceDefaultItem;
  links?: FooterSectionsSliceDefaultItem[];
}

// We don't need this logic becasue we have only one footer type
// supported today
export const getPrismicFooterSlices = (
  body: IPrismicSlice[],
): FooterSectionsSlice =>
  body?.find(
    slice => slice?.slice_type === 'footer_sections' && slice?.primary?.active,
  ) as FooterSectionsSlice;
