import React, { Ref } from 'react';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Spinner } from '@/components';

import styles from './button.module.scss';

interface IButtonProps {
  children: ReactNode;
  color?: 'primary' | 'secondary' | 'link';
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  type?: 'button' | 'submit' | 'reset';
  sm?: boolean;
  icon?: boolean;
  className?: string;
  inFooter?: boolean;
  onClick: (_e: React.MouseEvent) => void;
  ariaLabel?: string;
  ariaExpanded?: boolean;
  'data-testid'?: string;
  ref?: Ref<HTMLButtonElement | null>;
}

const Button: FC<IButtonProps> = ({
  children,
  color,
  disabled,
  loading,
  type,
  sm,
  style,
  icon,
  className,
  inFooter,
  ariaLabel,
  ariaExpanded,
  'data-testid': dataTestID,
  onClick,
  ref,
}) => {
  const isDisabled = disabled || loading;

  return (
    <button
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
      className={classNames(
        className,
        { [styles.button]: true },
        { [styles.footer]: inFooter },
        { [styles[`button_color-${color}`]]: color },
        { [styles.button_sm]: sm },
        { [styles.button_icons]: icon },
      )}
      data-testid={dataTestID}
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
      style={style}
      type={type ?? 'button'}
    >
      <div className={styles.button__content}>
        {loading && <Spinner className={styles.spinner} sm />}
        {children}
      </div>
    </button>
  );
};

export default Button;
