import { FC } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Container } from '@/components';
import { getSlicePadding, htmlSerializer } from '@/utils';
import { DualTextSlice } from '@/types';
import styles from './dualtext.module.scss';
import classNames from 'classnames';

const DualText: FC<DualTextSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        background: slice?.primary?.background_color ?? '',
        color: slice?.primary?.font_color ?? '',
      }}
    >
      <Container className={styles.container}>
        <div
          style={{
            // eslint-disable-next-line no-undef
            textAlign: slice?.primary?.align_block_left as CanvasTextAlign,
          }}
        >
          <PrismicRichText
            components={htmlSerializer}
            field={slice?.primary?.left_block}
          />
        </div>
        <div
          style={{
            // eslint-disable-next-line no-undef
            textAlign: slice?.primary?.align_block_right as CanvasTextAlign,
          }}
        >
          <PrismicRichText
            components={htmlSerializer}
            field={slice?.primary?.right_block}
          />
        </div>
      </Container>
    </section>
  );
};

export default DualText;
