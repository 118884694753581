import { FC } from 'react';
import { CenteredHeaderSlice } from '@/types';
import { Container, Heading } from '@/components';
import styles from './centeredheader.module.scss';
import { asText, getSlicePadding } from '@/utils';
import classNames from 'classnames';

const CenteredHeader: FC<CenteredHeaderSlice> = slice => {
  const padding = slice?.primary?.custom_bottom_spacing
    ? `${slice?.primary?.custom_bottom_spacing} 0`
    : 0;

  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      className={classNames(styles.container, getSlicePadding(paddingSection))}
      style={{ padding }}
    >
      <Heading color={slice?.primary?.color ?? ''} level={1}>
        {asText(slice?.primary?.text)}
      </Heading>
    </Container>
  );
};

export default CenteredHeader;
