'use client';

import { ChangeEvent, useState } from 'react';
import { UPLOAD_SUCCESS_MESSAGE } from '@/constants';
import { omit, round } from 'lodash';
import { useFeaturedFlag, useNotify, useSelectedStore } from '@/hooks';
import { allowedAge, getCrmListIds, scrollToTop } from '@/utils';
import { formatDate, mapUpdateAccountInput } from './utils';
import {
  useAnalytics,
  useEcomStore,
  useForm,
  useGetAccount,
  useSetTreezCustomerUnverified,
  useSubscribeToCRM,
  useUpdateAccount,
  useValidator,
} from '@/data';
import {
  AccountPhoto,
  AddressMap,
  Alert,
  AuthContainer,
  Button,
  DateInput,
  Heading,
  Icon,
  Input,
  Modal,
  PhoneNumberInput,
  Spinner,
  useAuth,
  useData,
} from '@/components';
import { ProviderStoreType } from '@/types';
import { differenceInYears, isFuture, isValid, parse } from 'date-fns';

const AccountInfo = () => {
  const [state, setState] = useEcomStore();
  const { store } = useSelectedStore();
  const { allowIdVerifiationDelivery, allowIdVerifiationPickup } =
    useFeaturedFlag();

  const { notify } = useNotify();
  const { crm } = useData();
  const { isAuth, email, changePassword, updateUserAttributes, signOut } =
    useAuth();

  const { updateEndpoint } = useAnalytics();
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [feedback, setFeedback] = useState<{
    error?: boolean;
    isOpen?: boolean;
    message?: string;
  }>({});

  const { inputs, setInputs, genericChange, inputChange, dateChange } =
    useForm();
  const { inputs: passwordInputs, inputChange: passwordInputChange } =
    useForm();

  const { loading: loadingData, data: accountInfo } = useGetAccount({
    onCompleted: res => {
      setInputs({
        ...(omit(res.getAccountByEmail, 'accept_marketing') ?? {}),
        accept_marketing: state?.buyer_accepts_marketing,
        date_of_birth: formatDate(res.getAccountByEmail?.date_of_birth),
        driver_license_id_expiration_date: formatDate(
          res.getAccountByEmail?.driver_license_id_expiration_date,
        ),
      });
    },
    skip: !isAuth || updated,
    fetchPolicy: 'network-only',
    // Update inputs with current server information.
    variables: {
      email,
      ...(store?.shortName
        ? {
            provider_store: {
              id: store?.shortName,
              provider: ProviderStoreType.Treez,
            },
          }
        : {}),
    },
  });

  const minAge = allowedAge(accountInfo?.type, store?.ageRestriction);

  const { validator, setIsSubmited, isInvalid } = useValidator(
    [
      {
        field: 'first_name',
        message: 'First name is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: [{ min: 3 }],
        field: 'first_name',
        message: 'First name must have at least 3 characters',
        method: 'isLength',
        validWhen: true,
      },
      {
        field: 'last_name',
        message: 'Last name is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: [{ min: 3 }],
        field: 'last_name',
        message: 'Last name must have at least 3 characters',
        method: 'isLength',
        validWhen: true,
      },
      {
        field: 'email',
        message: 'The email format is incorrect.',
        method: 'isEmail',
        validWhen: true,
      },
      {
        field: 'phone',
        message: 'Enter your phone number.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'phone',
        message: 'The phone format is incorrect.',
        method: (phone: string) => {
          return (
            RegExp(/\+1 \(\d{3}\) \d{3}-\d{4}$/).test(phone) ||
            RegExp(/^\+1\d{10}$/).test(phone)
          );
        },
        validWhen: true,
      },
      {
        field: 'date_of_birth',
        message: 'The birthdate format is incorrect.',
        method: 'isDate',
        validWhen: true,
      },
      {
        field: 'date_of_birth',
        message: 'Enter your date of birth.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'date_of_birth',
        message: `Should be at least ${minAge} years old.`,
        method: current => {
          return differenceInYears(new Date(), new Date(current)) >= minAge;
        },
        validWhen: true,
      },
      {
        field: 'driver_license_id_expiration_date',
        message: 'Driver License should not be expired.',
        method: current => {
          if (isValid(parse(current, 'P', new Date()))) {
            return isFuture(new Date(current));
          }

          return true;
        },
        validWhen: true,
      },
    ],
    inputs,
  );

  const {
    validator: passwordValidator,
    setIsSubmited: setIsPasswordSubmited,
    isInvalid: isPasswordInvalid,
  } = useValidator(
    [
      {
        args: [{ min: 8 }],
        field: 'oldpassword',
        message: 'Write at least 8 characters.',
        method: 'isLength',
        validWhen: true,
      },
      {
        args: [{ min: 8 }],
        field: 'password',
        message: 'Write at least 8 characters.',
        method: 'isLength',
        validWhen: true,
      },
      {
        args: [passwordInputs.password],
        field: 'passwordmatch',
        message: "Passwords doesn't match.",
        method: 'equals',
        validWhen: true,
      },
    ],
    passwordInputs,
  );

  const { updateAccount } = useUpdateAccount();
  const { setTreezCustomerUnverified } = useSetTreezCustomerUnverified();

  const { subscribeToCRM } = useSubscribeToCRM({
    onCompleted: () => {
      updateUserAttributes({
        'custom:ACCEPT_MARKETING': (!!inputs.accept_marketing).toString(),
      }).then(() => {
        setState({
          buyer_accepts_marketing: !!inputs.accept_marketing,
        });
      });

      notify(
        'success',
        inputs?.accept_marketing
          ? 'Thanks for subscribing!'
          : 'You successfully unsubscribe',
      );
    },
    onError: error => {
      notify('error', `error: ${error}`);
    },
  });

  const handleAcceptMarketing = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs({
      ...inputs,
      accept_marketing: e.target.checked,
    });

    const crmListIds = getCrmListIds(crm);

    subscribeToCRM({
      variables: {
        input: {
          email: inputs?.email,
          lists: crmListIds,
          source: 'ACCOUNT',
          unsubscribe: inputs?.accept_marketing,
        },
      },
    });
  };

  const showError = error => {
    setFeedback({
      error: true,
      isOpen: true,
      message:
        error.message ?? 'Account information not updated. Please, try again.',
    });
    scrollToTop();
    // eslint-disable-next-line no-console
    console.error(error);
  };

  const handleResetPassword = e => {
    e.preventDefault();

    setIsPasswordSubmited(true);
    setIsSubmited(false);
    setFeedback({});

    if (isPasswordInvalid) {
      scrollToTop();
      return;
    }

    setIsResetting(true);

    const oldPassword = passwordInputs.oldpassword;
    const newPassword = passwordInputs.password;

    changePassword(email, oldPassword, newPassword)
      .then(() => {
        setFeedback({
          isOpen: true,
          message: 'Password was reset successfully.',
        });
      })
      .catch(e => showError(e))
      .finally(() => setIsResetting(false));
  };

  // eslint-disable-next-line no-unused-vars
  const handleUpdate = e => {
    e.preventDefault();

    setIsSubmited(true);
    setIsPasswordSubmited(false);
    setFeedback({});

    if (isInvalid) {
      scrollToTop();
      return;
    }

    const phoneNumber =
      inputs?.phone && `+${inputs?.phone?.replace(/\D/g, '')}`;

    setIsLoading(true);

    // Update the account in Cognito using predefined
    // attributes.
    updateUserAttributes({
      address: inputs.address1 ?? '',
      'custom:ACCEPT_MARKETING': (!!inputs.accept_marketing).toString(),
      'custom:CITY': inputs.city ?? '',
      'custom:COUNTRY_CODE': inputs.country_code ?? '',
      'custom:GC_CUSTOMER_ID': inputs.entity_id,
      'custom:PROVINCE_CODE': inputs.province_code ?? '',
      'custom:ZIP': inputs.zip ?? '',
      family_name: inputs.last_name,
      given_name: inputs.first_name,
      birthdate: inputs.date_of_birth,
      phone_number: phoneNumber,
    })
      .then(() => {
        setUpdated(true);

        // Update endpoint in Pinpoint.
        updateEndpoint({
          address: email,
          location: {
            city: inputs.city,
            country: inputs.country_code ?? 'US',
            latitude: round(inputs.latitude, 1),
            longitude: round(inputs.longitude, 1),
            postalCode: inputs.zip,
            region: inputs.province_code,
          },
          userAttributes: {
            firstName: [inputs.first_name],
            lastName: [inputs.last_name],
            specialOffersOptOut: [inputs.accept_marketing ? 'NONE' : 'ALL'],
          },
        });

        const input = mapUpdateAccountInput(inputs);

        // Update the account in GapCommerce.
        return updateAccount({
          variables: {
            input,
          },
        });
      })
      .then(() => {
        // Update stored information.
        setState({
          ...state,
          delivery_address: {
            // These stored fields will be updated always.
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            phone: phoneNumber,

            // Stored address will be updated only
            // if there is not previous data.
            // It was commented because of addresses list
            // implementation in stored state. It will
            // store the selected address in this page and
            // next from checkout page you will be able to
            // select it.
            // ...(state.delivery_address.address1
            //     ? {}
            //     : pick(inputs, [
            //           "address1",
            //           "city",
            //           "city_code",
            //           "province",
            //           "province_code",
            //           "country",
            //           "country_code",
            //           "zip",
            //           "latitude",
            //           "longitude",
            //       ])),
          },
        });

        setFeedback({
          isOpen: true,
          message: 'Account information updated successfully.',
        });
        scrollToTop();
      })
      .catch(e => showError(e))
      .finally(() => setIsLoading(false));
  };

  const handleImageChange = (field, url, error) => {
    if (error) {
      showError({ message: error });
      return;
    }

    setIsLoading(true);

    genericChange(field, url);

    const input = mapUpdateAccountInput({
      ...inputs,
      [field]: url,
    });

    updateAccount({
      variables: {
        input,
      },
    })
      .then(() => {
        setFeedback({
          isOpen: true,
          message: UPLOAD_SUCCESS_MESSAGE,
        });

        // eslint-disable-next-line no-console
        setTreezCustomerUnverified()?.catch(e => console.error(e));

        scrollToTop();
      })
      .catch(e => showError({ message: e }))
      .finally(() => setIsLoading(false));
  };

  if (loadingData) {
    return (
      <div className="account__auth-loading">
        <Spinner>Loading...</Spinner>
      </div>
    );
  }

  return (
    <AuthContainer>
      <div className="account__auth-container">
        <Heading className="account__auth_title" level={2}>
          Account Info
        </Heading>
        <Alert
          error={!!feedback.error}
          isOpen={!!feedback.isOpen}
          toggle={() => setFeedback({})}
        >
          {feedback.message}
        </Alert>
        <form className="account__body">
          <div className="account__form-fieldset">
            <Heading className="account__form-title" level={6}>
              Personal information
            </Heading>
            <div className="account__form-body">
              <Input
                feedback={validator.first_name.message}
                inputLabel="First Name"
                isInvalid={validator.first_name.isInvalid}
                name="first_name"
                onChange={inputChange}
                placeholder="First name"
                value={inputs.first_name ?? ''}
              />
              <Input
                feedback={validator.last_name.message}
                inputLabel="Last Name"
                isInvalid={validator.last_name.isInvalid}
                name="last_name"
                onChange={inputChange}
                placeholder="Last Name"
                value={inputs.last_name ?? ''}
              />
              <Input
                disabled
                feedback={validator.email.message}
                inputLabel="Email"
                name="email"
                onChange={inputChange}
                placeholder="Email"
                type="email"
                value={email ?? ''}
              />
              <PhoneNumberInput
                feedback={validator.phone.message}
                inputLabel="Phone"
                isInvalid={validator.phone.isInvalid}
                name="phone"
                onChange={inputChange}
                placeholder="Phone number: 555-555-5555"
                value={inputs.phone ?? ''}
              />
              <DateInput
                autoComplete="off"
                feedback={validator.date_of_birth.message}
                inputLabel="Date of birth"
                isInvalid={validator.date_of_birth.isInvalid}
                name="date_of_birth"
                onChange={dateChange}
                placeholder="Date of birth"
                type="date"
                value={inputs.date_of_birth ?? ''}
              />
            </div>
            <Button
              className="account__signout-btn"
              color="secondary"
              onClick={signOut}
            >
              Sign out
            </Button>
          </div>
          <div className="account__form-fieldset">
            <Heading className="account__form-title" level={6}>
              Reset password
            </Heading>
            <div className="account__form-body">
              <Input
                autoComplete="old-password"
                feedback={passwordValidator.oldpassword.message}
                inputLabel="Old password"
                isInvalid={passwordValidator.oldpassword.isInvalid}
                name="oldpassword"
                onChange={passwordInputChange}
                placeholder="Old password"
                type="password"
                value={passwordInputs.oldpassword ?? ''}
              />
              <Input
                autoComplete="new-password"
                feedback={passwordValidator.password.message}
                inputLabel="New password"
                isInvalid={passwordValidator.password.isInvalid}
                name="password"
                onChange={passwordInputChange}
                placeholder="New password"
                type="password"
                value={passwordInputs.password ?? ''}
              />
              <Input
                autoComplete="new-password"
                feedback={passwordValidator.passwordmatch.message}
                inputLabel="Confirm new password"
                isInvalid={passwordValidator.passwordmatch.isInvalid}
                name="passwordmatch"
                onChange={passwordInputChange}
                placeholder="Confirm new password"
                type="password"
                value={passwordInputs.passwordmatch ?? ''}
              />
              <Button
                className={'account__reset-password'}
                color="primary"
                loading={isResetting}
                onClick={handleResetPassword}
              >
                Reset password
              </Button>
            </div>
          </div>
        </form>
        <div className="account__address">
          <Heading level={6}>Select an address</Heading>
          {state.addresses && (
            <AddressMap
              className="checkout__form-group"
              editable
              hideInput
              id="account-address"
              noType
              onChange={(address, addresses) => {
                setState({ addresses });
                setInputs({
                  ...inputs,
                  ...address,
                });
              }}
              setState={setState}
              state={state.addresses}
              value={inputs}
            />
          )}

          <Button
            className="account__add-address-btn"
            color="secondary"
            loading={isResetting}
            onClick={() => setIsAddressOpen(true)}
          >
            <Icon className="account__add-address-icon" name="plus" /> ADD AN
            ADDRESS
          </Button>
          <Modal
            className="account__address_modal"
            isOpen={isAddressOpen}
            sm
            toggle={() => setIsAddressOpen(false)}
          >
            <AddressMap
              editable
              id="address1"
              isAccount
              noType
              onChange={(address, addresses) => {
                setState({ addresses });
                setInputs({
                  ...inputs,
                  ...address,
                });
              }}
              setIsAddressOpen={() => setIsAddressOpen(false)}
              setState={setState}
              state={state.addresses}
              value={inputs}
            />
          </Modal>
        </div>
        <div className="account__identification-container-photo">
          <Heading level={6}>Your Identification</Heading>
          <p className="identification_description">
            Help us to increase our security. To check your ID complete the
            information below.
          </p>
          <div className="account__form-legend">
            <div className="account__form-column">
              <Input
                autoComplete="off"
                inputLabel="Driver license number"
                //feedback={validator.driver_license_id.message}
                name="driver_license_id"
                //isInvalid={validator.driver_license_id.isInvalid}
                onChange={inputChange}
                placeholder="Driver license number"
                value={inputs.driver_license_id ?? ''}
              />
              <DateInput
                autoComplete="off"
                className="identification_expiration_date"
                feedback={validator.driver_license_id_expiration_date.message}
                inputLabel="DL expiration date"
                isInvalid={
                  validator.driver_license_id_expiration_date.isInvalid
                }
                name="driver_license_id_expiration_date"
                onChange={dateChange}
                placeholder="DL expiration date"
                type="date"
                value={inputs.driver_license_id_expiration_date ?? ''}
              />
            </div>
            {(allowIdVerifiationPickup || allowIdVerifiationDelivery) && (
              <AccountPhoto
                alt="Driver licence picture"
                field="driver_license_photo"
                loading={isLoading}
                onChange={handleImageChange}
                title="Upload a photo of your drivers license or government issued ID."
                value={inputs.driver_license_photo}
              />
            )}
            {/* use css to give the botton space */}
          </div>
        </div>
        <div className="account__identification-container">
          <Heading level={6}>Notification preferences</Heading>
          <Input
            checked={inputs.accept_marketing}
            id="accept_marketing"
            name="accept_marketing"
            onChange={handleAcceptMarketing}
            type="checkbox"
            value={inputs.accept_marketing}
          >
            <label htmlFor="accept_marketing">
              Receive email promotions and updates
            </label>
          </Input>
        </div>
        <div className="account__update-footer">
          <Button
            className="account__update-btn"
            color="primary"
            loading={isLoading}
            onClick={handleUpdate}
          >
            Update account
          </Button>
        </div>
      </div>
    </AuthContainer>
  );
};

export { AccountInfo };
