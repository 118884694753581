import { FC, useEffect, useState } from 'react';
import { find, startCase, upperCase } from 'lodash';
import classNames from 'classnames';

import {
  Container,
  Heading,
  Link,
  RatingStats,
  Sharing,
  sortVariantByPrice,
} from '@/components';
import { CustomerReview, GProduct, Store, Variant } from '@/types';
import { NOT_DEFINED } from '@/constants';
import { useFeaturedFlag, useProducts } from '@/hooks';

import { ProductImages } from './ProductImages';
import { ProductBadges } from './ProductBadges';
import { ReviewsFilterProvider } from './ReviewsFilterProvider';
import { ProductFeats } from './ProductFeats';
import { ProductDescription } from './ProductDescription';
import { ProductExtraDescriptions } from './ProductExtraDescriptions';
import { ProductWarning } from './ProductWarning';
import { ProductControls } from './ProductControls';
import { AboutProduct } from './AboutProduct';
import { ProductReviewsFilters } from './ProductReviewsFilters';
import { FilteredTabReviews } from './FilteredTabReviews';
import { NewProductBogo } from './ProductBogo';
import { LabResults } from './LabResults';

interface IProductWithUrls extends GProduct {
  brand_url: string;
  category_url: string;
}

interface IProductDetailProps {
  product: IProductWithUrls;
  reviews: CustomerReview[];
  store: Store;
  isProductAvailable?: boolean;
}
const ProductDetail: FC<IProductDetailProps> = ({
  product,
  reviews,
  store,
  isProductAvailable,
}) => {
  const { products } = useProducts();
  const { disableProductReviews } = useFeaturedFlag();
  const [weightVariant, setWeightVariant] = useState<Variant>({});
  const [isSpecial, setIsSpecial] = useState(false);

  const brandLink = (
    <Link className="details__link" href={product.brand_url}>
      {product.brand_name || NOT_DEFINED}
    </Link>
  );

  const flowerTypeTextStyle =
    product?.flower_type === 'cbd' ? upperCase : startCase;

  useEffect(() => {
    const p = find(products, p => p.id == product.id);

    let wv: Variant;

    // If the product is already on the cart show the
    // selected variant
    if (p !== undefined) {
      wv = p.weightVariant;
      setWeightVariant(p.weightVariant);
    } else {
      // If the product is not in the cart show the
      // shipes variant
      const sortedWeightVariants = sortVariantByPrice(
        product?.variants as Variant[],
      );
      wv = sortedWeightVariants[0];

      setWeightVariant(sortedWeightVariants[0]);
    }

    setIsSpecial(wv?.salePrice! < wv?.price!);
  }, [products, product, weightVariant]);

  return (
    <ReviewsFilterProvider reviews={reviews}>
      <section className={'details'}>
        <Container>
          <div className={'container'}>
            <ProductImages product={product} />
            <div className={'details__body'}>
              <NewProductBogo product={product} />

              <ProductBadges
                isSpecial={isSpecial}
                product={product}
                weightVariant={weightVariant}
              />
              <span className={'details__span'}>
                {!product?.brand_name && NOT_DEFINED}
                {product?.brand_name && brandLink}
              </span>
              <Heading className="details__product_heading" level={1}>
                {product.name}
              </Heading>
              {product?.flower_type ? (
                <span
                  className={classNames(
                    'flower__type',
                    'type__default',
                    `type__${product?.flower_type
                      .toLocaleLowerCase()
                      .replace(/\s+/g, '')}`,
                  )}
                >
                  {flowerTypeTextStyle(product?.flower_type) || 'not specified'}
                </span>
              ) : null}
              <ProductFeats product={product} />
              <ProductDescription product={product} />
              <ProductExtraDescriptions product={product} />
              <ProductWarning product={product} />
              <ProductControls
                isProductAvailable={isProductAvailable}
                isSpecial={isSpecial}
                product={product}
                weightVariant={weightVariant}
              />
              <AboutProduct product={product} />
              <Heading className={'details__subtitle'} level={4}>
                Share
              </Heading>
              <Sharing
                className={'sharing'}
                include={['facebook', 'twitter', 'pinterest', 'whatsapp']}
                media={product.asset?.image as string}
                url={`${store.domain}${product.url}`}
              />
            </div>
          </div>
          <LabResults labResults={product.labResults} />
          {reviews && !disableProductReviews && (
            <div className="details__rating-reviews">
              <RatingStats
                className={'rating__component'}
                review_rating={product?.review_rating || 0}
                reviews={reviews || []}
                total_reviews={product?.reviews || 0}
              />
              <ProductReviewsFilters reviews={reviews} />
            </div>
          )}
        </Container>
        {!disableProductReviews && <FilteredTabReviews />}
      </section>
    </ReviewsFilterProvider>
  );
};

export { ProductDetail };
