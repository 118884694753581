import { FC } from 'react';
import { asText, getSlicePadding, SORT_BY_MAP } from '@/utils';
import { MenuService } from '@/data';
import { Container, Heading, Product, ProductPlaceholder } from '@/components';
import {
  useFeaturedFlag,
  useSelectedStore,
  useSpacingBetweenProductCard,
} from '@/hooks';
import {
  GProduct,
  IProductListSlice,
  LastKey,
  SortBySelectOption,
} from '@/types';
import styles from './productslist.module.scss';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

const ProductsList: FC<IProductListSlice> = slice => {
  const { store } = useSelectedStore();
  const { isProductCardLargImage } = useFeaturedFlag();
  const { spacingClass } = useSpacingBetweenProductCard();
  const initProducts = slice?.products ?? [];
  const lastKey = slice?.lastKey;
  const isFilterByStore = slice?.primary?.filter_by_store;
  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const sortBy = slice?.primary?.sort_by;
  const queryKey = ['searchProducts', store?.shortName];
  const { searchProducts } = new MenuService();

  const { data, isLoading } = useQuery({
    enabled: isFilterByStore,
    initialData: {
      products: initProducts,
      lastKey,
    },
    queryKey,
    queryFn: async (): Promise<{
      products: GProduct[];
      lastKey: LastKey;
    }> => {
      const ids = slice?.items?.map(item =>
        item?.product_id?.trim(),
      ) as string[];

      const data = await searchProducts({
        filters: { productId: { values: ids } },
        sortBy: SORT_BY_MAP[sortBy] as SortBySelectOption,
      });

      return data;
    },
  });

  const productsCollection = data?.products;

  if (!slice?.primary?.active || !productsCollection.length) {
    return null;
  }

  return (
    <Container
      as={'section'}
      className={classNames(styles.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <Heading level={2}>
        {asText(slice?.primary?.products_header) ?? 'Products'}
      </Heading>

      <div
        className={classNames(styles.content, spacingClass, {
          [styles.content__large]: isProductCardLargImage,
        })}
      >
        {isLoading
          ? new Array(20)
              .fill(null)
              .map((_, index) => (
                <ProductPlaceholder
                  key={index}
                  largeCard={isProductCardLargImage}
                />
              ))
          : productsCollection?.map((prod, index) => (
              <Product
                className={styles.content__product}
                key={index}
                product={prod}
              />
            ))}
      </div>
    </Container>
  );
};

export default ProductsList;
