import { FC } from 'react';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import { Container } from '@/components';
import { useIsMobile } from '@/hooks';
import { CodeEmbedSlice } from '@/types';
import { getSlicePadding } from '@/utils';

import styles from './codeembed.module.scss';

const CodeEmbed: FC<CodeEmbedSlice> = slice => {
  const { isMobile } = useIsMobile();

  const marginBottom = slice?.primary?.bottom_spacing ?? '';
  const isFullWidthSlice = slice?.primary?.full_width ?? '';
  const paddingSection = slice?.primary?.padding_section ?? '';
  const background = slice?.primary?.section_background_color ?? '';
  const height = slice?.primary?.height ?? '';
  const horizontalAlign = slice?.primary?.horizontal_align ?? '';
  const maxWidth = isMobile
    ? slice?.primary?.max_width_mobile ?? ''
    : slice?.primary?.max_width_desktop ?? '';

  if (!slice?.primary?.active) {
    return null;
  }

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'Left':
        return 'flex-start';

      case 'Right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        [styles.full_width]: isFullWidthSlice,
        ['spacing_bottom']: marginBottom,
      })}
      style={{ background }}
    >
      <Container>
        <div
          className={styles.embedded_content}
          dangerouslySetInnerHTML={{
            __html: prismicH.asText(slice.primary.plain_text),
          }}
          style={{
            display: 'flex',
            justifyContent: textAlign(horizontalAlign),
            maxWidth: `${maxWidth}px`,
            height: `${height}px`,
          }}
        />
      </Container>
    </section>
  );
};

export default CodeEmbed;
