import { FC } from 'react';
import { Container, Heading } from '@/components';
import { LogoTextSlice } from '@/types';
import styles from './logotext.module.scss';
import { PrismicRichText } from '@prismicio/react';
import {
  asText,
  getMaxWidthForDevice,
  getSlicePadding,
  htmlSerializerWithProps,
} from '@/utils';
import classNames from 'classnames';
import { PrismicNextImage } from '@prismicio/next';
import { useScreenWidth } from 'hooks/useScreenWidth';

const LogoText: FC<LogoTextSlice> = slice => {
  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const textColor = slice?.primary?.text_color as string;
  const backgroundColor = slice?.primary?.background_color as string;

  const descriptionMaxWidthDesktop =
    slice?.primary?.description_max_width_desktop;
  const descriptionMaxWidthMobile =
    slice?.primary?.description_max_width_mobile;
  const titleMaxWidthDesktop = slice?.primary?.title_max_width_desktop;
  const titleMaxWidthMobile = slice?.primary?.title_max_width_mobile;

  const textAlign = slice?.primary?.text_align ?? 'center';

  const logoSize = slice?.primary?.logo_size
    ? parseFloat(slice?.primary?.logo_size)
    : 75;

  const { width } = useScreenWidth();

  if (!slice?.primary?.active) {
    return null;
  }

  const descriptionMaxWidth = getMaxWidthForDevice(
    width,
    descriptionMaxWidthDesktop!,
    descriptionMaxWidthMobile!,
  );

  const titleMaxWidth = getMaxWidthForDevice(
    width,
    titleMaxWidthDesktop!,
    titleMaxWidthMobile!,
  );

  return (
    <Container
      as={'section'}
      className={classNames(styles.logo_text, getSlicePadding(paddingSection), {
        [styles.logo_text_background]: backgroundColor,
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        background: backgroundColor,
        color: textColor,
      }}
    >
      <Heading color={textColor} level={1} style={{ maxWidth: titleMaxWidth }}>
        {asText(slice?.primary?.title)}
      </Heading>
      <div className={styles.logo_container} style={{ width: logoSize }}>
        <PrismicNextImage
          fallbackAlt=""
          field={slice?.primary?.logo}
          height={slice?.primary?.logo?.dimensions?.height}
          quality={100}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
          width={slice?.primary?.logo?.dimensions?.width}
        />
      </div>

      <PrismicRichText
        components={htmlSerializerWithProps({
          color: textColor,
          maxWidth: descriptionMaxWidth,
          textAlign: textAlign,
        })}
        field={slice?.primary?.description}
      />
    </Container>
  );
};

export default LogoText;
