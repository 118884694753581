import { FC } from 'react';
import { SeparatorSlice } from '@/types';
import styles from './components.module.scss';
import classNames from 'classnames';

const ImageSeparator: FC<SeparatorSlice> = slice => {
  const { primary } = slice;
  const svgBackground = primary.pattern_image?.url || '';
  const height = primary.height || 50;
  const backgroundColor = primary.background_color || undefined;
  const backgroundSize = primary.background_size || 'cover';
  const backgroundPosition = `left ${primary.background_position || 'center'}`;
  const offset = primary.vertical_offset || 0;
  const rotation = primary.image_rotation || 0;
  const marginBottom = slice?.primary?.margin_bottom;

  if (!primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(styles.separator, {
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        height: `${height}px`,
        backgroundColor,
        backgroundImage: `url(${svgBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize,
        backgroundPosition,
        position: 'relative',
        //zIndex: zIndex,
        marginTop: `${offset}px`,
        transform: `rotate(${rotation}deg)`, // Rotación
        transformOrigin: 'center', // Punto de rotación en el centro
      }}
    />
  );
};

export default ImageSeparator;
