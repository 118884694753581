import { FC } from 'react';

import { Heading, Icon } from '@/components';

import { PrismicNextImage } from '@prismicio/next';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithCtaOnImageSide,
} from 'prismicio-types';

import style from './withctaonimagesidevariation.module.scss';
import { CtaSection, textAlign } from '../ImageWithTextSideBySide.components';
import classNames from 'classnames';

export const WithCtaOnImageSideVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithCtaOnImageSide &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const {
    content_horizontal_align,
    content_vertical_align,
    cta_description,
    image,
    image_bottom_left_border_radius,
    image_bottom_right_border_radius,
    image_top_left_border_radius,
    image_top_right_border_radius,
    item_description_color,
    item_title_color,
    all_images_border_radius,
    layout_direction,
  } = slice.primary;

  const alignText = content_horizontal_align ?? 'center';
  const layoutDirection = layout_direction ?? 'left';

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  return (
    <>
      <div
        className={classNames(style.content__item_list, {
          [style.content__item_list__left]: layoutDirection === 'left',
        })}
        style={{
          alignItems: textAlign(content_horizontal_align as string),
          textAlign: alignText,
          alignSelf: textAlign(content_vertical_align),
          ...bodyVariables,
        }}
      >
        {slice.items &&
          slice.items.map((option, index) => (
            <div className={style.content__option_item} key={index}>
              {option.title && (
                <Heading
                  className={style.title}
                  level={5}
                  style={{ color: item_title_color as string }}
                >
                  {option.title}
                </Heading>
              )}
              {option.description && (
                <p
                  className={style.description}
                  style={{ color: item_description_color as string }}
                >
                  {option.description}
                </p>
              )}
            </div>
          ))}
      </div>
      <div
        className={classNames(style.figure_container, {
          [style.figure_container__left]: layoutDirection === 'left',
        })}
      >
        <figure
          className={style.figure}
          style={{
            borderTopLeftRadius: all_images_border_radius
              ? (all_images_border_radius as number)
              : (image_top_left_border_radius as number),
            borderBottomLeftRadius: all_images_border_radius
              ? (all_images_border_radius as number)
              : (image_bottom_left_border_radius as number),
            borderTopRightRadius: all_images_border_radius
              ? (all_images_border_radius as number)
              : (image_top_right_border_radius as number),
            borderBottomRightRadius: all_images_border_radius
              ? (all_images_border_radius as number)
              : (image_bottom_right_border_radius as number),
          }}
        >
          {image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={image}
              fill
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
        <CtaSection isSingleButton slice={slice} />
        {cta_description && (
          <p
            className={style.description}
            style={{ color: item_description_color as string }}
          >
            {cta_description}
          </p>
        )}
      </div>
    </>
  );
};
