import React, { FC, memo, useEffect, useState } from 'react';
import { GProduct, Variant } from '@/types';
import { sortVariantByPrice, useData } from '@/components';
import { ProductCardSlice } from '@/types';
import { cloneDeep, find } from 'lodash';
import { ISelectOption } from '../Select/Select';
import useProducts from 'hooks/useProducts';
import {
  ProductCardDefault,
  ProductCardWithBtnHover,
  ProductCardWithHighlighted,
  ProductCardWithSmallBtn,
} from './variations';

const Product: FC<IProductProps> = ({
  className,
  product,
  analyticsListName,
  slice,
}) => {
  const sortedWeightVariants = sortVariantByPrice(
    product.variants as Variant[],
  );
  const wv = sortedWeightVariants[0];

  const { productCard } = useData();
  const { products } = useProducts();
  const [weightVariant, setWeightVariant] = useState<Variant>(wv);
  const [isSpecial, setIsSpecial] = useState(wv?.salePrice! < wv?.price!);
  const [quantity, setQuantity] = useState(0);

  let activeSlice = (productCard?.slices || []).find(
    (s: ProductCardSlice) => s?.primary?.active,
  );

  // this will be used only when the component is used from the
  // slice simulator, so we can preview on the CMS with the
  // mocked data how the card will look like
  if (slice) {
    activeSlice = slice;
  }

  useEffect(() => {
    const p = find(
      products,
      p => p.id === product.id && weightVariant?.id === p?.weightVariant?.id,
    );
    if (p) {
      setQuantity(p?.quantity);
    }
    if (!p) {
      setQuantity(0);
    }
  }, [weightVariant]);

  useEffect(() => {
    const p = find(products, p => p.id === product.id);
    let wv: Variant;

    // If the product is already on the cart show the
    // selected variant
    if (p !== undefined) {
      wv = p.weightVariant;
      setWeightVariant(p.weightVariant);
      setIsSpecial(wv?.salePrice! < wv?.price!); // we have to test this
      setQuantity(p.quantity);
    } else {
      const sortedWeightVariants = sortVariantByPrice(
        product?.variants as Variant[],
      );
      wv = sortedWeightVariants[0];
      setWeightVariant(wv);
      setIsSpecial(wv?.salePrice! < wv?.price!);
      setQuantity(0);
    }
  }, [products, product.id, product.variants]);

  const updateWeinghtVariant = (v: ISelectOption<string>) => {
    const newWeightVariant = find(product.variants, p => p?.id == v.value);

    // save the product on the
    // persisted store
    const newProducts = cloneDeep(products);

    const index = products.findIndex(p => p.id === product.id);

    setWeightVariant(newWeightVariant as Variant);

    if (index === -1) {
      return;
    }

    if (newProducts[index]) {
      newProducts[index].weightVariant = newWeightVariant as Variant;
    }

    // if (calculateTotalGrams(newProducts) > gramsLimit) {
    //   notify('error', GRAM_LIMIT_EXCEEDED_ERROR);
    //   return;
    // }
  };

  const Component = ComponentsByVariations[activeSlice?.variation ?? 'default'];

  const productCardProps: IProductCard = {
    quantity,
    isSpecial,
    className,
    product,
    analyticsListName,
    weightVariant,
    variants: sortedWeightVariants,
    updateWeinghtVariant,
  };

  return <Component {...productCardProps} />;
};

type ProductVariation = ProductCardSlice['variation'];

const ProductDefault: React.FC<IProductCard> = props => (
  <ProductCardDefault {...props} />
);

const ProductWithSmallBtn: React.FC<IProductCard> = props => (
  <ProductCardWithSmallBtn {...props} />
);

const ProductWithBtnHover: React.FC<IProductCard> = props => (
  <ProductCardWithBtnHover {...props} />
);

const ProductWithHighlighted: React.FC<IProductCard> = props => (
  <ProductCardWithHighlighted {...props} />
);

const ComponentsByVariations: Record<
  ProductVariation,
  React.FC<IProductCard>
> = {
  default: ProductDefault,
  productCardWithSmallBtn: ProductWithSmallBtn,
  productCardWithBtnHover: ProductWithBtnHover,
  productCardWithHighlighted: ProductWithHighlighted,
};

export interface IProductProps {
  className?: string;
  product: GProduct;
  analyticsListName?: string;
  slice?: ProductCardSlice;
}

export interface IProductCard extends IProductProps {
  isSpecial: boolean;
  quantity: number;
  weightVariant: Variant;
  variants: Variant[];
  updateWeinghtVariant: (_option: ISelectOption<string>) => void;
}

export default memo(Product);
