/* eslint-disable max-len */
import { FC, memo, useMemo, useState } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Container, IconSvg, Link } from '@/components';
import { useIsMobile, useIsServerSide, useSelectedStore } from '@/hooks';
import { isCurrentDateInRange, isNullish } from '@/utils';
import {
  CarouselSlice,
  CarouselSliceCarouselFourImagesPerViewItem,
  Simplify,
} from '@/types';
import { useScreenWidth } from '@/hooks';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import style from './hero.module.scss';

const HeroCarousel: FC<CarouselSlice> = slice => {
  const { width } = useScreenWidth();
  const { isMobile } = useIsMobile();
  const { store } = useSelectedStore();
  const { isServerSide } = useIsServerSide();
  const currentStoreUrl = store?.url_slug;
  const carouselDefault = slice?.variation === 'carouselSingleImagePerView';
  const carousel3Images = slice?.variation === 'carouselThreeImagesPerView';
  const carousel4Images = slice?.variation === 'carouselFourImagesPerView';
  const background = slice?.primary?.background_color ?? '';
  const fullWidth = slice?.primary?.full_width;
  const imgEffect = slice?.primary?.effect_zoom_images;
  const withousVerticalSpace = slice?.primary?.withous_vertical_space;
  const maxHeigthDesktop = carouselDefault
    ? slice?.primary?.max_height_desktop
    : 0;
  const maxHeigthMobile = carouselDefault
    ? slice?.primary?.max_height_mobile
    : 0;
  const borderRadius =
    width > 767 && !fullWidth ? `${slice?.primary?.border_radius}px` : 0;

  const carouselItems = useMemo(
    () => slice?.items.filter(filterCarouselItems(currentStoreUrl)),
    [slice?.items, currentStoreUrl],
  );
  const numberOfItems = carouselItems.length;

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(
    null,
  );

  const currentBannerTop = useMemo(
    () => carouselItems[((currentSlideIndex as number) + 1) % numberOfItems],
    [currentSlideIndex, carouselItems],
  );

  const currentBannerBottom = useMemo(
    () => carouselItems[((currentSlideIndex as number) + 2) % numberOfItems],
    [currentSlideIndex, carouselItems],
  );

  const currentBannerThree = useMemo(
    () => carouselItems[((currentSlideIndex as number) + 3) % numberOfItems],
    [currentSlideIndex, carouselItems],
  );

  const handleSwiperInit = (swiper: ISwiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  const handleSlideChange = (swiper: ISwiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  const getMaxHeight = () => {
    const paddingTop = isMobile ? '58%' : '33.33%';

    if (isMobile && maxHeigthMobile) {
      return `min(${maxHeigthMobile}px, ${paddingTop})`;
    }

    if (!isMobile && maxHeigthDesktop) {
      return `min(${maxHeigthDesktop}px, ${paddingTop})`;
    }

    return undefined;
  };

  const getImage = element => {
    if (carousel3Images) {
      return element?.background_image;
    }

    return element?.background_image?.mobile;
  };

  const renderImage = (banner, sizes, secondary, link, key) => {
    let image = <IconSvg name="image" />;

    if (banner?.url) {
      image = (
        <PrismicNextImage
          fallbackAlt=""
          field={banner}
          fill
          priority={!secondary}
          sizes={sizes}
          style={{ borderRadius, objectFit: 'cover', objectPosition: 'center' }}
        />
      );
    }

    return (
      <Link
        className={classNames({ [style.banner__secondary]: secondary })}
        href={link ?? '/'}
        key={key}
      >
        <figure
          className={classNames({
            [style.banner__figure_simple]:
              (carouselDefault || carousel4Images) && !secondary,
            [style.banner__figure]: carousel3Images && !secondary,
            [style.banner__secondary_v]: carousel3Images && secondary,
            [style.banner__secondary_h]: carousel4Images && secondary,
          })}
          style={{ borderRadius, paddingTop: getMaxHeight() }}
        >
          {image}
        </figure>
        {!secondary && (
          <figure
            className={style.banner__figure_mobile}
            style={{ paddingTop: getMaxHeight() }}
          >
            {banner?.url ? (
              <PrismicNextImage
                fallbackAlt=""
                field={banner?.mobile}
                fill
                priority
                sizes="(min-width: 640px) 610px, 100wv"
                style={{ objectFit: 'cover', objectPosition: 'center center' }}
              />
            ) : (
              <IconSvg name="image" />
            )}
          </figure>
        )}
      </Link>
    );
  };

  if (!slice?.primary?.active) return null;

  const sizes = carouselDefault
    ? '(min-width: 2048px)  2008px, (min-width: 1536px)  1496px, (min-width: 1280px)  1240px, (min-width: 1024px) 984px, (min-width: 768px) 728px, 100wv'
    : '(min-width: 2048px) 1296px, (min-width: 1536px) 960px, (min-width: 1280px) 786px, (min-width: 1024px) 602px, 100wv';

  const sizesImageSecondary =
    '(min-width: 2048px) 688px, (min-width: 1536px) 512px, (min-width: 1280px) 430px, (min-width: 1024px) 358px, 0vw';

  return (
    <section
      className={classNames(style.hero, {
        [style.hero__withous_margin]: carouselDefault && withousVerticalSpace,
        [style.hero__with_margin]: !withousVerticalSpace,
        [style.hero__spacing_bottom]: slice?.primary?.bottom_spacing,
        [style.hero__background_image]: carousel4Images || carousel3Images,
      })}
      key={slice?.variation}
      style={{ background }}
    >
      <Container
        className={classNames(style.container__clear, {
          [style.container__grid]: !carouselDefault,
          [style.container__grid_tree_columns]: carousel4Images,
          [style.container__full_width]: fullWidth,
          [style.container__effect_img]: imgEffect,
          [style.container__grid_full]: fullWidth && !carouselDefault,
        })}
      >
        <div
          className={classNames({
            [style.banner__main_full]: carouselDefault,
            [style.banner__main]: carousel3Images,
            [style.banner__main_four_columns]: carousel4Images,
          })}
        >
          {isServerSide ? (
            renderImage(
              carouselItems?.[0]?.background_image,
              sizes,
              false,
              carouselItems?.[0]?.image_link,
              'banner_main_0',
            )
          ) : (
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                stopOnLastSlide: false,
              }}
              className={classNames(style.swipper__hero_carousel, {
                swiper_pagination_container: carouselDefault,
              })}
              effect="fade"
              loop
              modules={[Autoplay, EffectFade, Navigation, Pagination]}
              onAfterInit={handleSwiperInit}
              onSlideChange={handleSlideChange}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
            >
              {carouselItems.map((banner, index) => (
                <SwiperSlide key={index}>
                  {!isNullish(currentSlideIndex) &&
                    renderImage(
                      banner.background_image,
                      sizes,
                      false,
                      banner.image_link,
                      `banner_main_${currentSlideIndex}`,
                    )}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        {!carouselDefault && (
          <>
            {carousel4Images &&
              renderImage(
                getImage(currentBannerThree),
                sizesImageSecondary,
                true,
                currentBannerThree?.image_link,
                `banner_three_${currentSlideIndex}`,
              )}
            {renderImage(
              getImage(
                carousel3Images ? currentBannerTop : currentBannerBottom,
              ),
              sizesImageSecondary,
              true,
              currentBannerTop?.image_link,
              `banner_one_${currentSlideIndex}`,
            )}
            {renderImage(
              getImage(
                carousel3Images ? currentBannerBottom : currentBannerTop,
              ),
              sizesImageSecondary,
              true,
              currentBannerBottom?.image_link,
              `banner_two_${currentSlideIndex}`,
            )}
          </>
        )}
      </Container>
    </section>
  );
};

const filterCarouselItems =
  (currentStoreUrl?: string) =>
  (item: Simplify<CarouselSliceCarouselFourImagesPerViewItem>) => {
    const { location_slug: slug, start_date: start, end_date: end } = item;
    const currentDay = format(new Date(), 'eeee');
    const isItemDayValid = [null, 'Everyday', currentDay].includes(item.day);
    const isItemDateInRange = isCurrentDateInRange(start, end);
    const isItemLocationValid = [null, currentStoreUrl].includes(slug);

    return (
      item.active && isItemDayValid && isItemDateInRange && isItemLocationValid
    );
  };

export default memo(HeroCarousel);
