import { FC } from 'react';

import { Container, Heading, Icon, Link } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { CardFeatureTextSlice } from '@/types';
import classNames from 'classnames';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import style from './cardfeaturetext.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import { FilledImageFieldImage } from '@prismicio/client';
import { ImageFieldImage } from '@prismicio/client';

/* eslint-disable-next-line max-len */
export const CardFeatureText: FC<CardFeatureTextSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section ?? '';
  const borderBottom = slice?.primary?.border_section ?? '';
  const paddingSection = slice?.primary?.padding_section;

  const itemBorder = () => {
    if (slice?.primary?.item_border === '0') {
      return 'none';
    }
    return slice?.primary?.item_border || '';
  };

  const renderHeadingSlice = (
    text: [],
    level: number,
    color: string,
    classCSS: string,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <Heading className={classCSS} color={color} level={level}>
        {prismicH.asText(text)}
      </Heading>
    );
  };

  const renderDescriptionSlice = (description: [], color: string) => {
    if (!prismicH.asText(description)) {
      return null;
    }

    return (
      <PrismicRichText
        components={htmlSerializerWithProps({
          color: color,
          textTransform: 'none',
        })}
        field={description}
      />
    );
  };

  const renderCTA = (
    type: string,
    link: string,
    text: string,
    background: string,
    textColor: string,
  ) => {
    if (!text) {
      return null;
    }

    return (
      <Link
        className={classNames({
          [style.body__button_primary]: type === 'Button',
          [style.body__button_secondary]: type === 'Secondary',
        })}
        href={link}
        style={{
          background: background,
          border: background ? `1px solid ${background}` : undefined,
          color: textColor,
        }}
      >
        {text}
      </Link>
    );
  };

  const renderSimpleImage = (
    image: FilledImageFieldImage,
    borderRadius: boolean,
    sizes: string,
    classCss: string,
    variation: string,
  ) => {
    if (variation === 'default' && !image?.url) {
      return null;
    }
    return (
      <figure
        className={classNames(classCss, {
          [style.body__figure_border_radius_under_sm]: borderRadius,
        })}
      >
        {image?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={image as ImageFieldImage}
            fill
            sizes={sizes}
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    );
  };

  /* eslint-disable max-len */
  const getVariation = slice => {
    if (
      slice.variation === 'default' ||
      slice.variation === 'cardFeatureTextWidthIcon' ||
      slice.variation === 'cardFeatureTextWidthTwoCol'
    ) {
      const alignContent = slice.primary?.center_content ? 'center' : 'left';

      return (
        <div
          className={classNames(style.content, {
            [style.content__two_columns]:
              slice.variation === 'featureTextWithTwoColumns',
          })}
          style={{
            background: slice.primary?.background_color,
          }}
        >
          {(prismicH.asText(slice.primary?.title) ||
            prismicH.asText(slice.primary?.description)) && (
            <div className={style.content__text}>
              {renderHeadingSlice(
                slice.primary?.title,
                2,
                slice.primary?.title_color,
                style.heading__large,
              )}
              {renderDescriptionSlice(
                slice.primary?.description,
                slice.primary?.description_color,
              )}
            </div>
          )}
          <div
            className={classNames(style.body__contents, {
              [style.body__contents_two_columns]:
                slice.variation === 'cardFeatureTextWidthTwoCol',
            })}
          >
            {slice.items &&
              slice.items.map((feature, index: number) => {
                const cardLink =
                  slice.variation === 'cardFeatureTextWidthIcon'
                    ? feature?.cta_link
                    : slice.variation === 'default' && !feature.button_link_text
                    ? feature?.button_link
                    : null;

                const content = (
                  <>
                    <div
                      className={classNames(style.body__item_text, {
                        [style.body__item_text_two_column]:
                          slice.variation === 'cardFeatureTextWidthTwoCol',
                      })}
                      style={{ alignItems: alignContent }}
                    >
                      <div
                        className={classNames(
                          style.body__item_text_main_content,
                          {
                            [style.body__item_text_two_column]:
                              slice.variation === 'cardFeatureTextWidthTwoCol',
                          },
                        )}
                        style={{ alignItems: alignContent }}
                      >
                        {(slice.variation === 'cardFeatureTextWidthIcon' ||
                          slice.variation === 'default') &&
                          renderSimpleImage(
                            feature.icon,
                            false,
                            '64px',
                            style.body__figure_single,
                            slice?.variation || '',
                          )}
                        {renderHeadingSlice(
                          feature.heading,
                          4,
                          slice.primary.item_heading_color,
                          style.headding__small,
                        )}
                        {renderDescriptionSlice(
                          feature.description,
                          slice.primary.item_description_color,
                        )}
                      </div>
                      {renderCTA(
                        'link',
                        feature.button_link,
                        feature.button_link_text,
                        '',
                        slice.primary?.Item_button_link_color,
                      )}
                    </div>
                    {slice.variation === 'cardFeatureTextWidthTwoCol' && (
                      <Link href={'/'}>
                        <figure
                          className={classNames(style.body__icon_action, {
                            [style.body__figure_border_radius_under_sm]: true,
                          })}
                        >
                          <Icon name="angle-right" />
                        </figure>
                      </Link>
                    )}
                  </>
                );

                return (
                  <div
                    className={classNames(
                      style.body__item,
                      {
                        [style.body__item_row]:
                          slice.variation === 'cardFeatureTextWidthTwoCol',
                      },
                      { [style.body__item_border]: !itemBorder() },
                    )}
                    key={index}
                    style={{
                      alignItems: alignContent,
                      textAlign: alignContent,
                      border: itemBorder(),
                    }}
                  >
                    {cardLink ? (
                      <Link
                        className={style.body__item_link}
                        href={cardLink}
                        key={index}
                      >
                        {content}
                      </Link>
                    ) : (
                      content
                    )}
                  </div>
                );
              })}
            {slice.variation === 'headingAndFeatureText' && (
              <>
                {renderDescriptionSlice(
                  slice.primary?.feature,
                  slice.primary?.feature_color,
                )}
                <div className={style.body__actions}>
                  {renderCTA(
                    slice.primary?.button_type,
                    slice.primary?.button_link,
                    slice.primary?.button_text,
                    slice.primary?.button_background,
                    slice.primary?.button_text_color,
                  )}
                  {renderCTA(
                    'Secondary',
                    slice.primary?.secondary_button_link,
                    slice.primary?.secondary_button_text,
                    slice.primary?.button_background,
                    slice.primary?.button_background,
                  )}
                </div>
              </>
            )}
          </div>
          {slice.variation === 'cardFeatureTextWidthIcon' &&
            renderCTA(
              'Button',
              slice.primary?.button_link,
              slice.primary?.button_text,
              slice.primary?.button_background,
              slice.primary?.button_text_color,
            )}
        </div>
      );
    }

    if (slice.variation === 'cardFeatureTextWidthTwoColAndTitle') {
      return (
        <div
          className={style.content}
          style={{
            background: slice.primary?.background_color,
          }}
        >
          <div className={style.content__text}>
            {renderHeadingSlice(
              slice.primary?.title,
              2,
              slice.primary?.title_color,
              style.heading__large,
            )}
            {renderDescriptionSlice(
              slice.primary?.description,
              slice.primary?.description_color,
            )}
          </div>
          <div
            className={classNames(style.body__contents_two_columns_title, {
              [style.body__contents_two_columns_title_border]:
                slice.primary?.border || false,
            })}
            style={{ border: slice.primary?.border || 'none' }}
          >
            <div className={style.body__item_border_less} key={'left'}>
              {renderHeadingSlice(
                slice.primary?.item_heading_left,
                4,
                slice.primary?.item_heading_color,
                style.headding__small,
              )}
              {renderDescriptionSlice(
                slice.primary?.item_description_left,
                slice.primary?.item_description_color,
              )}
            </div>
            <div
              className={classNames(style.body__item_border_less, {
                [style.separator]: slice.primary?.separator_color ?? false,
              })}
              key={'right'}
              style={{
                borderColor: slice.primary?.separator_color
                  ? slice.primary?.separator_color
                  : 'none',
              }}
            >
              {renderHeadingSlice(
                slice.primary?.item_heading_right,
                4,
                slice.primary?.item_heading_color,
                style.headding__small,
              )}
              {renderDescriptionSlice(
                slice.primary?.item_description_right,
                slice.primary?.item_description_color,
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  /* eslint-enable max-len */

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames(style.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ borderTop, borderBottom }}
    >
      {getVariation(slice)}
    </Container>
  );
};
