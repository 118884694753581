import { differenceInYears } from 'date-fns/differenceInYears';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

// TODO - use Treez Config
const minAge = parseInt(process?.env?.NEXT_PUBLIC_MIN_AGE_ALLOWED ?? '21');

interface IUseKioskAccountFormRulesResponse {
  isFirstNameRequired: boolean;
  isLastNameRequired: boolean;
  isEmailRequired: boolean;
  isPhoneRequired: boolean;
  isDobRequired: boolean;
  formRules: any[];
}

const useKioskAccountFormRules = (): IUseKioskAccountFormRulesResponse => {
  const isFirstNameRequired =
    process?.env?.NEXT_PUBLIC_KIOSK_FIRST_NAME_REQUIRED === 'true';
  const isLastNameRequired =
    process?.env?.NEXT_PUBLIC_KIOSK_LAST_NAME_REQUIRED === 'true';
  const isEmailRequired =
    process?.env?.NEXT_PUBLIC_KIOSK_EMAIL_REQUIRED === 'true';
  const isPhoneRequired =
    process?.env?.NEXT_PUBLIC_KIOSK_PHONE_REQUIRED === 'true';
  const isDobRequired = process?.env?.NEXT_PUBLIC_KIOSK_DOB_REQUIRED === 'true';

  const requiredRules = [
    ...(isFirstNameRequired ? ['first_name'] : []),
    ...(isLastNameRequired ? ['last_name'] : []),
    ...(isPhoneRequired ? ['phone_number'] : []),
    ...(isEmailRequired ? ['email'] : []),
    ...(isDobRequired ? ['date_of_birth'] : []),
  ];

  return {
    isFirstNameRequired,
    isLastNameRequired,
    isEmailRequired,
    isPhoneRequired,
    isDobRequired,
    formRules: fieldsValidationRules.filter(({ field }) =>
      requiredRules.includes(field),
    ),
  };
};

export { useKioskAccountFormRules };

export const fieldsValidationRules = [
  {
    field: 'first_name',
    message: 'Enter your first name.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    args: [{ min: 3 }],
    field: 'first_name',
    message: 'First name must have at least 3 characters',
    method: 'isLength',
    validWhen: true,
  },
  {
    field: 'last_name',
    message: 'Enter your last name.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    args: [{ min: 3 }],
    field: 'last_name',
    message: 'Last name must have at least 3 characters',
    method: 'isLength',
    validWhen: true,
  },
  {
    field: 'phone_number',
    message: 'Enter your phone number.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'phone_number',
    message: 'The phone format is incorrect.',
    method: (phone: string) => {
      return RegExp(/\+1 \(\d{3}\) \d{3}-\d{4}$/).test(phone);
    },
    validWhen: true,
  },
  {
    field: 'email',
    message: 'The email format is incorrect.',
    method: 'isEmail',
    validWhen: true,
  },
  {
    field: 'date_of_birth',
    message: 'The birthdate format is incorrect.',
    method: 'isDate',
    validWhen: true,
  },
  {
    field: 'date_of_birth',
    message: 'Enter your date of birth.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'date_of_birth',
    message: `Should be at least ${minAge} years old.`,
    method: current => {
      return differenceInYears(new Date(), new Date(current)) >= minAge;
    },
    validWhen: true,
  },
  {
    field: 'date_of_birth',
    message: 'Invalid date',
    method: current => {
      if (!current) {
        return true;
      }
      const parsedDate = parse(current, 'P', new Date());
      const isValidDate = isValid(parsedDate);
      return isValidDate;
    },
    validWhen: true,
  },
];
