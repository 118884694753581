import { CSSProperties, FC } from 'react';
import { Container } from '@/components';
import { ContentEmbedSlice } from '@/types';
import styles from './contentembed.module.scss';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';
import { useIsMobile } from 'hooks/useIsMobile';

const ContentEmbed: FC<ContentEmbedSlice> = slice => {
  const { isMobile } = useIsMobile();

  const marginBottom = slice?.primary?.bottom_spacing ?? '';
  const paddingSection = slice?.primary?.padding_section ?? '';
  const background = slice?.primary?.background_color ?? '';
  const height = slice?.primary?.height ?? '';
  const horizontalAlign = slice?.primary?.horizontal_align ?? '';
  const maxWidth = isMobile
    ? slice?.primary?.max_width_mobile ?? ''
    : slice?.primary?.max_width_desktop ?? '';

  if (!slice?.primary?.active) {
    return null;
  }

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'Left':
        return 'flex-start';

      case 'Right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ background }}
    >
      <Container>
        <div
          className={styles.embedded_content}
          dangerouslySetInnerHTML={{
            __html: slice.primary.embed.html ?? '',
          }}
          style={
            {
              display: 'flex',
              justifyContent: textAlign(horizontalAlign),
              '--embedded-height': height,
              '--embedded-max-width': maxWidth,
            } as CSSProperties
          }
        />
      </Container>
    </section>
  );
};

export default ContentEmbed;
