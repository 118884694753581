'use client';

import {
  ComponentProps,
  FC,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  ReactElement,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';

import { PAGE_ROUTES } from '@/constants';
import { useUiStoreSelector } from '@/data';
import { useFeaturedFlag, useIsMobile, useIsServerSide } from '@/hooks';

import { useData } from '../DataProvider';
import { AccountMenu } from '../AccountMenu';
import { IconSvg } from '../IconSvg';
import { useAuth } from '../Auth';
import { ShoppingHeader } from '../ShoppingHeader';
import { FavoriteHeader } from '../FavoriteHeader';
import { Container } from '../Container';
import { Nav } from '../Nav';

import style from './header.module.scss';

const BREAKPOINT = 1024;

const HeaderContainer: FC<PropsWithChildren> = ({ children }) => (
  <header className={style.header}>{children}</header>
);

const HeaderMobile: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useIsMobile(BREAKPOINT);
  const { isServerSide } = useIsServerSide();

  if (!isMobile && !isServerSide) return null;

  return <div className={classNames(style.header_mobile)}>{children}</div>;
};

const HeaderDesktop: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useIsMobile(BREAKPOINT);
  const { isServerSide } = useIsServerSide();

  if (isMobile && !isServerSide) return null;

  return <div className={classNames(style.header_desktop)}>{children}</div>;
};

type HeaderRowChild = ReactElement<HeaderSectionProps>;

const HeaderRow: FC<
  HTMLAttributes<HTMLDivElement> & {
    children: HeaderRowChild | HeaderRowChild[];
    type?: 'flat' | 'small' | 'medium' | 'large';
    borderless?: boolean;
    withNavbar?: boolean;
    fullWidth?: boolean;
    relativePosition?: boolean;
  }
> = ({
  children,
  className,
  type = 'medium',
  borderless,
  withNavbar,
  fullWidth = false,
  relativePosition = false,
  ...props
}) => (
  <div
    className={classNames(style.header_row, style[type], className, {
      [style.borderless]: borderless,
      [style.with_navbar]: withNavbar,
      [style.position]: !!relativePosition,
    })}
    {...props}
  >
    {fullWidth ? (
      children
    ) : (
      <Container className={style.header_row__container}>{children}</Container>
    )}
  </div>
);

type HeaderSectionProps = PropsWithChildren<ComponentProps<'div'>> & {
  justify: 'start' | 'center' | 'end';
  spaceBetweenOnMultipleChildren?: boolean;
};

const HeaderSection: FC<HeaderSectionProps> = ({
  children,
  className,
  justify,
  spaceBetweenOnMultipleChildren,
  ...props
}) => (
  <div
    className={classNames(style.header_section, className, {
      [style.space_between]: spaceBetweenOnMultipleChildren,
    })}
    data-justify={justify}
    {...props}
  >
    {children}
  </div>
);

const HeaderLogo: FC<{
  fullWidth?: boolean;
  fixHeight?: boolean;
  center?: boolean;
}> = memo(({ fullWidth, fixHeight, center }) => {
  const { header } = useData();
  const { isMobile } = useIsMobile(1024);

  const logo =
    isMobile && header?.data?.mobile_logo?.url
      ? header?.data?.mobile_logo
      : header?.data?.logo;

  return (
    <Link
      className={classNames(style.logo, {
        [style.logo__fill]: !!fullWidth,
        [style.logo__fix_height]: !!fixHeight,
        [style.logo__center]: !!center,
      })}
      href={'/'}
    >
      <figure className={style.logo__main}>
        <PrismicNextImage
          fallbackAlt=""
          field={logo}
          height={logo?.dimensions?.height}
          quality={100}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
          width={logo?.dimensions?.width}
        />
      </figure>
    </Link>
  );
});

interface HeaderProfileProps {
  showLabel?: boolean;
  label?: string;
}

const HeaderProfile: FC<HeaderProfileProps> = ({
  showLabel,
  label = 'Sign In',
}) => {
  const { store } = useData();
  const { loading, isAuth } = useAuth();
  const router = useRouter();

  const handleClickLogIcon = () => {
    if (loading) {
      return;
    }
    if (!isAuth) {
      router.push(PAGE_ROUTES.SIGNIN);
      return;
    }

    router.push(PAGE_ROUTES.ACCOUNT);
  };

  if (!store?.account_setting?.active) return null;

  return (
    <>
      <button className={style.profile__action} onClick={handleClickLogIcon}>
        <IconSvg name="profile" />
        {showLabel && (
          <span className={style.profile__action_label}>
            {isAuth ? 'Profile' : label}
          </span>
        )}
      </button>
      <AccountMenu />
    </>
  );
};

const HeaderShopping = () => {
  const { isGRLimitActive } = useFeaturedFlag();

  if (isGRLimitActive) return null;

  return <ShoppingHeader />;
};

interface HeaderFavoritesProps {
  showLabel?: boolean;
  label?: string;
}

const HeaderFavorites: FC<HeaderFavoritesProps> = ({ ...props }) => {
  const { wishlistActive } = useFeaturedFlag();

  if (!wishlistActive) return null;

  return <FavoriteHeader {...props} />;
};

const HeaderNav = () => (
  <section className={style.section__nav}>
    <Nav />
  </section>
);

const HeaderBurgerNav = () => {
  const { setState } = useUiStoreSelector([]);

  return (
    <button
      aria-label="Navigation"
      className={classNames(style.profile__action, style.nav__action)}
      onClick={() => setState({ is_nav_open: true })}
    >
      <IconSvg name="nav" />
    </button>
  );
};

const Header = Object.assign(HeaderContainer, {
  Mobile: HeaderMobile,
  Desktop: HeaderDesktop,
});

export {
  Header as HeaderContainer,
  HeaderRow,
  HeaderSection,
  HeaderLogo,
  HeaderProfile,
  HeaderShopping,
  HeaderFavorites,
  HeaderNav,
  HeaderBurgerNav,
};
