const { template } = require('lodash');
const axios = require('axios');

const graphQlEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_URL;
const accountId = process.env.NEXT_PUBLIC_ACCOUNT_ID;
const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const evaluate = (val, elem) => {
  const interpolate = /{{([\s\S]+?)}}/g;

  if (!elem) {
    return;
  }

  if (typeof val === 'string') {
    return template(val, { interpolate })(elem);
  }

  const _val = Array.isArray(val) ? [...val] : { ...val };

  Object.keys(_val).forEach(function (key) {
    if (
      typeof _val[key] === 'string' ||
      (typeof _val[key] === 'object' && _val[key] !== null)
    ) {
      try {
        _val[key] = evaluate(_val[key], elem);
      } catch {
        return;
      }
    }
  });

  return _val;
};

const getStoreApps = async () => {
  const params = `account_id: "${accountId}", store_id: "${storeId}"`;

  const query = `query {
        listStoreApps(${params}, status: true){
            id
            name
            handler
            type
            ... on Redirect {
                links
            }
            ... on Surfside {
                active
                account_id
                source_id
                channel_id
            }
            ... on IDScan {
                license_key
            }
            ... on Treez {
                credentials {
                  active
                  default_store
                  headless_client_id
                  headless_client_secret
                  store_id
                  store_name
                }
            }
            ... on ReverseProxy {
                links
            }
        }
    }`;

  try {
    const {
      data: {
        data: { listStoreApps: apps },
      },
    } = await axios.post(graphQlEndpoint, { query });

    return apps;
  } catch (error) {
    console.log('retrieving redirect app', error);

    return;
  }
};

const getRedirectApp = async () => {
  const apps = await getStoreApps(graphQlEndpoint, accountId, storeId);

  return apps?.find(app => app.handler === 'redirect');
};

const getRedirectLinks = async (endpoint, accountID, storeID) => {
  const redirectApp = await getRedirectApp(endpoint, accountID, storeID);

  if (!redirectApp) return [];

  try {
    const links = JSON.parse(redirectApp.links);

    return links;
  } catch (error) {
    console.log('parsing redirect app link', error);

    return [];
  }
};

const getReverseProxyApp = async () => {
  const apps = await getStoreApps(graphQlEndpoint, accountId, storeId);

  return apps?.find(app => app.handler === 'reverse_proxy');
};

const getReverseProxyLink = async (endpoint, accountID, storeID) => {
  const reverseProxyApp = await getReverseProxyApp(
    endpoint,
    accountID,
    storeID,
  );

  if (!reverseProxyApp) return '';

  const links = reverseProxyApp.links?.[0];

  return links;
};

const getStore = async () => {
  const params = `account_id: "${accountId}", store_id: "${storeId}"`;

  // TODO: Add disabled_time_window to feature_flags after deployed to production

  const query = `query {
        getStore(${params}){
            domain    
            prismic_api_endpoint
            google_map_api_url
            store_cache_version
            ach_payment_banner_url            
            tier_plan
            show_reward_at_checkout
            feature_flags {
                allow_guest_checkout
                disable_product_reviews
                display_out_of_stock_products
                display_low_in_stock_products
                is_add_to_cart_in_kiosk_disabled
                is_grlimit_active
                is_med_and_rec_selection_active
                keep_shopping_active
                show_product_warning
                show_striketrough_price
                treez_filter_sort
                treez_filters_title_mapping
                wishlist_active
                kiosk_timeout_seconds
                kiosk_countdown_time
                kiosk_thank_you_timeout_seconds
                discount_minimum_threshold_value
                kiosk_first_name_required
                kiosk_last_name_required
                kiosk_email_required
                kiosk_phone_required
                kiosk_dob_required
                allow_cancel_order_from_confirmation_page
                allow_id_verification_for_delivery
                allow_id_verification_for_pickup
                id_verification_title
                id_verification_description
                force_to_select_store
                sb_540_regulation
                allow_medical_sign_ups
            }
        }
    }`;

  try {
    const {
      data: {
        data: { getStore: store },
      },
    } = await axios.post(graphQlEndpoint, { query });

    return store;
  } catch (error) {
    console.log('retrieving redirect app', error);

    return;
  }
};

module.exports = {
  evaluate,
  getStore,
  getStoreApps,
  getRedirectLinks,
  getReverseProxyLink,
};
