import { FC } from 'react';
import { Container, Heading, Icon, Link } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { ImageRowWithHeadingAndButtonSlice as ImageRow } from '@/types';
import classNames from 'classnames';
import style from './imagerowwithheadingandbutton.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import { getSlicePadding } from '@/utils';

/* eslint-disable-next-line max-len */
const ImageRowWithHeadingAndButton: FC<ImageRow> = slice => {
  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.margin_bottom;

  const sizes =
    /* eslint-disable-next-line max-len */
    '(min-width: 2048px) 412px, (min-width: 1536px) 304px, (min-width: 1280px) 247px, (min-width: 1024px) 208px, (min-width: 420px) 50wv, 100wv';

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames(style.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <div
        className={style.content}
        style={{
          background: slice.primary?.background ?? '',
        }}
      >
        <div
          className={classNames(style.body, {
            [style.body__square_picture]: slice.variation === 'squarePicture',
          })}
        >
          {slice.variation === 'default' && (
            <Heading
              className={style.body__text_heading}
              color={slice.primary?.heading_color ?? ''}
              level={3}
            >
              {prismicH.asText(slice.primary?.heading)}
            </Heading>
          )}
          <Heading
            className={style.body__title}
            color={slice.primary?.title_color ?? ''}
            level={2}
          >
            {prismicH.asText(slice.primary?.title)}
          </Heading>
          {slice.variation === 'squarePicture' && (
            <PrismicRichText
              components={{
                paragraph: ({ children }) => (
                  <p
                    style={{
                      color: slice.primary?.description_color ?? '',
                    }}
                  >
                    {children}
                  </p>
                ),
              }}
              field={slice.primary?.description}
            />
          )}
          <div className={style.body__contents_images}>
            {slice.items.map((img, index) => {
              return (
                <figure
                  className={classNames(style.body__figure, {
                    /* eslint-disable-next-line max-len */
                    [style.body__figure_border_radius_under_sm]:
                      !slice.primary?.background,
                  })}
                  key={index}
                >
                  {img.image?.url ? (
                    <PrismicNextImage
                      fallbackAlt=""
                      field={img.image}
                      fill
                      sizes={sizes}
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Icon name="image" />
                  )}
                </figure>
              );
            })}
          </div>
          {slice.primary?.button_text && (
            <Link
              className={classNames({
                [style.body__button_primary]:
                  slice.primary?.button_type === 'Button',
              })}
              href={slice.primary?.button_link ?? ''}
              style={{
                background: slice.primary?.button_background ?? '',
                color: slice.primary?.button_text_color ?? '',
              }}
            >
              {slice.primary?.button_text}
            </Link>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ImageRowWithHeadingAndButton;
