'use client';

/* eslint-disable max-len */
import { FC, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Close,
  Heading,
  Input,
  Modal,
  useData,
} from '@/components';
import { useEcomStore, useForm, useSubscribeToCRM, useValidator } from '@/data';
import { asText, getCrmListIds } from '@/utils';

import styles from './crmmodal.module.scss';
import { PrismicRichText } from '@prismicio/react';
import { useFixedBodyOnPopup, useNotify } from '@/hooks';

const ErrorMessage =
  // eslint-disable-next-line max-len
  'An error has occurred. Please try again, and if the problem persists, contact support.';
const SuccessMessage = 'Thanks for being awesome!';

const initialState = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  email: '',
  full_name: '',
  phone: '',
  state: '',
  terms_accepted: true,
  zip: '',
};

const CrmModal: FC = () => {
  const [alert, setAlert] = useState({
    isError: false,
    isOpen: false,
    message: '',
  });

  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useEcomStore();
  const ref = useRef<HTMLFormElement>(null);
  const { notify } = useNotify();
  const { crmModal, crm } = useData();
  const { inputs, inputChange } = useForm(initialState);
  useFixedBodyOnPopup({ isPopupOpen: state.is_crm_modal_open });

  const { validator, setIsSubmited, isInvalid } = useValidator(
    [
      {
        field: 'full_name',
        message: 'Full name is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'email',
        message: 'The email format is incorrect.',
        method: 'isEmail',
        validWhen: true,
      },
      ...(inputs.phone && inputs.phone !== ''
        ? [
            {
              args: [{ max: 10, min: 10 }],
              field: 'phone',
              message: 'The phone number format is incorrect.',
              method: 'isLength',
              validWhen: true,
            },
          ]
        : []),
    ],
    inputs,
  );

  const { subscribeToCRM } = useSubscribeToCRM({
    onCompleted: () => {
      setAlert({
        isError: false,
        isOpen: false,
        message: '',
      });
      setIsSubmited(false);
      setState({ is_crm_modal_open: false });
      setisLoading(false);
      notify('success', SuccessMessage);
    },
    onError: () => {
      setisLoading(false);
      setAlert({
        isError: true,
        isOpen: true,
        message: ErrorMessage,
      });
    },
  });

  const onSubmit = e => {
    if (ref?.current?.checkValidity()) {
      e.preventDefault();
      sendForm();
    }
  };

  const sendForm = async () => {
    if (isInvalid) {
      setIsSubmited(true);
      return;
    }

    setisLoading(true);

    try {
      // return only the list of ids becasue the subscribe
      // endpoint only accepted the list of ids
      const crmListIds = getCrmListIds(crm);

      subscribeToCRM({
        variables: {
          input: {
            address: {
              address1: inputs.address1,
              address2: inputs.address2,
              city: inputs.city,
              country: inputs.country,
              state: inputs.state,
              zip: inputs.zip,
            },
            email: inputs.email,
            first_name: inputs.full_name.split(' ')[0],
            last_name: inputs.full_name.split(' ')[1] || '',
            lists: crmListIds,
            phone: inputs.phone ? `${inputs.phone}` : null,
            source: 'POPUP',
            // klaviyo status code 400 response body {\"errors\":[{\"id\":\"7ba36aa1-441e-4d81-bd57-06b9473ad8a5\",\"status\":400,\"code\":\"invalid\",\"title\":\"Invalid input.\",\"detail\":\"Invalid or unsupported phone number\",\"source\":{\"pointer\":\"/data/attributes/phone_number\"},\"meta\":{}}]}
          },
        },
      });
    } catch (e) {
      setisLoading(false);
      setAlert({
        isError: true,
        isOpen: true,
        message: ErrorMessage,
      });
    }
  };

  const createButtonStyle = () => {
    return {
      background: crmModal?.data.button_background_color || '',
      color: crmModal?.data.button_text_color || '',
    };
  };

  if (!crmModal) {
    return null;
  }

  return (
    <Modal
      className={styles.crmmodal}
      isOpen={state.is_crm_modal_open}
      overlayBgColor={crmModal?.data?.overlay_background_color ?? ''}
      overlayOpacity={crmModal?.data?.overlay_opacity ?? 1}
      popupBgColor={crmModal?.data?.popup_background_color ?? ''}
    >
      <Close
        className={styles.close}
        onClick={() => setState({ is_crm_modal_open: false })}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading className={styles.title} level={4}>
            {asText(crmModal?.data?.title) || 'Let’s Stay In Touch'}
          </Heading>
          <p>
            {asText(crmModal?.data?.description) ||
              'Sign up now to get exclusive cannabis deals'}
          </p>
        </div>
        <Alert
          error={alert.isError}
          isOpen={alert.isOpen}
          toggle={() =>
            setAlert({
              isError: false,
              isOpen: false,
              message: '',
            })
          }
        >
          {alert.message}
        </Alert>
        <form ref={ref}>
          <Input
            feedback={validator.full_name.message}
            id="contact-full-name"
            inputLabel="Full Name"
            isInvalid={validator.full_name.isInvalid}
            name="full_name"
            onChange={inputChange}
            placeholder="John Smith"
            required
            value={inputs.full_name}
          />
          <Input
            feedback={validator.email.message}
            id="contact-email"
            inputLabel="Email"
            isInvalid={validator.email.isInvalid}
            name="email"
            onChange={inputChange}
            placeholder="example@domain.com"
            required
            type="email"
            value={inputs.email}
          />
          <Input
            feedback={validator?.phone?.message}
            id="contact-phone"
            inputLabel="Phone"
            isInvalid={validator?.phone?.isInvalid}
            name="phone"
            onChange={inputChange}
            placeholder="5555555555"
            value={inputs.phone ?? ''}
          />
          {crmModal?.data?.show_field_address_1 && (
            <Input
              id="contact-address-1"
              inputLabel="Address"
              name="address1"
              onChange={inputChange}
              placeholder="Address 1"
              value={inputs.address1}
            >
              Address 1
            </Input>
          )}
          {crmModal?.data?.show_field_address_2 && (
            <Input
              id="contact-address-2"
              inputLabel="Address 2"
              name="address2"
              onChange={inputChange}
              placeholder="Address 2"
              value={inputs.address2}
            />
          )}
          {crmModal?.data?.show_field_city && (
            <Input
              id="contact-city"
              inputLabel="City"
              name="city"
              onChange={inputChange}
              placeholder="City"
              value={inputs.city}
            />
          )}
          {crmModal?.data?.show_field_country && (
            <Input
              id="contact-country"
              inputLabel="Country"
              name="country"
              onChange={inputChange}
              placeholder="Country"
              value={inputs.country}
            />
          )}
          {crmModal?.data?.show_field_state && (
            <Input
              id="contact-state"
              inputLabel="State"
              name="state"
              onChange={inputChange}
              placeholder="State"
              value={inputs.state}
            />
          )}
          {crmModal?.data?.show_field_zip && (
            <Input
              id="contact-zip"
              inputLabel="Zip Codes"
              name="zip"
              onChange={inputChange}
              placeholder="#####"
              value={inputs.zip}
            />
          )}
          <Button
            className={styles.submit}
            color="primary"
            disabled={!inputs.terms_accepted}
            loading={isLoading}
            onClick={onSubmit}
            style={createButtonStyle()}
            type="submit"
          >
            {crmModal?.data?.button_text || 'Enter'}
          </Button>
        </form>
        <Input
          checked={inputs.terms_accepted}
          className={styles.option}
          name="terms_accepted"
          onChange={inputChange}
          type="checkbox"
        >
          <label>
            <PrismicRichText field={crmModal?.data.agreement_text} />
          </label>
        </Input>
        <Button
          className={styles.continue_link}
          color="link"
          onClick={() => setState({ is_crm_modal_open: false })}
        >
          {crmModal?.data?.cancel_link_text || 'No thanks, take me to the site'}
        </Button>
      </div>
    </Modal>
  );
};

export default CrmModal;
