import { FC } from 'react';
import classNames from 'classnames';

import { useScreenWidth } from '@/hooks';
import { Container } from '@/components';
import { ImageGallerySlice } from '@/types';

import style from './image_gallery.module.scss';
import {
  CTAButton,
  GalleryVariation,
  renderTextSlice,
} from './ImageGallery.components';
import { getSlicePadding } from '@/utils';

const ImageGallery: FC<ImageGallerySlice> = slice => {
  const { width } = useScreenWidth();
  const { primary, variation } = slice;
  const {
    heading,
    heading_color,
    title,
    title_color,
    description,
    description_color,
    bottom_spacing,
    effect_zoom_images,
    border_section,
    background_color,
    max_header_width,
    padding_section,
  } = primary;

  const paddingSection = padding_section;
  const marginBottom = bottom_spacing;
  const borderTop = border_section ?? '';
  const borderBottom = border_section ?? '';
  const maxWidtHeader =
    primary?.max_header_width && width > 428
      ? `${primary?.max_header_width}px`
      : '100%';

  const getMaxWidthdescription = () => {
    if (width < 428) {
      return '100%';
    }

    if (variation === 'imageCorousel' && primary?.max_width_description) {
      return `${primary?.max_width_description}px`;
    }

    const maxWidth = max_header_width ? `${max_header_width}px` : '100%';

    return maxWidth;
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.image_gallery,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      style={{
        backgroundColor: background_color ?? '',
        borderTop,
        borderBottom,
      }}
    >
      <Container
        className={classNames(style.image_gallery_container, {
          [style.carousel_container]: variation === 'imageCorousel',
          [style.container__effect_img]: effect_zoom_images,
        })}
      >
        {renderTextSlice(heading, heading_color ?? '', maxWidtHeader, false)}
        {renderTextSlice(
          title,
          title_color ?? '',
          maxWidtHeader,
          variation === 'imageGridWithCustomWidth',
        )}
        {renderTextSlice(
          description,
          description_color ?? '',
          getMaxWidthdescription(),
          true,
        )}
        <GalleryVariation {...slice} />
        <CTAButton {...slice} />
      </Container>
    </section>
  );
};

export { ImageGallery };
