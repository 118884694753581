import { FC } from 'react';

import { Container } from '@/components';
import { ImageRowSlice } from '@/types';

import style from './imagerow.module.scss';

import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';

const ImageRow: FC<ImageRowSlice> = slice => {
  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const items = slice.items;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.gallery__image_row,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
    >
      <Container className={style.gallery_images}>
        {items.map(({ image }, index) => {
          return (
            <figure
              className={classNames(style.gallery_image__item)}
              key={index}
            >
              <PrismicNextImage
                fallbackAlt=""
                field={image}
                style={{ objectFit: 'cover', objectPosition: 'center center' }}
                width={428}
              />
            </figure>
          );
        })}
      </Container>
    </section>
  );
};

export default ImageRow;
