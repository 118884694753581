import { CSSProperties } from 'react';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { IncomingMessage } from 'http';
import { capitalize, filter, isNil, omitBy, startCase, trim } from 'lodash';
import {
  Icon,
  Icons,
  IconURL,
} from 'next/dist/lib/metadata/types/metadata-types';

import { TEMPLATE_BASE_PATH } from '@/constants';
import {
  AccountType,
  Address,
  AgeRestriction,
  BlogPostDocument,
  CartLineItem,
  Closure,
  CollectionQueryParams,
  EnumCustomerType as CustomerType,
  DayPeriod,
  Discount,
  DiscountRateType,
  DiscountType,
  EcomState,
  EnumCustomerType,
  GeoType,
  GProduct,
  IBlogTags,
  JaneSpecial,
  JaneSpecialKind,
  LineItemInput,
  Maybe,
  OpeningHoursSpecification,
  OrderAddress,
  OrderAddressInput,
  OrderDiscountItem,
  OrderDiscountItemInput,
  OrderInput,
  PickupScheduleZone,
  Product,
  PromoCarouselDocumentDataCarouselItemsItem,
  PromoCarouselDocumentDataCarouselItemsItemWithID,
  QueryFilters,
  ReservationMode,
  ScheduleZone,
  SortByParams,
  SortBySelectOption,
  Store,
  StoreAgreement,
  StorePaymentSettings,
  StorePaymentType,
  TreezFilters,
  TreezSpecials,
  TreezStore,
  Variant,
} from '@/types';
import { SORT_BY_FIELD_OPTION } from '@/constants';
import { ILocalAddress } from '@/components';

import { evaluate as evaluateTemplate } from '../scripts/utils';
import {
  DefaultDeliverySchedules,
  DefaultPickupSchedules,
} from './defaultSchedules';
import { KeyTextField } from '@prismicio/client';
import { getSpecialLink } from './treez';

export const currencyFormat = (value: number) => {
  if (!value) {
    return 0;
  }

  const toFloat = value / 100;

  return toFloat === Math.floor(toFloat)
    ? Math.floor(toFloat)
    : toFloat.toFixed(2);
};

export const formatProductDiscountText = ({
  value,
  format = 'percent',
}: {
  value: Variant;
  format?: 'amount' | 'percent';
}): string => {
  if (!value?.discount_percent && !value?.discount_amount) {
    return '';
  }

  if (format === 'amount') {
    return `$${currencyFormat(value?.discount_amount as number)} Off`;
  }

  return `${value?.discount_percent}% Off`;
};

export const addressToSlug = (text: string) => {
  if (!text) {
    return '';
  }

  return trim(text.replace(/ /g, '+').replace(/,/g, ''));
};

export const stringToSlug = (text: string | KeyTextField) => {
  if (!text) {
    return '';
  }

  return trim(
    text
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, ''),
    '-',
  );
};

export const stringToSlugBrands = (text: string) => {
  if (!text) {
    return '';
  }

  return trim(
    text
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\s.-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, ''),
    '-',
  );
};

export const scrollToTop = () => {
  window.scrollTo({ behavior: 'smooth', top: 0 });
};

export const getOperatingHours = () => {
  // Service hours. In this case from 8am to 11pm.
  return [8, 22];
};

export const personValidationRules = [
  {
    field: 'first_name',
    message: 'First name is required.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'last_name',
    message: 'Last name is required.',
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'address1',
    message: 'Invalid or incomplete address.',
    method: (_, state) => {
      return [
        'address1',
        'city',
        'country_code',
        'zip',
        'latitude',
        'longitude',
      ].every(field => state[field]);
    },
    validWhen: true,
  },
];

export const evaluate = <T>(val: T, elem): T => {
  return evaluateTemplate(val, elem);
};

export const formatWeightNumber = v => {
  return mapWeightLabelToGrams(v?.type);
};

const mapWeightLabelToGrams = (type: string) =>
  ({
    each: 0,
    eighth_ounce: 3.5,
    gram: 1,
    half_gram: 0.5,
    half_ounce: 14,
    ounce: 28,
    quarter_ounce: 7,
    two_gram: 2,
  }[type] ?? +type?.split('_')[0]);

export const formatNumber = number => {
  return Number(number).toLocaleString();
};

export const getServerUrl = ({ url, headers }: IncomingMessage) => {
  const protocol = headers.referer?.split('://')[0] || 'https';
  return `${protocol}://${headers.host}${url}`;
};

export const sanitizeObject = (object: Object) => {
  return omitBy(
    Object.keys(object || {}).reduce((acc, key) => {
      const currentValue = object[key];
      if (isNil(currentValue)) {
        return acc;
      }
      if (Array.isArray(currentValue)) {
        return {
          ...acc,
          [key]:
            typeof currentValue[0] === 'object'
              ? currentValue.map(sanitizeObject)
              : filter(currentValue, value => !isNil(value)),
        };
      }
      if (currentValue !== null && typeof currentValue === 'object') {
        return {
          ...acc,
          [key]: sanitizeObject(currentValue),
        };
      }
      return { ...acc, [key]: currentValue };
    }, {}),
    isNil,
  );
};

export const shouldHideNavigation = (path: string): boolean => {
  return ['/checkout', '/thank-you', '/checkin'].includes(path);
};

export const createLocationUrl = (path: string): string => {
  const location = TEMPLATE_BASE_PATH.LOCATION;

  return `${location}${path}`;
};

export const getRichTextText = (richText: any): string => {
  return richText?.[0]?.text || '';
};

export const getTreezSeoAddress = (store: TreezStore): Address => {
  const [streetAddress, addressLocality, addressCountry, postalCode] =
    store.full_address.split(',');

  return {
    addressCountry,
    addressLocality,
    postalCode,
    streetAddress,
  };
};

type seoHours = OpeningHoursSpecification | OpeningHoursSpecification[];

export const getTreezSeoOpeningHours = (store: TreezStore): seoHours => {
  const specification: seoHours = store?.delivery_hours?.map(
    (hours: DayPeriod) => {
      return {
        closes: hours.period.to,
        dayOfWeek: hours.day,
        opens: hours.period.from,
      };
    },
  );

  return specification;
};

export const getFirstPTextAsDescrition = (richText: any): string => {
  let description: string =
    richText?.find(content => {
      return content.type === 'paragraph' && content.text;
    })?.text || '';

  if (description.length > 100) {
    description = description?.substring(0, 100).concat('...');
  }

  return description;
};

export const numberToShortString = (number: number): string => {
  if (number < 1000) {
    return String(number);
  }
  if (number < 1000000) {
    return `${(number / 1000).toFixed(1)}K`;
  }
  if (number < 1000000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  if (number < 1000000000000) {
    return `${(number / 1000000000).toFixed(1)}B`;
  }

  return undefined as unknown as string;
};

export const getBlogsTags = (blogs: BlogPostDocument<string>[]): IBlogTags => {
  const tags: IBlogTags = {};

  if (!blogs || !blogs.length) {
    return tags;
  }

  blogs.forEach(article => {
    if (article.tags.length === 0) {
      return;
    }

    article.tags.forEach(value => {
      if (!tags[value]) {
        tags[value] = { count: 1, slug: stringToSlug(value) };
      } else {
        tags[value].count += 1;
      }
    });
  });

  return tags;
};

export const getProductBundleSpecial = (
  specials: JaneSpecial[],
  product?: Product,
): JaneSpecial | undefined => {
  return specials
    .filter(j => j.special_type === 'bundle')
    .find(j => checkIfSpecialIsBundled(product!, j));
};

export const getBundledProducts = async (
  special: JaneSpecial,
  id: string,
): Promise<GProduct[]> => {
  // first filter and map bundled products
  const searchProducts = special?.bundle_products
    ?.filter(pr => pr !== id)
    ?.map(v => {
      return {
        product_id: v,
      };
    });

  if (!searchProducts?.length) {
    return [];
  }

  return [];

  // try {
  //     return []; //await getProductsByIdList(searchProducts);
  // } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error);

  //     return [];
  // }
};

const checkIfSpecialIsBundled = (
  product: Product,
  special: JaneSpecial,
): boolean => {
  if (!product) {
    return false;
  }

  if (special.bundle_products?.length) {
    return special.bundle_products.find(b => b === product.id) !== undefined;
  }

  if (special.brands) {
    if (!special.brands.find(br => br === product.brand_name)) {
      return false;
    }
  }

  if (special.kinds) {
    return (
      special.kinds.find(kd => checkSpeciakKind(kd, product)) !== undefined
    );
  }

  return true;
};

const checkSpeciakKind = (kind: JaneSpecialKind, product: Product): boolean => {
  if (product.cannabis_type?.toLowerCase() !== kind.kind?.toLowerCase()) {
    return false;
  }

  if (kind.brand_subtype) {
    return kind.brand_subtype === product.brand_subtype;
  }

  return true;
};

export const formatEnUSPhoneNumber = (phone: string) => {
  if (!phone) {
    return '';
  }

  const match = phone
    ?.split('+1')
    ?.at(-1)
    ?.replace(/\D/g, '')
    ?.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  return !match?.[2]
    ? `+1 ${match?.[1]}`
    : '+1 (' + match[1] + ') ' + match[2] + (match[3] ? '-' + match[3] : '');
};

export function toQueryFilters(input: TreezFilters): QueryFilters {
  const queryFilters: QueryFilters = {};

  for (const attribute in input) {
    if (input.hasOwnProperty(attribute)) {
      const values = input[attribute].values ?? input[attribute]?.value;
      queryFilters[attribute] = {};

      if (Array.isArray(values)) {
        values.forEach(value => {
          queryFilters[attribute][value] = true;
        });
      } else if (typeof values === 'string') {
        queryFilters[attribute][values] = true;
      }
    }
  }

  return queryFilters;
}

export function toTreezFilters(
  queryParams: CollectionQueryParams | (() => CollectionQueryParams),
): TreezFilters {
  return Object.fromEntries(
    Object.entries(queryParams).map(([attribute, value]) => {
      let filterValue;
      if (attribute === 'price') {
        filterValue = { lessThanEqual: true, value: value };
      } else if (attribute === 'totalThcPercent') {
        filterValue = { greaterThanEqual: true, value: value };
      } else {
        filterValue = { values: (value as string).split(',') };
      }
      return [attribute, filterValue];
    }),
  );
}

export function toCollectionQueryParams(
  activeFilters: QueryFilters,
): CollectionQueryParams {
  const queryParams = {};

  for (const attribute in activeFilters) {
    if (activeFilters.hasOwnProperty(attribute)) {
      const values = activeFilters[attribute];

      if (values && typeof values === 'object') {
        const filteredValues = Object.keys(values).filter(
          value => values[value],
        );
        if (filteredValues.length > 0) {
          queryParams[attribute] =
            filteredValues.length > 1
              ? filteredValues.join(',')
              : filteredValues[0];
        }
      }
    }
  }

  return queryParams;
}

export const toQueryParams = (query: ReadonlyURLSearchParams) => {
  let result: { [key: string]: string } = {};
  for (const [key, value] of query.entries()) {
    if (
      key !== 'slug' &&
      key !== 'subcategory' &&
      key !== 'query' &&
      key !== 'sort'
    ) {
      result = {
        ...result,
        [key]: value,
      };
    }
  }

  return result;
};

export function toTreezSortBy(
  queryString?: string,
): SortBySelectOption | undefined {
  if (!queryString) {
    return undefined;
  }

  // Regular expression to separate camelCase string
  const match = queryString.match(/([a-zA-Z]+)(Asc|Desc)$/);

  if (!match) {
    return undefined;
  }

  const [, field] = match;

  const filterOption = SORT_BY_FIELD_OPTION?.find(
    option => (option?.value as SortByParams)?.field === field,
  );

  return filterOption;
}

export function toQuerySortBy(selectOption: SortBySelectOption): string {
  if (selectOption.value === 'default') {
    return undefined as unknown as string;
  }

  return `${selectOption.value.field}${startCase(selectOption.value.order)}`;
}

const getVariantPrice = (salePrice: number, price: number): number => {
  if (!salePrice) {
    return price;
  }

  if (salePrice > price) {
    return price;
  }

  return salePrice;
};

const getAccountType = (customerType: CustomerType) =>
  ({
    [CustomerType.ADULT]: AccountType.Adult,
    [CustomerType.MEDICAL]: AccountType.Medical,
  }[customerType] ?? AccountType.Adult);

export const getOrderFromEcomState = (
  state: EcomState,
  store: TreezStore,
): OrderInput => {
  return {
    entity_id: state.entity_id,
    email: state.email,
    date_of_birth: state.date_of_birth,

    // payment
    payment_details: state.payment_details,

    // payment address
    delivery_address: state.delivery_address,

    // delivery details
    delivery_details: state.delivery_details,

    // discount items
    discount_items: state.discount_items.map(
      (i: OrderDiscountItem): OrderDiscountItemInput => ({
        blaze_id: i.blaze_id,
        discountType: i.discountType as DiscountType,
        discount_apply_type: i.discount_apply_type,
        discount_rate_type: i.discount_rate_type as DiscountRateType,
        id: i.id as string,
        promo_code: i.promo_code,
        rate: i.rate as number,
      }),
    ),

    // line items
    line_items: mapLineItems(state.line_items),

    notes: state.notes,
    buyer_accepts_marketing: state.buyer_accepts_marketing,

    // provider info
    provider_store_full_address: store?.full_address,
    provider_store_id: store?.shortName,
    provider_store_name: store?.name,
    provider_store_phone_number: store?.phone,

    // totals
    subtotal_price: state.subtotal_price,
    total_delivery: state.total_delivery,
    total_discounts: state.total_discounts,
    total_line_items_price: state.total_line_items_price,
    total_price: state.total_price,
    total_service_fee: state.total_service_fee,
    total_tax: state.total_tax,

    // medical info
    order_type: getAccountType(state.customer_type),
    order_medical_id_expiration_date: '',
    order_medical_id: '',

    // alpine iq
    alpineiq_template_id: state.alpineiq_template_id,
    alpineiq_redemption_url: state.alpineiq_redemption_url,
  };
};

export const mapLineItems = (lineItems: CartLineItem[]): LineItemInput[] => {
  if (!lineItems?.length) {
    return [];
  }

  return lineItems.map(
    (lineItem: CartLineItem): LineItemInput => ({
      brand: lineItem.brand_name,
      category: lineItem.category,
      id: lineItem?.id?.toString(),
      item_id: lineItem?.id?.toString(),
      pos_id: lineItem?.id?.toString(),
      base_weight: lineItem?.base_weight,
      tier_method: lineItem?.tier_method,
      name: lineItem.name,
      price: lineItem.weightVariant.price,
      quantity: lineItem.quantity,
      sale_price: lineItem.weightVariant
        ? lineItem.weightVariant.salePrice
        : lineItem.sales_price,
      thumbnail: lineItem.asset?.image,
      price_type: lineItem.price_type,
      variants: [
        {
          id: lineItem?.weightVariant?.id?.toString(),
          name: lineItem.weightVariant.name,
          price: getVariantPrice(
            lineItem?.weightVariant?.salePrice as number,
            lineItem?.weightVariant?.price as number,
          ),
          type: lineItem.weightVariant.type,
        },
      ],
    }),
  );
};

export function encryptText(text) {
  return text;
  // const key = process.env.NEXT_PUBLIC_ENCRYPTION_KEY;
  // const textEncryption = CryptoJS.AES.encrypt(text, key).toString();

  // return textEncryption;
}

export function decryptText(textEncrypt) {
  return textEncrypt;

  // const key = process.env.NEXT_PUBLIC_ENCRYPTION_KEY;
  // const bytes = CryptoJS.AES.decrypt(textEncrypt, key);
  // const textOrigin = bytes.toString(CryptoJS.enc.Utf8);

  // return textOrigin;
}

export function getFormatedPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return;
  }
  //This is for check when the phone number includes +1
  if (phoneNumber.slice(0, 2) === '+1') {
    return phoneNumber
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
  }
  return phoneNumber
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2-$3');
}

export const getCrmListIds = crm => crm?.map(v => v?.value?.toString());

export const textAlign = (
  alignment: 'bottom' | 'left' | 'right' | 'top' | 'center',
) => {
  switch (alignment) {
    case 'left':
    case 'top':
      return 'flex-start';
    case 'right':
    case 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};

export const alignContent = (align: 'center' | 'start' | 'end' = 'center') => {
  switch (align) {
    case 'center':
      return 'center';
    default:
      return `flex-${align}`;
  }
};

export const getContentAlign = (
  contentAlign: 'start' | 'left' | 'center' | 'end' | 'right',
) => {
  switch (contentAlign) {
    case 'start':
    case 'left':
      return 'flex-start';
    case 'end':
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

type PropertyName = keyof CSSProperties;

export const getCSSpropety = <T extends PropertyName>(
  name: T,
  value: CSSProperties[T] | null,
) => {
  return value ? { [name]: value } : {};
};

export const getSlicePadding = (
  padding: 'small' | 'medium' | 'large' = 'large',
) => `padding-slice-${padding}`;

export const getFavicon = (
  iconURL: string | undefined | null,
): null | IconURL | Array<Icon> | Icons => {
  if (!iconURL) {
    return null;
  }

  return {
    icon: iconURL,
    shortcut: iconURL,
    apple: iconURL,
  };
};

export const stripHtml = html => {
  // Use a regular expression to remove all HTML tags.
  return html.replace(/<[^>]*>?/gm, '');
};

const defaultPaymentSettings: StorePaymentSettings[] = [
  {
    reservation_mode: ReservationMode.All,
    payment_type: StorePaymentType.Cash,
    stores_provider_ids: [],
  },
  {
    reservation_mode: ReservationMode.All,
    payment_type: StorePaymentType.Debit,
    stores_provider_ids: [],
  },
  {
    reservation_mode: ReservationMode.All,
    payment_type: StorePaymentType.Ach,
    stores_provider_ids: [],
  },
];

export const filterStoreContent = (
  store: Store,
  providerStoreId?: string,
  isPickup?: boolean | null,
  deliveryAddress?: OrderAddressInput | null,
  isExpress?: boolean,
): Store => {
  const res = isPickup ? ReservationMode.Pickup : ReservationMode.Delivery;

  const filterResModAndProviderStoreId = (
    s: StorePaymentSettings | StoreAgreement | Closure | null,
  ): boolean => {
    return !!(
      s?.stores_provider_ids?.includes(providerStoreId!) &&
      (s?.reservation_mode === res ||
        s?.reservation_mode === ReservationMode.All)
    );
  };

  // filter payment settings
  const payment_settings = store?.payment_settings?.filter(s =>
    filterResModAndProviderStoreId(s),
  );

  // filter agreements
  const agreements = store?.agreements?.filter(s =>
    filterResModAndProviderStoreId(s),
  );

  // filter closures
  const closures = store?.closures?.filter(s =>
    filterResModAndProviderStoreId(s),
  );

  // pick up hours
  const pickup_schedules = store?.pickup_schedules?.filter(
    s => s?.stores_provider_id === providerStoreId,
  );

  let delivery_schedules = DefaultDeliverySchedules;

  // we only need to filter this if it is a delivery order
  // becasue we are not using this info at checkout
  if (res === ReservationMode.Delivery) {
    const f = getDeliveryScheduleByAddress(
      {
        latitude: deliveryAddress?.latitude || 0,
        longitude: deliveryAddress?.longitude || 0,
        zip: deliveryAddress?.zip || '',
      },
      store,
    );

    if (f) {
      delivery_schedules = [f];
    }
  }

  let ds = delivery_schedules;

  // filter express delivery times slots if the zone
  // offers express delivery
  if ((ds[0] as ScheduleZone)?.express_menu_config?.active) {
    ds = ds?.map(ds => {
      return {
        ...ds,
        time_slots: ds.time_slots?.filter(ts =>
          isExpress ? ts?.is_express : !ts?.is_express,
        ),
      };
    });
  }

  const ps = pickup_schedules?.length
    ? pickup_schedules
    : DefaultPickupSchedules;

  return {
    ...store,
    delivery_schedules: ds as ScheduleZone[],
    pickup_schedules: ps as PickupScheduleZone[],
    payment_settings: payment_settings?.length
      ? payment_settings
      : defaultPaymentSettings,
    agreements,
    closures,
  };
};

export const checkOrderForCustomDeliveryFee = (
  gcStoreWithGeoInfo: Store,
  order: OrderInput,
  store?: TreezStore,
) => {
  // this is a config from treez store settings that allows
  // customer to use a custom delivery fee
  const res = order?.delivery_details?.pickup
    ? ReservationMode.Pickup
    : ReservationMode.Delivery;

  if (res === ReservationMode.Delivery && store?.allowCustomDeliveryFee) {
    // can can ref delivery schedule on zero index because is gets default
    // before we call this method
    order.allow_custom_delivery_fee = true;
    order.total_delivery =
      gcStoreWithGeoInfo?.delivery_schedules?.[0]?.delivery_fee ?? 0;

    const freeAfter =
      gcStoreWithGeoInfo?.delivery_schedules?.[0]?.free_after ?? -1;

    if (freeAfter > 0) {
      // calculate order subtotal
      const subtotal = order?.line_items?.reduce((sum, p) => {
        const v = p?.variants?.[0];

        const price = v?.price ?? 0;
        const quantity = p?.quantity ?? 0;

        return sum + price * quantity;
      }, 0);

      if ((subtotal ?? 0) > freeAfter) {
        order.total_delivery = 0; // fee delivery
      }
    }
  }
};

export const getDeliveryScheduleByAddress = (
  address: ILocalAddress,
  store: Store,
) => {
  try {
    const ds = store?.delivery_schedules?.find(schedule => {
      switch (schedule?.geo_type) {
        case GeoType.Zip:
          return schedule?.zips?.includes(address.zip as string);
        case GeoType.Geofence:
          return isPointInsidePolygon(address, schedule?.polygon_geo_zone);
        case GeoType.Radius:
          return isPointInsideCircle(address, store, schedule?.radius ?? 0);
        default:
          return undefined;
      }
    });

    return ds ?? (DefaultDeliverySchedules[0] as ScheduleZone);
  } catch (error) {
    return DefaultDeliverySchedules[0] as ScheduleZone;
  }
};

const isPointInsidePolygon = (
  deliveryAddress: ILocalAddress,
  polygonGeoZone?: string | null,
) => {
  if (!polygonGeoZone) return false;

  let isInsidePolygon = false;
  try {
    const point = new google.maps.LatLng(
      deliveryAddress.latitude || 0,
      deliveryAddress.longitude || 0,
    );

    const polygon: google.maps.Polygon = new google.maps.Polygon({
      paths: JSON.parse(polygonGeoZone).map(
        ({ lat, lng }: { lat: number; lng: number }) => ({ lat, lng }),
      ),
    });

    isInsidePolygon = google.maps.geometry.poly.containsLocation(
      point,
      polygon,
    );

    return isInsidePolygon;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('invalid polygonGeoZone JSON string', error);
    return false;
  }
};

const isPointInsideCircle = (
  deliveryAddress: ILocalAddress,
  store: Store,
  radius: number,
) => {
  try {
    const point = new google.maps.LatLng(
      deliveryAddress.latitude || 0,
      deliveryAddress.longitude || 0,
    );

    const center = new google.maps.LatLng(
      store?.latitude || 0,
      store?.longitude || 0,
    );

    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      point,
      center,
    );

    const distanceInMiles = metersToMiles(distance);

    return distanceInMiles <= radius;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('invalid radius config', err);

    return false;
  }
};

const metersToMiles = (meters: number) => meters / 1609.344;

export const getDeliveryScheduleByZip = (zip: string, store: Store) => {
  const ds = store?.delivery_schedules?.find(schedule =>
    schedule?.zips?.includes(zip as string),
  );

  return ds;
};

export const getBogoSpecialURL = (d: Discount): string => {
  return `${TEMPLATE_BASE_PATH.SPECIALS}${stringToSlug(
    d?.discount_title,
  )}?special_id=${d?.discount_id}`;
};

export const getBogoFromProduct = (product: GProduct): Discount | undefined =>
  product?.discounts?.find(
    item => item?.discount_method?.toUpperCase() === 'BOGO',
  );

export const formatPhoneNumber = (phone: string) => {
  const phoneNumber = phone.match(/^[^(]+/);
  return phoneNumber ? phoneNumber[0].trim() : '';
};

export const getMaxWidthForDevice = (
  currentWidth: number,
  desktopValue: number | undefined,
  mobileValue: number | undefined,
) => {
  if (currentWidth > 640 && desktopValue) {
    return `${desktopValue}px`;
  }

  if (currentWidth <= 640 && mobileValue) {
    return `${mobileValue}px`;
  }

  return;
};

export const getPaymentMethods = (
  store: Store | undefined,
): StorePaymentType[] =>
  (store?.payment_settings
    ?.map((payment: Maybe<StorePaymentSettings>) => payment?.payment_type)
    ?.filter(Boolean) ?? []) as StorePaymentType[];

export const isNullish = (value: any): value is null | undefined =>
  value === null || value === undefined;

export const getActivesPreCartDiscounts = (
  cmsDiscountsItems: PromoCarouselDocumentDataCarouselItemsItem[],
  preCartTreezDiscounts?: TreezSpecials[],
) => {
  return cmsDiscountsItems?.reduce(
    (
      result: {
        [key: string]: PromoCarouselDocumentDataCarouselItemsItemWithID;
      },
      current: PromoCarouselDocumentDataCarouselItemsItem,
      index,
    ) => {
      if (!current?.pos_title_name) {
        return {
          ...result,
          [`ad-${index}`]: { ...current },
        };
      }

      if (current?.pos_title_name?.toLocaleLowerCase()?.trim() === 'default') {
        preCartTreezDiscounts?.forEach(treezDiscount => {
          if (!result?.[treezDiscount?.id]) {
            result = {
              ...result,
              [treezDiscount?.id]: {
                ...evaluate(current, treezDiscount),
                brands: treezDiscount?.brands,
                categories: treezDiscount?.categories,
                id: treezDiscount?.id,
              },
            } as {
              [key: string]: PromoCarouselDocumentDataCarouselItemsItemWithID;
            };
          }
        });

        return result;
      }

      const treezDiscount = preCartTreezDiscounts?.find(
        special =>
          special?.title?.toLocaleLowerCase() ===
          current?.pos_title_name?.toLocaleLowerCase(),
      );

      if (!treezDiscount) {
        return result;
      }

      return {
        ...result,
        [treezDiscount.id]: {
          ...evaluate(current, treezDiscount),
          brands: treezDiscount?.brands,
          categories: treezDiscount?.categories,
          id: treezDiscount?.id,
        },
      };
    },
    {},
  );
};

export const getSpecialURL = (
  special: PromoCarouselDocumentDataCarouselItemsItemWithID,
) => {
  // if id is undefined is because the special is a item without a POS Title Name
  if (special?.id) {
    return getSpecialLink(special);
  }

  return special?.cta_link ?? '/';
};

export const allowedAge = (
  customerType: EnumCustomerType,
  r?: AgeRestriction,
): number => {
  // this case will be for when there is not store selected
  if (!r) {
    return 21;
  }

  if (customerType === CustomerType.MEDICAL) {
    return r?.medical;
  }

  return r?.adult;
};

export const getTaxValue = (displayPostTax?: boolean, tax?: number) => {
  if (displayPostTax) {
    return 'Included';
  }

  if (!tax) {
    return 'Free';
  }

  return `$${currencyFormat(tax)}`;
};

export const convertSlugToTitle = (slug: string): string => {
  try {
    return slug
      .split('-')
      .map(i => capitalize(i))
      .join(' ');
  } catch (error) {
    return slug;
  }
};

export const getFilterItems = (
  stores: TreezStore[],
  attribute: string,
  configValue?: string,
) => {
  const f = (
    stores: TreezStore[],
    attribute: string,
    transformFn?: (_item: any) => string,
  ): string[] => {
    const items = stores.reduce<string[]>((acc, store) => {
      const i = store[attribute] ?? [];

      if (Array.isArray(i)) {
        acc.push(...i.map(transformFn ?? (x => x)));
      }
      return acc;
    }, []);

    return Array.from(new Set(items));
  };

  switch (attribute) {
    case 'deals':
      return ['on sale'];

    case 'productTypeName':
      return f(stores, 'categories', cat => cat?.name);

    case 'brand':
      return f(
        stores,
        'brands',
        brand => brand?.replace(/\s+/g, ' ').trim(), // remove extra spaces
      );

    default:
      return f(stores, configValue ?? attribute);
  }
};

export const getCartItemsSubtotal = (cartItems: CartLineItem[]) => {
  return cartItems.reduce((sum, { weightVariant: v, quantity }) => {
    const salePrice = v?.salePrice ?? 0;
    const price = v?.price ?? 0;
    return sum + (salePrice < price ? salePrice : price) * quantity;
  }, 0);
};

export const getCartItemsCount = (cartItems: CartLineItem[]) =>
  cartItems?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? 0;

export const formatAddress = ({
  address,
  format = 'long',
}: {
  address?: OrderAddress;
  format: 'short' | 'long';
}) => {
  const shortFormat = [address?.address1, address?.address2, address?.city];
  const longFormat = [
    address?.address1,
    address?.address2,
    address?.city,
    address?.province,
    address?.zip,
    address?.country_code,
  ];

  if (format === 'short') return shortFormat?.filter(Boolean).join(', ') || '';

  return longFormat?.filter(Boolean).join(', ') || '';
};
