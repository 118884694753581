import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithImageCarouselVariation,
} from 'prismicio-types';
import { CSSProperties, FC } from 'react';
import { useRouter } from 'next/navigation';

import { PrismicRichText } from '@prismicio/react';
import { htmlSerializerWithProps } from '@/utils';
import { Button, Container, Icon, Link, Slider } from '@/components';
import { TEMPLATE_BASE_PATH } from '@/constants';

import style from './withImageCarouselVariation.module.scss';
import { ImageField } from '@prismicio/client';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

export const WithImageCarouselVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithImageCarouselVariation &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const {
    content_background_color,
    content_horizontal_align,
    content_vertical_align,
    description,
    description_color,
    heading,
    heading_color,
    icon_as_heading,
    image_border_radius,
    icon_as_heading_max_width,
    cta_text,
    cta_link,
    layout_direction,
    title,
    title_color,
    autoplay_image_carousel,
    section_background_color,
  } = slice.primary;

  const router = useRouter();

  const layoutDirection = layout_direction ?? 'left';
  const alignText = content_horizontal_align ?? 'center';

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'left':
        return 'flex-start';

      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  const handleCtaBtn = () => {
    if (cta_link) {
      router.push(cta_link);
      return;
    }
    router.push(TEMPLATE_BASE_PATH.SHOP);
  };

  const renderImage = (image?: ImageField) => {
    return (
      <figure
        className={classNames(style.image, {
          [style.image__default]: !image?.url,
        })}
        style={{
          borderRadius: image_border_radius ?? 0,
        }}
      >
        {image?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={image}
            height={image?.dimensions?.height}
            style={{ objectFit: 'cover' }}
            width={image?.dimensions?.width}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    );
  };

  return (
    <>
      <Container className={style.image_carousel_swiper_container}>
        {slice.items?.length > 0 ? (
          <Slider
            autoplay={
              autoplay_image_carousel
                ? {
                    delay: 5000,
                    disableOnInteraction: false,
                    stopOnLastSlide: false,
                  }
                : false
            }
            effect="fade"
            loop
            modules={[Autoplay, EffectFade, Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            showSliderButtons={true}
            showSliderHeader={false}
            showSlidesOutsideContainer
            slidesPerView={1}
            spaceBetween={16}
          >
            {slice.items?.map((item, index) => {
              return (
                <Link
                  className={style.image_carousel_swiper}
                  href={item?.image_link ?? '/'}
                  key={index}
                  style={{ background: section_background_color ?? '' }}
                >
                  {renderImage(item?.image)}
                </Link>
              );
            })}
          </Slider>
        ) : (
          renderImage()
        )}
      </Container>
      <div
        className={style.body}
        style={
          {
            ...bodyVariables,
          } as CSSProperties
        }
      >
        <div
          className={classNames(style.content, {
            [style.content_with_background]: !!content_background_color,
          })}
          style={{
            alignItems: textAlign(content_horizontal_align as string),
            textAlign: alignText,
            alignSelf: textAlign(content_vertical_align),
            background: content_background_color as string,
            borderRadius:
              !!content_background_color && image_border_radius
                ? image_border_radius
                : 0,
          }}
        >
          {icon_as_heading?.url && (
            <figure
              className={style.body__heading_icon}
              style={{ maxWidth: icon_as_heading_max_width ?? '' }}
            >
              <PrismicNextImage
                fallbackAlt=""
                field={icon_as_heading}
                height={icon_as_heading?.dimensions?.height}
                style={{ objectFit: 'cover' }}
                width={icon_as_heading?.dimensions?.width}
              />
            </figure>
          )}
          {heading && (
            <PrismicRichText
              components={htmlSerializerWithProps(
                {
                  color: heading_color,
                },
                style.body__text_heading,
              )}
              field={heading}
            />
          )}
          {title && (
            <div
              className={style.body__title_container}
              style={
                {
                  '--icon-order': alignText === 'right' ? 0 : 1,
                  '--title-order': alignText === 'right' ? 1 : 0,
                } as CSSProperties
              }
            >
              <PrismicRichText
                components={htmlSerializerWithProps(
                  {
                    color: title_color,
                  },
                  style.body__title,
                )}
                field={title}
              />
            </div>
          )}
          {description && (
            <div className={style.description_section}>
              <PrismicRichText
                components={htmlSerializerWithProps({
                  color: description_color,
                })}
                field={description}
              />
            </div>
          )}
          {cta_text && (
            <Button
              className={style.cta}
              color="secondary"
              onClick={handleCtaBtn}
            >
              {cta_text}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
