import {
  Children,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';
import { Autoplay, EffectFade, FreeMode, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';

import { Container } from '@/components';

import { SliderButtons } from './Slider.components';

import styles from './Slider.module.scss';

interface ISliderProps extends SwiperProps {
  activatedHover?: boolean;
  activeAutoplay?: boolean;
  children: ReactNode[];
  heightShort?: boolean;
  seeAllElement?: ReactNode;
  showSliderButtons?: boolean;
  showSliderCardLink?: boolean;
  showSliderHeader?: boolean;
  showSlidesOutsideContainer?: boolean;
  activateThumbs?: boolean;
  nested?: boolean;
  sliderHeading?: ReactElement<any>;
  isNotPaddingMobile?: boolean;
  containerClassname?: string;
}

const Slider: FC<ISliderProps> = ({
  activatedHover = false,
  activeAutoplay = false,
  sliderHeading,
  seeAllElement,
  children,
  className,
  heightShort = false,
  loop = false,
  slidesPerView,
  nested = false,
  containerClassname,
  showSliderButtons = true,
  showSliderCardLink = false,
  showSliderHeader = true,
  activateThumbs = false,
  showSlidesOutsideContainer = false,
  isNotPaddingMobile,
  ...props
}) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(true);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);

  const [hideNavControls, setHideNavControls] = useState<boolean>(true);

  useEffect(() => {
    setHideNavControls(isPrevDisabled && isNextDisabled);
  }, [isPrevDisabled, isNextDisabled]);

  const handleSwiperChange = (swiper: ISwiper) => {
    setIsPrevDisabled(swiper.isBeginning);
    setIsNextDisabled(swiper.isEnd);

    if (!showSliderCardLink) {
      return;
    }

    const translate = swiper.snapGrid[swiper.snapGrid.length - 2] + 120;

    swiper.snapGrid[swiper.snapGrid.length - 1] = translate;
    swiper.init();

    if (swiper.isEnd) {
      swiper.setTranslate(translate * -1);
    }
  };

  const isHeader = !sliderHeading?.props?.children;

  const renderSwiper = () => (
    <Swiper
      className={classNames(
        styles.slider,
        {
          [styles.slider__products]: showSliderCardLink || activateThumbs,
          [styles.slider__height_short]: heightShort,
          [styles.slider__products_last]: isNextDisabled,
        },
        className,
      )}
      loop={loop}
      loopAddBlankSlides={loop}
      modules={
        activeAutoplay
          ? [Autoplay, EffectFade, FreeMode, Pagination]
          : [EffectFade, FreeMode, Pagination]
      }
      nested={nested}
      onAfterInit={handleSwiperChange}
      onSlideChange={handleSwiperChange}
      onSwiper={handleSwiperChange}
      onTransitionEnd={handleSwiperChange}
      slidesPerView={slidesPerView ?? 'auto'}
      {...props}
    >
      <div
        className={classNames(styles.slider_header, {
          [styles.slider_header_right]: isHeader,
          [styles.slider_header_padding_mobile]: isNotPaddingMobile,
          [styles['slider_header--hide']]: !showSliderHeader,
          [styles['slider_header--showSlidesOutsideContainer']]:
            showSlidesOutsideContainer,
        })}
      >
        {sliderHeading}
        {!hideNavControls && (
          <div className={styles.slider__shop_all}>
            {seeAllElement}
            {showSliderButtons && (
              <SliderButtons
                isNextDisabled={isNextDisabled}
                isPrevDisabled={isPrevDisabled}
                loop={loop}
              />
            )}
          </div>
        )}
      </div>
      {Children.toArray(children).map((child, index) => (
        <SwiperSlide
          className={classNames(styles.slider_item, {
            [styles.slider_item_hover]: activatedHover,
          })}
          key={index}
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return showSlidesOutsideContainer ? (
    <div
      className={classNames(styles.slider__container, containerClassname, {
        [styles.slider__container_not_padding_mobile]: isNotPaddingMobile,
      })}
    >
      {renderSwiper()}
    </div>
  ) : (
    <Container
      className={classNames(styles.slider__container, containerClassname, {
        [styles.slider__container_not_padding_mobile]: isNotPaddingMobile,
      })}
    >
      {renderSwiper()}
    </Container>
  );
};

export { Slider };
