import { ChangeEvent, FC, Ref } from 'react';
import classNames from 'classnames';

import {
  addressAsString,
  Address as AddressInput,
  Button,
  Icon,
  ILocalAddress,
  Input,
  Spinner,
  useData,
} from '@/components';
import { getDeliveryScheduleByAddress } from '@/utils';

import { useStoreLocatorContext } from './context';

import styles from './storelocator.module.scss';

interface IDeliverySectionProps {
  handleUseCurrentLocation: () => void;
  loadingLocation: boolean;
  showInstructionText: boolean;
  ref?: Ref<HTMLInputElement | HTMLTextAreaElement> | null;
}

const ADDRESS_INPUT_ID = 'addres_input';

const DeliverySection: FC<IDeliverySectionProps> = ({
  showInstructionText,
  loadingLocation,
  handleUseCurrentLocation,
  ref: inputRef,
}) => {
  const {
    isDeliveryAddressInvalid,
    setDeliveryAddress,
    deliveryAddress,
    singleStore,
    isPickup,
    setIsDeliveryAddressInvalid,
    setSelectedStoreId,
    isDeliveryFromEconStore,
  } = useStoreLocatorContext();
  const { store } = useData();

  const onAddressChange = (address: ILocalAddress) => {
    if (!address) {
      setIsDeliveryAddressInvalid(false);
      return;
    }

    const ds = getDeliveryScheduleByAddress(address, store);

    if (!ds) {
      setIsDeliveryAddressInvalid(true);
      return;
    }

    setIsDeliveryAddressInvalid(false);
    setDeliveryAddress(address);
    setSelectedStoreId(ds?.stores_provider_id);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setDeliveryAddress({
      ...deliveryAddress,
      address2: event?.target?.value ?? '',
    });
  };

  return (
    <div className={styles.delivery_section}>
      {showInstructionText && (
        <p className={styles.delivery_section__title}>Enter delivery address</p>
      )}
      <AddressInput
        country={['us']}
        feedback={
          'Sorry, we don’t deliver there. Want to pick up your order instead?'
        }
        googleMapApi={process?.env?.NEXT_PUBLIC_GOOGLE_MAP_API ?? ''}
        id={ADDRESS_INPUT_ID}
        isClearable
        isInvalid={isDeliveryAddressInvalid}
        label="Your address"
        name={ADDRESS_INPUT_ID}
        onAddressChange={onAddressChange}
        onAddressClear={() => {
          setIsDeliveryAddressInvalid(false);
          setSelectedStoreId(undefined as unknown as string);
        }}
        placeholder="Your address"
        ref={inputRef}
        value={
          deliveryAddress && isDeliveryFromEconStore
            ? addressAsString(deliveryAddress)
            : ''
        }
      />
      <Input
        inputLabel="Apartment or suite"
        name="address2"
        onChange={handleChange}
        placeholder="Apartment or suite (optional)"
        value={deliveryAddress ? deliveryAddress?.address2 ?? '' : ''}
      />
      <Button
        className={classNames(styles.current_location, {
          [styles.hidden]: singleStore && isPickup,
        })}
        onClick={handleUseCurrentLocation}
      >
        {loadingLocation ? (
          <Spinner className={styles.crosshairs_icon} sm />
        ) : (
          <Icon className={styles.crosshairs_icon} name="crosshairs" />
        )}
        <span>Use current location</span>
      </Button>
    </div>
  );
};

export { DeliverySection };
