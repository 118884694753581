import { FC } from 'react';
import { LogoRowSlice } from '@/types';
import { Container, Heading, Image } from '@/components';
import styles from './logorow.module.scss';
import { PrismicLink } from '@prismicio/react';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';

const LogoRow: FC<LogoRowSlice> = slice => {
  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as={'section'}
      className={classNames(styles.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <>
        <Heading
          className={styles.container__header}
          color={slice?.primary?.header_color as string}
          level={1}
        >
          {slice?.primary?.header?.[0]?.text}
        </Heading>
        <div className={styles.container__logos}>
          {slice?.items?.map((logo, index) => {
            return (
              <span className={styles.logo} key={index}>
                <PrismicLink field={logo?.link}>
                  <Image
                    alt={logo?.logo?.alt ?? ''}
                    fill
                    src={logo?.logo?.url ?? ''}
                    style={{ objectFit: 'contain' }}
                  />
                </PrismicLink>
              </span>
            );
          })}
        </div>
      </>
    </Container>
  );
};

export default LogoRow;
