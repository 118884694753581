import { FC } from 'react';

import { ImageWithTextSideBySideSlice } from '@/types';
import { Container } from '@/components';

import DefaultVariation from './DefaultVariation';
import WithBlockquoteVariation from './WithBlockquoteVariation';
import WithImageGalleryVariation from './WithImageGalleryVariation';
import WithSocialIconsVariation from './WithSocialIconsVariation';
import WithWideSocialIconsVariation from './WithWideSocialIconsVariation';
import WithCtaOnImageSideVariation from './WithCtaOnImageSide';
import WithFeaturesListVariation from './WithFeaturesListVariation';

import style from './components.module.scss';
import classNames from 'classnames';
import {
  DefaultComponentSection,
  TextSlice,
} from './ImageWithTextSideBySide.components';
import { WithImageCarouselVariation } from './WithImageCarouselVariation';
import { CarouselWithImageAndInfoVariation } from './CarouselWithImageAndInfo';

const ImageWithTextSideBySide: FC<ImageWithTextSideBySideSlice> = slice => {
  const {
    bottom_spacing,
    padding_on_section,
    section_background_color,
    section_border,
    layout_direction,
  } = slice.primary;

  if (!slice?.primary?.active) {
    return null;
  }

  const marginBottom = bottom_spacing;
  const borderTop = section_border as string;
  const borderBottom = section_border as string;
  const layoutDirection = layout_direction ?? 'left';

  return (
    <section
      className={classNames(style.side_by_side__section, {
        [style.side_by_side__section_with_padding]: padding_on_section,
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        backgroundColor: section_background_color as string,
        borderTop,
        borderBottom,
      }}
    >
      {(slice.variation === 'withImageGallery' ||
        slice.variation === 'withCtaOnImageSide') && (
        <div className={style.heading_section}>
          <TextSlice
            centeredText
            color={slice?.primary?.title_color as string}
            text={slice?.primary?.title}
          />
          <TextSlice
            centeredText
            color={slice?.primary?.description_color as string}
            text={slice?.primary?.description}
          />
        </div>
      )}
      <Container
        className={classNames(style.container, {
          [style.container__left]: layoutDirection === 'left',
          [style.flat_image_variation_section]:
            slice.variation === 'withFlatImage',
          [style.cta_on_image_variation_section]:
            slice.variation === 'withCtaOnImageSide',
          [style.image_gallery_variation_section]:
            slice.variation === 'withImageGallery',
          [style.carousel_image_variation_section]:
            slice.variation === 'carouselWithImageAndInfo',
        })}
      >
        {slice.variation === 'withBlockquotes' && (
          <WithBlockquoteVariation slice={slice} />
        )}
        {slice.variation === 'withFlatImage' && (
          <DefaultComponentSection slice={slice} />
        )}
        {slice.variation === 'withImageGallery' && (
          <WithImageGalleryVariation slice={slice} />
        )}
        {slice.variation === 'withCtaOnImageSide' && (
          <WithCtaOnImageSideVariation slice={slice} />
        )}
        {slice.variation === 'withFeaturesList' && (
          <WithFeaturesListVariation slice={slice} />
        )}
        {slice.variation === 'withSocialIcons' && (
          <WithSocialIconsVariation slice={slice} />
        )}
        {slice.variation === 'withWideSocialIcons' && (
          <WithWideSocialIconsVariation slice={slice} />
        )}
        {slice.variation === 'withImageCarouselVariation' && (
          <WithImageCarouselVariation slice={slice} />
        )}
        {slice.variation === 'carouselWithImageAndInfo' && (
          <CarouselWithImageAndInfoVariation slice={slice} />
        )}
        {slice.variation === 'default' && <DefaultVariation slice={slice} />}
      </Container>
    </section>
  );
};

export default ImageWithTextSideBySide;
