import { Counter } from '@/components';
import { NOT_DEFINED } from '@/constants';

import {
  getInfoPackValue,
  ProductActionFavorite,
  ProductBadgeContainer,
  ProductCategory,
  ProductImage,
  ProductInfo,
  ProductInfoCarousel,
  ProductName,
  ProductPrice,
  ProductVariants,
} from '../Product.components';

import style from '../product.module.scss';
import styleVariation from './ProductCardWithBtnSmall.module.scss';

import { IProductCard } from '../Product';
import classNames from 'classnames';
import React from 'react';
import { startCase } from 'lodash';

const ProductCardWithSmallBtn: React.FC<IProductCard> = ({
  isSpecial,
  className,
  product,
  variants,
  analyticsListName,
  weightVariant,
  updateWeinghtVariant,
}) => {
  const thcInfo = getInfoPackValue(product, false, true);
  const cbdInfo = getInfoPackValue(product, false, false);

  const availableInfo = [
    <ProductCategory
      className={classNames(
        styleVariation.variation__info_badge,
        styleVariation.variation__info_badge_border_less,
      )}
      info={product?.flower_type as string}
      key="category"
      type="outlined"
    />,
    thcInfo && (
      <ProductInfo
        className={classNames(
          style.info__filled,
          styleVariation.variation__info_badge,
        )}
        icon="thc"
        info={thcInfo}
        key="thc"
        type="filled"
      />
    ),
    cbdInfo && (
      <ProductInfo
        className={classNames(
          style.info__filled,
          styleVariation.variation__info_badge,
        )}
        icon="cbd"
        info={cbdInfo}
        key="cbd"
        type="filled"
      />
    ),
    <ProductInfo
      className={classNames(
        style.info__filled,
        styleVariation.variation__info_badge,
      )}
      info={product.brand_name || NOT_DEFINED}
      key="info-brand"
      type="default"
    />,
    <ProductInfo
      className={classNames(
        style.info__filled,
        styleVariation.variation__info_badge,
      )}
      info={startCase(product?.subcategory as string) || startCase(NOT_DEFINED)}
      key="subcategory"
      type="default"
    />,
  ].filter(Boolean);

  return (
    <div
      className={classNames(style.product, styleVariation.product, className)}
    >
      <ProductImage
        analyticsListName={analyticsListName}
        className={styleVariation.product__image_container}
        product={product}
      />
      <div className={style.product__body}>
        <ProductBadgeContainer
          className={styleVariation.product_badge__container}
          isSpecial={isSpecial}
          key="badge-container"
          product={product}
          type="default"
          weightVariant={weightVariant}
        />
        <ProductInfoCarousel
          className={styleVariation.variation__info_carousel}
        >
          {availableInfo}
        </ProductInfoCarousel>
        <ProductName analyticsListName={analyticsListName} product={product} />
        <div className={styleVariation.product__actions_footer}>
          <ProductPrice
            elementVariants={
              <ProductVariants
                type={'default'}
                updateWeightVariant={updateWeinghtVariant}
                variants={variants}
                weightVariant={weightVariant}
              />
            }
            isOverflow={variants?.length === 1}
            isSpecial={isSpecial}
            type="column-container"
            weightVariant={weightVariant}
          />
          <Counter
            actionTheme="primary"
            className={styleVariation.actions__counter}
            omitCheckAvilibility
            onCard
            product={product}
            selectedWeightVariant={weightVariant}
            showIcon
          />
        </div>
        <ProductActionFavorite
          className={classNames(
            style.favorite_action_hover,
            styleVariation.favorite__button,
          )}
          product={product}
        />
      </div>
    </div>
  );
};

export { ProductCardWithSmallBtn };
