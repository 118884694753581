/* eslint-disable max-len */
import Image from 'next/image';
import classNames from 'classnames';
// import Instafeed from "./instafeed.min";
import { Link } from '../../atoms/link';

function Instagram({ className, ...props }) {
  return (
    <div
      className={classNames('instagram', className)}
      id="instafeed"
      {...props}
    >
      <div className="instagram__images" id="instafeed">
        {[
          {
            caption: 'image',
            id: 1,
            media_url:
              'https://www.thompsoncoburn.com/images/default-source/blogs/cannabis-delivery-bag.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 2,
            media_url:
              'https://leafly-cms-production.imgix.net/wp-content/uploads/2020/01/15101327/pelican-cannabis-delivery-service.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 3,
            media_url:
              'https://content.fortune.com/wp-content/uploads/2016/10/eaze-homepage-shoot-selects-edited-0639.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 4,
            media_url:
              'https://www.thompsoncoburn.com/images/default-source/blogs/cannabis-delivery-bag.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 5,
            media_url:
              'https://cdn.shopify.com/s/files/1/0285/0383/4763/files/premium-dc-cannabis-delivery_7463fe59-5d6c-416e-bc31-00bd20e984ff_480x480.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 6,
            media_url: 'https://25hournews.com/imgs/news/2722.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 7,
            media_url:
              'https://d12ly3e9mbfi1t.cloudfront.net/img/blog/newblog.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 8,
            media_url:
              'https://www.americadailypost.com/wp-content/uploads/2020/06/Attachment_1592585001.jpeg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 9,
            media_url:
              'https://images.weedmaps.com/deliveries/000/056/008/avatar/original/1621316442-emberz_wm_logo_21.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 10,
            media_url:
              'https://d12ly3e9mbfi1t.cloudfront.net/img/promo_bag_web.png',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 11,
            media_url:
              'https://d12ly3e9mbfi1t.cloudfront.net/img/promo_smokiez.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
          {
            caption: 'image',
            id: 12,
            media_url:
              'https://mma.prnewswire.com/media/834298/CaniBrands_CaniBrands_Announces_the_Acquisition_of_Sonder_Endeav.jpg',
            permalink: '#',
            title: 'lorem ipsum dolor sit amet',
          },
        ].map(image => (
          <Link
            href={image.permalink}
            key={image.id}
            rel="noopener noreferrer"
            target="_blank"
            title={image.caption}
          >
            <Image
              alt={image.caption}
              src={image.media_url}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Link>
        ))}
      </div>
    </div>
  );

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //     const feed = new Instafeed({
  //         accessToken,
  //         mock: true,
  //         success: (res) => {
  //             setData(
  //                 res.data
  //                     .filter((image) => image.media_type === "IMAGE")
  //                     .slice(0, limit),
  //             );
  //         },
  //     });

  //     feed.run();
  // }, []);

  // return (
  //     <div
  //         className={classNames("instagram", className)}
  //         {...props}
  //     >
  //          <div id="instafeed">
  //              {data.map((image) => (
  //                  <Link
  //                      key={image.id}
  //                      href={image.permalink}
  //                      title={image.caption}
  //                      rel="noopener noreferrer"
  //                      target="_blank"
  //                  >
  //                      <img src={image.media_url} alt={image.caption} />
  //                  </Link>
  //              ))}
  //          </div>
  //     </div>
  // );
}

export default Instagram;
