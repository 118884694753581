'use client';

import React, { FC } from 'react';

import { Container, useData } from '@/components';

import style from './footer.module.scss';
import { getPrismicFooterSlices, RenderFooter } from './Footer.components';
import classNames from 'classnames';
import { useIsServerSide } from 'hooks/useIsServerSide';

const Footer: FC = () => {
  const {
    footer: { data: footer },
  } = useData();

  const footerSlice = getPrismicFooterSlices(footer.body);
  const isBackgroundImage = footerSlice?.variation === 'withBackgroundImage';

  const { isServerSide } = useIsServerSide();

  if (!footerSlice) {
    return (
      <div className={style.footer_empty}>
        <h3>
          Regrettably, the footer section <br />
          is currently unavailable for display
        </h3>
        <span>
          The footer section is either inactive or has not been created. Kindly
          establish an active footer section.
        </span>
      </div>
    );
  }

  return (
    <footer
      className={classNames(style.footer, 'footer__with_bg_image', {
        [style.subscribe_bottom_variation__footer]:
          footerSlice.variation === 'subscribeBottom',
      })}
      id="appFooter"
      style={{
        background: footerSlice?.primary.background_color || undefined,
      }}
    >
      <Container
        className={classNames(style.footer__container, {
          ['footer__container_with_background_image']: isBackgroundImage,
        })}
      >
        <RenderFooter {...footerSlice} />
      </Container>
      {!isServerSide && isBackgroundImage && (
        <style>{`
          .footer__container_with_background_image {
            &::before {   
                content: '';
                position: absolute;
                background-image: ${
                  footerSlice.primary?.background_image.url
                    ? `url(${footerSlice?.primary.background_image.url})`
                    : 'none'
                };
                background-size: cover;
                background-position: right;
                background-repeat: no-repeat !important;
                transform: rotate(32deg) scalex(-1) scale(1.4);
                bottom: '440px';
                right: '-20px';
                width: '365px';
                height: '319px';
                z-index: -1;
                opacity: 0.7;
          }   
        }

        @media (min-width: 640px) {
          .footer__container_with_background_image::before {
            background-position: left;
            transform: rotate(-16.3deg) scale(1.9);
            top: '180px';
            left: '40px';
            right: auto;
          }
        }
        `}</style>
      )}
    </footer>
  );
};

export default Footer;
