import { FC, useState } from 'react';

import { Accordion, Container, Heading, Icon, IconSvg } from '@/components';
import { useScreenWidth } from '@/hooks';
import { FaqSlice } from '@/types';
import { PrismicNextImage } from '@prismicio/next';
import * as prismicH from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

import style from './faqsection.module.scss';
import classNames from 'classnames';

const FaqSection: FC<FaqSlice> = slice => {
  const { width } = useScreenWidth();
  const [isOpen, setIsOpen] = useState({});
  const [hovered, setHovered] = useState(null);
  const sliceDefault = slice?.variation === 'default';
  const twoColumns = slice?.variation === 'twoColumns';
  const twoColumnsWithInfo = slice?.variation === 'twoColumnsWithInfo';
  const towColumnsWithImage = slice?.variation === 'twoColumnsWithImage';
  const sliceDinamicPadding = sliceDefault || twoColumns;
  const marginBottom = slice?.primary?.margin_bottom;
  const background = slice?.primary?.background_color ?? '';
  const borderTop = slice?.primary?.border_section ?? '';
  const borderBottom = slice?.primary?.border_section ?? '';
  const paddingSection = slice?.primary?.padding_section;
  const HeaderUppercase = slice?.primary?.header_uppercase;
  const caretColor = slice?.primary?.caret_color ?? '';
  const separator = slice?.primary?.divider_color || undefined;
  const hoverSeparator = slice?.primary?.divider_color_hover || separator;
  const maxWidthBody =
    sliceDinamicPadding && slice?.primary?.max_width_body && width > 428
      ? slice?.primary?.max_width_body
      : '100%';
  const maxWidtHeader =
    sliceDinamicPadding && slice?.primary?.max_header_width && width > 428
      ? slice?.primary?.max_header_width
      : '100%';

  const getContentAlign = () => {
    switch (slice?.primary?.content_align) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  const getFaqs = (list, split, initial) => {
    if (!split) {
      return list;
    }

    const half = Math.ceil(list.length / 2);

    if (initial) {
      const initialsegment = list.slice(0, half);

      return initialsegment;
    }

    const lastSegment = list.slice(half);

    return lastSegment;
  };

  const getIconName = id => {
    if (slice?.primary?.type_of_caret === 'operator') {
      return isOpen[id] ? 'minus' : 'plus';
    }

    return isOpen[id] ? 'angle-up' : 'angle-down';
  };

  const renderHeadingSlice = (
    text: any,
    level: number,
    color: string,
    style?: object,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <Heading color={color} level={level} style={style}>
        {prismicH.asText(text)}
      </Heading>
    );
  };

  const renderImage = info => {
    if (!info?.url) {
      return <IconSvg name="image" />;
    }

    return (
      <PrismicNextImage
        fallbackAlt=""
        field={info}
        fill
        style={{ objectFit: 'cover' }}
      />
    );
  };

  const renderHeader = () => {
    if (
      !prismicH.asText(slice.primary?.header) &&
      !prismicH.asText(slice?.primary?.description)
    ) {
      return;
    }

    return (
      <div
        className={style.faqs__header}
        style={{
          maxWidth: maxWidtHeader,
          alignItems: getContentAlign(),
          // eslint-disable-next-line no-undef
          textAlign: slice?.primary?.content_align as CanvasTextAlign,
        }}
      >
        {renderHeadingSlice(
          slice.primary?.header,
          2,
          slice?.primary?.header_color ?? '',
          {
            textTransform: HeaderUppercase ? 'uppercase' : 'initial',
          },
        )}
        {renderHeadingSlice(
          slice.primary?.description,
          3,
          slice?.primary?.description_color ?? '',
          {},
        )}
      </div>
    );
  };

  const renderAccordion = (question, answer, id, last, isOnlyArray) => {
    return (
      <Accordion
        className={classNames(style.faqs__section_element, {
          [style.faqs__section_element_effect]: slice?.primary?.effect_hover,
        })}
        isOpen={isOpen[id]}
        key={id}
        onMouseEnter={() => {
          setHovered(id);
        }}
        onMouseLeave={() => {
          setHovered(null);
        }}
        style={{
          borderBottom:
            !last || (last && !isOnlyArray && width < 1279)
              ? `1px solid ${hovered === id ? hoverSeparator : separator}`
              : 'unset',
        }}
        title={
          <div className={classNames(style.accordion__title)}>
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: slice?.primary?.question_color,
              })}
              field={question}
            />
            <Icon
              className={style.icon}
              name={getIconName(id)}
              style={{ color: caretColor }}
            />
          </div>
        }
        toggle={() =>
          setIsOpen(oldIsOpen => ({
            ...oldIsOpen,
            [id]: !oldIsOpen[id],
          }))
        }
      >
        {isOpen[id] && (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: slice?.primary?.answer_color,
              borderLeft: `5px solid ${slice?.primary?.color_border_left}`,
            })}
            field={answer}
          />
        )}
      </Accordion>
    );
  };

  const getVariation = slice => {
    const initialList = getFaqs(slice?.items, twoColumns, true);
    const lastSegment = twoColumns ? getFaqs(slice?.items, true, false) : [];
    return (
      <div
        className={classNames(style.faqs__body, {
          [style.faqs__body_columns]: twoColumns,
          [style.faqs__body_info]: twoColumnsWithInfo || towColumnsWithImage,
          [style.faqs__body_reverse]:
            (twoColumnsWithInfo || towColumnsWithImage) &&
            slice?.primary?.layout_direction === 'left',
          [style.faqs__body_header_center]:
            (twoColumnsWithInfo || towColumnsWithImage) &&
            slice?.primary?.vertical_alignment,
        })}
        style={{ maxWidth: maxWidthBody }}
      >
        {twoColumnsWithInfo && renderHeader()}
        {towColumnsWithImage && (
          <figure className={style.faqs__figure}>
            {renderImage(slice?.primary?.image)}
          </figure>
        )}
        <ul className={style.faqs__list}>
          {initialList?.map(({ question, answer }, id) => {
            const lastItem = twoColumns
              ? width > 1279 && id === initialList.length - 1
              : id === initialList.length - 1;
            return renderAccordion(question, answer, id, lastItem, false);
          })}
        </ul>
        {twoColumns && (
          <ul className={style.faqs__list} key={'faqs-last'}>
            {lastSegment?.map(({ question, answer }, id) => {
              const lastItem = id === lastSegment.length - 1;
              const newId = id + initialList.length;
              return renderAccordion(question, answer, newId, lastItem, true);
            })}
          </ul>
        )}
      </div>
    );
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.faqs__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      key={`section-faq-${slice?.variation}`}
      style={{
        background,
        borderTop,
        borderBottom,
      }}
    >
      <Container
        className={classNames(style.faqs__container, {
          [style.faqs__container_spacing]:
            (twoColumnsWithInfo || towColumnsWithImage) &&
            slice?.primary?.layout_with_padding,
          [style.faqs__container_default]: sliceDefault,
        })}
      >
        {!twoColumnsWithInfo && renderHeader()}
        {getVariation(slice)}
      </Container>
    </section>
  );
};

export default FaqSection;
