import { FC } from 'react';

import { PrismicNextImage } from '@prismicio/next';

import { Slider } from '@/components';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceDefault,
} from '@/types';

import style from './defaultvariation.module.scss';
import {
  DefaultComponentSection,
  textAlign,
} from '../ImageWithTextSideBySide.components';
import classNames from 'classnames';

export const DefaultVariation: FC<{
  slice: ImageWithTextSideBySideSliceDefault & ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const breakpoints = {
    640: {
      spaceBetween: 32,
    },
  };

  const itemImageCustomWidth = slice?.primary?.item_image_custom_width;

  return (
    <DefaultComponentSection slice={slice}>
      {slice?.items[0] && (
        <div className={style.items_section}>
          <Slider
            breakpoints={breakpoints}
            className={style.item_slider}
            showSliderButtons={false}
            showSlidesOutsideContainer
            spaceBetween={16}
          >
            {slice.items.map((item, index) => (
              <div className={style.item} key={index}>
                {item.image.url && (
                  <figure
                    className={classNames(style.image, {
                      [style.image__custom_width]: !!itemImageCustomWidth,
                    })}
                    style={{
                      alignSelf: textAlign(
                        slice.primary?.content_horizontal_align as string,
                      ),
                      maxWidth: `${itemImageCustomWidth}px`,
                    }}
                  >
                    <PrismicNextImage
                      fallbackAlt=""
                      field={item?.image}
                      style={{ objectFit: 'contain' }}
                    />
                  </figure>
                )}
                <div className={style.item_content}>
                  {item.title && (
                    <p
                      className={style.title}
                      style={{
                        color: slice.primary.item_title_color as string,
                      }}
                    >
                      {item.title}
                    </p>
                  )}
                  {item.description && (
                    <p
                      className={style.description}
                      style={{
                        color: slice.primary.item_description_color as string,
                      }}
                    >
                      {item.description}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </DefaultComponentSection>
  );
};
