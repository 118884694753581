import { FC } from 'react';
import classNames from 'classnames';

import styles from './RefinementList.module.scss';

interface RefinementListItemProps {
  value: string;
  isChecked: boolean;
  handleChange: (_value: string) => void;
  className?: string;
}

const RefinementListItem: FC<RefinementListItemProps> = ({
  value,
  isChecked,
  handleChange,
  className,
}) => (
  <label
    className={classNames(styles.filters_list_item, className)}
    htmlFor={value}
  >
    <input
      checked={isChecked}
      id={value}
      name={value}
      onChange={() => handleChange(value)}
      type="checkbox"
    />
    <span className={styles.filters_list_item__label}>{value}</span>
  </label>
);

export { RefinementListItem };
