'use client';

import { useState } from 'react';
import { get, isEqual, set } from 'lodash';

export function useForm(initialState) {
  const [inputs, setInputs] = useState(initialState ?? {});

  const setInput = (key, value) => {
    const oldValue = get(inputs, key);

    if (isEqual(oldValue, value)) {
      return;
    }

    setInputs(inputs => {
      const newInputs = { ...inputs };

      set(newInputs, key, value);

      return newInputs;
    });
  };

  const genericChange = (key, value) => setInput(key, value);

  const inputChange = e => {
    const { name, value, type, checked } = e.target;
    const isCheckbox = type === 'checkbox';

    setInput(name, isCheckbox ? checked : value);
  };

  const dateChange = e => {
    const { name, value } = e.target;

    if (value.length > 10) {
      return;
    }

    setInput(name, value);
  };

  const selectValue = (options, value) =>
    options.find(option => option.value === value);

  return {
    dateChange,
    genericChange,
    inputChange,
    inputs,
    selectValue,
    setInputs,
  };
}

export function useValidator(rules, inputs = {}) {
  const [isSubmited, setIsSubmited] = useState(false);
  const validatorLib = require('validator');

  const forceValid = () => {
    const validation = {
      isInvalid: false,
    };

    rules.forEach(rule => {
      validation[rule.field] = { isInvalid: false };
    });

    return validation;
  };

  const validate = () => {
    // start out assuming valid.
    const validation = forceValid();

    try {
      rules.forEach(rule => {
        // if the field hasn't already been marked
        // invalid by an earlier rule.
        if (!validation[rule.field].isInvalid) {
          // determine the field value, the method to invoke
          // and optional args from the rule definition.
          const value = `${inputs[rule.field] ?? ''}`;
          const args = rule.args ?? [];
          const method =
            typeof rule.method === 'string'
              ? validatorLib[rule.method]
              : rule.method;

          // Call the validation method with the current field value as the first
          // argument, any additional arguments, and the whole state as a final
          // argument. If the result doesn't match the rule.validWhen property,
          // then set the isInvalid field to false and modify the validation
          // object for the field.

          if (
            method &&
            method(value, ...args, { ...inputs }) !== rule.validWhen
          ) {
            validation[rule.field] = {
              isInvalid: true,
              message: rule.message,
            };
          }
        }
      });

      return validation;
    } catch (e) {
      console.log(e);
    }

    return validation;
  };

  const validation = validate();
  const isInvalid = Object.values(validation).some(rule => rule.isInvalid);
  const validator = isSubmited ? validation : forceValid();

  return { isInvalid, isSubmited, setIsSubmited, validator };
}
