import { FC, RefObject, useEffect, useState } from 'react';
import classNames from 'classnames';
import { asText } from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';

import { Container, Heading, Link, useData } from '@/components';
import { getCSSpropety } from '@/utils';
import { useIsServerSide } from '@/hooks';

import styles from './megamenucustom.module.scss';

interface IMegaMenuCustomProps {
  itemID: string;
  className?: string;
  navbarRef: RefObject<HTMLElement | null>;
}

const MegaMenuCustom: FC<IMegaMenuCustomProps> = ({
  itemID,
  className,
  navbarRef,
}) => {
  const { navigation } = useData();
  const [top, setTop] = useState<number | null>(null);
  const menuTop = getCSSpropety('top', top);
  const { isServerSide } = useIsServerSide();

  useEffect(() => {
    const handleScroll = () => {
      if (!navbarRef?.current) return;
      const navbarBottom = navbarRef.current.getBoundingClientRect().bottom;
      setTop(navbarBottom);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const megaNav: any = navigation?.data?.links?.find((item: any) => {
    const { uid, type } = item.external_link;
    return uid === itemID && type === 'custom_mega_menu';
  });

  if (!megaNav) return null;

  const body = megaNav?.external_link?.data?.slices;

  if (body?.length === 1 && body?.[0]?.slice_type === 'group_link') {
    const groupLink = body?.[0]?.items;

    return (
      <div className={styles.megamenu_single}>
        <ul
          className={classNames(styles.megamenu_single__content, className)}
          data-type="single"
          role="banner"
        >
          {groupLink.map((link, index) => (
            <li key={index}>
              <Link href={link?.link} replace>
                {link?.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (isServerSide) return null;

  const groupsPictures = (
    body?.filter(item => item?.slice_type === 'group_picture') || []
  ).slice(0, 2);

  const groupsLinks = (
    body?.filter(item => item?.slice_type === 'group_link') || []
  ).slice(0, groupsPictures.length === 2 ? 1 : 3);

  return (
    <aside
      className={classNames(styles.megamenu, className)}
      data-type="mega"
      role="banner"
      style={menuTop}
    >
      <Container
        as="section"
        className={classNames(styles.megamenu__container, {
          [styles.megamenu__container__doble_pictures_section]:
            groupsPictures.length === 2,
        })}
      >
        {groupsLinks.map((item, index) => (
          <div key={item.id} style={{ gridArea: `group-link${index}` }}>
            <Heading className={styles.title} level={6}>
              {asText(item?.primary?.group_link_label)}
            </Heading>
            <ul className={styles.links_content}>
              {item?.items?.map((link, index) => (
                <li key={index}>
                  <Link href={link?.link} replace>
                    {link?.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
        {groupsPictures.map((item, index) => (
          <div
            className={styles.pictures}
            key={item.id}
            style={{ gridArea: `group-picture${index}` }}
          >
            <Heading className={styles.title} level={6}>
              {item?.primary?.title}
            </Heading>
            <div className={styles.pictures_content}>
              {item?.items?.map((picture, index) => (
                <Link href={picture?.link} key={index} replace>
                  <figure className={styles.pictures_content__figure}>
                    <PrismicNextImage
                      fallbackAlt=""
                      field={picture?.picture}
                      height={picture?.picture?.dimensions?.height}
                      width={picture?.picture?.dimensions?.width}
                    />
                  </figure>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </Container>
    </aside>
  );
};

export default MegaMenuCustom;
