'use client';

import { FC, Ref, useEffect, useRef } from 'react';
import {
  getBundledProducts,
  getProductBundleSpecial,
  getProductItemLocId,
  getSlicePadding,
} from '@/utils';
import { useIsServerSide, useProducts, useSelectedStore } from '@/hooks';
import { Container, sortVariantByPrice } from '@/components';
import classNames from 'classnames';
import { AdsSlice, CartLineItem, JaneSpecial, Variant } from '@/types';
import styles from './ads.module.scss';
import { MenuService, useEcomStoreSelector, useUiStore } from '@/data';
import { useSpecialsContext } from '@/context';

import { SurfsideBanner } from './SurfBanner';
import { SurfsideVideo } from './SurfVideo';

/*
 *** DOC ***
 *** https://docs.surfside.io/commerce-media/enabling-ads ***
 */

const Ads: FC<AdsSlice> = ({ primary, variation }) => {
  const { store } = useSelectedStore();
  const [, setState] = useUiStore();
  const { specials } = useSpecialsContext();
  const { add } = useProducts();
  const { treez_store_name } = useEcomStoreSelector(['treez_store_name']);
  const { isServerSide } = useIsServerSide();
  const contentRef = useRef<HTMLElement>(null);
  const adRef = useRef<HTMLDivElement>(null);
  const paddingSection = primary?.padding_section;
  const spacingBottom = primary?.bottom_spacing;
  const accountId = process?.env?.NEXT_PUBLIC_SURFSIDE_ACCOUNT_ID ?? '';
  const channelId = process?.env?.NEXT_PUBLIC_SURFSIDE_CHANNEL_ID ?? '';
  const siteId = process?.env?.NEXT_PUBLIC_SURFSIDE_SOURCE_ID ?? '';

  useEffect(() => {
    if (variation !== 'productCarousel') {
      return;
    }

    const addToCart = async e => {
      if (!e.data) {
        return;
      }

      if (e?.data?.event === 'addToCart') {
        const id = e?.data?.data?.item_id;
        const { searchProducts } = new MenuService();
        const { products } = await searchProducts({
          filters: { productId: { values: [id] } },
        });
        const prod = products?.[0];

        if (!prod) {
          return;
        }

        const bundleSpecial = getProductBundleSpecial(
          specials,
          prod,
        ) as JaneSpecial;

        const bundleProducts = await getBundledProducts(
          bundleSpecial,
          prod?.id ?? '',
        );

        const weightVariant = sortVariantByPrice(
          prod?.variants as Variant[],
        )[0];

        const itemLocId = getProductItemLocId(treez_store_name, weightVariant);

        const item: CartLineItem = {
          ...prod,
          _id: `${prod.id}-${Math.random()}`,
          bundleProducts,
          bundleSpecial,
          itemLocId,
          quantity: 1,
          weightVariant,
        };

        add(item);
        setState({ is_cart_open: true });
      }
    };

    window.addEventListener('message', addToCart, false);

    return () => {
      window.removeEventListener('message', addToCart);
    };
  }, []);

  // useEffect(() => {
  //   if (!adRef?.current) {
  //     return;
  //   }

  //   const style = document.createElement('style');
  //   style.textContent = `
  //       .watermark {
  //         background: rgba(255, 255, 255, 0.7);
  //         position: absolute;
  //         right: -115px;
  //         width: 142px;
  //         bottom: 0;
  //         z-index: 0;
  //         padding: 3px 2px 2px 3px;
  //         border-radius: 5px 0 0 5px !important;
  //         transition: right 300ms ease-out;
  //       }
  //     `;

  //   const shadowRoot = adRef?.current?.shadowRoot;

  //   if (!shadowRoot) {
  //     contentRef.current!.style.display = 'none';
  //     return;
  //   }

  //   contentRef.current!.style.display = 'block';
  //   shadowRoot.appendChild(style);
  // }, [adRef.current]);

  if (!primary?.active || isServerSide) {
    return null;
  }

  const renderAd = () => {
    switch (variation) {
      case 'video':
        return (
          <SurfsideVideo
            accountId={accountId}
            channelId={channelId}
            height={primary?.height!}
            locationId={store?.shortName ?? ''}
            maxDuration={primary?.max_duration as number}
            minDuration={primary?.min_duration ?? undefined}
            siteId={siteId}
            style={{ textAlign: 'center' }}
            width={primary?.width!}
            zoneId={primary?.zone_id!}
          />
        );
      case 'productCarousel':
        return (
          <surf-carousel
            account-id={accountId}
            card-max-width={primary?.card_max_width ?? 400}
            card-min-width={primary?.card_min_width ?? 350}
            catalog={'true'}
            channel-id={channelId}
            location-id={store?.shortName ?? ''}
            max-items={primary?.max_items ?? 23}
            max-page={primary?.max_page ?? 7}
            next-type={primary?.next_type ?? ''}
            recommend={primary?.recommend}
            ref={adRef}
            site-id={siteId}
            strategy={primary?.strategy}
            zone-id={primary?.zone_id!}
          />
        );
      default:
        return (
          <SurfsideBanner
            accountId={accountId}
            channelId={channelId}
            height={primary.height!}
            locationId={store?.shortName ?? ''}
            siteId={siteId}
            style={{ textAlign: 'center' }}
            width={primary?.width!}
            zoneId={primary?.zone_id as string}
          />
        );
    }
  };

  return (
    <Container
      as={'section'}
      className={classNames(getSlicePadding(paddingSection), {
        [styles.spacing_bottom]: spacingBottom,
      })}
      ref={contentRef as Ref<HTMLDivElement> | undefined}
    >
      {renderAd()}
    </Container>
  );
};

export default Ads;
