import { FC, Ref, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  addressAsString,
  buildAddressFromComponents,
  Button,
  Icon,
  IconSvg,
  ILocalAddress,
  useData,
} from '@/components';
import { useEcomStoreSelector, useUiStore, useUiStoreSelector } from '@/data';
import { Geoloc, HeaderSlice, TreezStore as TreezStoreType } from '@/types';
import {
  compareDistances,
  currencyFormat,
  getDeliveryScheduleByAddress,
  mapStoreToDistance,
} from '@/utils';
import {
  useCurrentMinimumAmount,
  useIsServerSide,
  useSelectedStore,
} from '@/hooks';
import { useStoreLocatorContext } from './context';

import { DeliverySection } from './DeliverySection.components';
import { PickupSection } from './PickupSection.components';

import styles from './storelocator.module.scss';
import { StoreRadioItem } from '../TreezStore/TreezStore.components';

const StoreLocatorButton = () => {
  const { isServerSide } = useIsServerSide();
  const [storeAddress, setStoreAddress] = useState('');
  const buttonLabel = process?.env?.NEXT_PUBLIC_STORE_LOCATOR_LABEL;

  const {
    header: { data: header },
  } = useData();

  const activeSlice = (header?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );

  const { is_store_location_open, setState } = useUiStoreSelector([
    'is_store_location_open',
  ]);

  const { delivery_details, provider_inventory_location_id, delivery_address } =
    useEcomStoreSelector([
      'delivery_details',
      'provider_inventory_location_id',
      'delivery_address',
    ]);

  const { store: selectedStore } = useSelectedStore();
  const { currentMinAmount } = useCurrentMinimumAmount();

  useEffect(() => {
    if (delivery_details.pickup && selectedStore) {
      setStoreAddress(selectedStore?.full_address);
      return;
    }

    if (delivery_details.delivery && delivery_address) {
      setStoreAddress(addressAsString(delivery_address as ILocalAddress));
    }
  }, [
    delivery_details.delivery,
    delivery_details.pickup,
    delivery_address,
    selectedStore,
  ]);

  return (
    <Button
      className={classNames(styles.store_locator__button, {
        [styles.alternate_header_variation]:
          activeSlice?.variation === 'withCenteredLogoAndLocatorFix',
      })}
      onClick={() =>
        setState({
          is_store_location_open: !is_store_location_open,
        })
      }
    >
      <div className={styles['button-content']}>
        <IconSvg name="marker" />
        <div className={styles.info}>
          {(isServerSide || !selectedStore) && (
            <span className={styles.method}>
              {buttonLabel ?? 'How do you want to shop?'}
            </span>
          )}
          {!isServerSide && selectedStore && (
            <span className={styles.method}>
              {delivery_details.pickup
                ? `Pickup at ${selectedStore?.name}`
                : `${
                    provider_inventory_location_id ? 'Express ' : ''
                  }Delivery to ${delivery_address.address1} ${
                    currentMinAmount == 0
                      ? ''
                      : `($${currencyFormat(currentMinAmount)} min)`
                  } `}
            </span>
          )}
          <span className={styles.address}>
            {storeAddress ?? 'Select your store'}
          </span>
        </div>
        <Icon name={'angle-down'} />
      </div>
    </Button>
  );
};

const StoreLocatorTop = () => {
  const [, setState] = useUiStore();
  const { onlyDelivery, onlyPickup } = useStoreLocatorContext();

  const panelTitle = onlyPickup
    ? 'Select a pickup location'
    : onlyDelivery
    ? 'Enter delivery address'
    : 'How do you want to shop?';

  return (
    <div className={styles.store__locator_top}>
      <div className={styles.store__locator_header}>
        <h6>{panelTitle}</h6>
        <Button
          className={styles.close_button}
          icon
          onClick={() => {
            setState({ is_store_location_open: false });
          }}
        >
          <Icon className={styles.close_icon} name="close" />
        </Button>
      </div>
      <ShopModeSelector />
    </div>
  );
};

interface IShopModeSelectorProps {
  className?: string;
}

const ShopModeSelector: FC<IShopModeSelectorProps> = ({ className }) => {
  const { deliveryAndPickup, isDelivery, setIsDelivery, setIsPickup } =
    useStoreLocatorContext();

  const handleDeliverySelection = () => {
    setIsDelivery(true);
    setIsPickup(false);
  };

  const handlePickupSelection = () => {
    setIsDelivery(false);
    setIsPickup(true);
  };

  if (!deliveryAndPickup) {
    return null;
  }

  return (
    <div className={classNames(className, styles.shop_mode)}>
      <div
        className={classNames(styles.shop_mode__background, {
          [styles.delivery]: isDelivery,
          [styles.pickup]: !isDelivery,
        })}
      ></div>
      <Button
        className={classNames(styles.delivery_mode, {
          [styles.selected]: isDelivery,
        })}
        onClick={handleDeliverySelection}
      >
        <IconSvg name="car" />
        <span>Delivery</span>
      </Button>
      <Button
        className={classNames(styles.pickup_mode, {
          [styles.selected]: !isDelivery,
        })}
        onClick={handlePickupSelection}
      >
        <IconSvg name="shopping" />
        <span>Pickup</span>
      </Button>
    </div>
  );
};

interface IStoreLocatorCenterProps {
  scrollListToTop: () => void;
  ref?: Ref<HTMLDivElement | null>;
}

const StoreLocatorCenter: FC<IStoreLocatorCenterProps> = ({
  scrollListToTop,
  ref: storesListRef,
}) => {
  const {
    isDelivery,
    isPickup,
    singleStore,
    onlyDelivery,
    onlyPickup,
    setDeliveryAddress,
    setSelectedStoreId,
    stores,
    setStores,
    setIsDeliveryAddressInvalid,
  } = useStoreLocatorContext();

  const [loadingLocation, setLoadingLocation] = useState(false);

  const showInstructionText = useMemo(
    () => !singleStore && !onlyDelivery && !onlyPickup,
    [singleStore, onlyDelivery, onlyPickup],
  );

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | undefined>(
    undefined,
  );

  const { store } = useData();

  const onClickUseCurrentLocation = () => {
    if (!navigator.geolocation) {
      inputRef.current?.focus();
      return;
    }

    const onSuccess = async position => {
      const { latitude: lat, longitude: lng } = position.coords;
      const geocoder = new google.maps.Geocoder();

      try {
        setDeliveryAddress(undefined);

        if (isPickup) {
          // the stores are sorted also selected store
          // and moved to the top
          sortStoreByLatAndLngAndSelectStore(stores, { lat, lng });
        }

        geocoder.geocode(
          {
            location: {
              lat,
              lng,
            },
          },
          res => {
            const bestResult =
              res?.find(result => result.types.includes('street_address')) ??
              res?.[0];

            if (!bestResult) {
              onError('Unable to get the current location.');
              return;
            }

            const builtAddress = buildAddressFromComponents(bestResult);

            // if is pickup, set the delivery address and return the store is selected on
            // sortStoreByLatAndLngAndSelectStore method above
            if (isPickup) {
              setDeliveryAddress({
                ...builtAddress,
                latitude: lat,
                longitude: lng,
              });

              setLoadingLocation(false);
              return;
            }

            // if is delivery, check if the zip code is valid and
            // select a delivery zone
            const ds = getDeliveryScheduleByAddress(builtAddress, store);

            if (!ds) {
              setLoadingLocation(false);
              setIsDeliveryAddressInvalid(true);
              return;
            }

            setSelectedStoreId(ds?.stores_provider_id);
            setDeliveryAddress({
              ...builtAddress,
              latitude: lat,
              longitude: lng,
            });

            setLoadingLocation(false);
          },
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        onError(e);
      }
    };

    const onError = error => {
      // eslint-disable-next-line no-console
      console.log(error);

      setLoadingLocation(false);
      setIsDeliveryAddressInvalid(false);
    };

    setLoadingLocation(true);
    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 5000,
    });
  };

  const sortStoreByLatAndLngAndSelectStore = (
    stores: TreezStoreType[],
    currentLocation: Geoloc,
  ) => {
    Promise.all(stores.map(mapStoreToDistance(currentLocation)))
      .then(stores => {
        const sortedStores = stores.sort(compareDistances);

        setSelectedStoreId(sortedStores[0].shortName);

        scrollListToTop();
        setStores(sortedStores);
        setLoadingLocation(false);
      })
      .catch(() => {
        setLoadingLocation(false);
      });
  };

  return (
    <div className={styles.store_locator_center} ref={storesListRef}>
      {isDelivery && (
        <DeliverySection
          handleUseCurrentLocation={onClickUseCurrentLocation}
          loadingLocation={loadingLocation}
          ref={inputRef as Ref<HTMLInputElement>}
          showInstructionText={showInstructionText}
        />
      )}

      {isPickup && (
        <PickupSection
          handleUseCurrentLocation={onClickUseCurrentLocation}
          loadingLocation={loadingLocation}
          showInstructionText={showInstructionText}
        />
      )}
    </div>
  );
};

interface IStoreExternalItemProps {
  isOpen: boolean;
  isSelected: boolean;
  store: any;
  // eslint-disable-next-line no-unused-vars
  onSelected: (id?: string) => void;
}

const StoreExternalItem: FC<IStoreExternalItemProps> = ({
  isOpen,
  isSelected,
  store,
  onSelected,
}) => {
  const handleStoreSelected = async () => {
    onSelected(store?.shortName);
  };

  return (
    <StoreRadioItem
      isOpen={isOpen}
      isSelected={isSelected}
      onSelected={handleStoreSelected}
      store={store}
    />
  );
};

// this component is used to create an overlay when the store locator
// is open to force the user to select an store
const Overlay = () => {
  const { showOverlay } = useStoreLocatorContext();

  return showOverlay ? <div className={styles.store_locator__overlay} /> : null;
};

export {
  StoreLocatorTop,
  StoreLocatorButton,
  ShopModeSelector,
  StoreLocatorCenter,
  Overlay,
  StoreExternalItem,
};
