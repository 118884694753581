/* eslint-disable max-len */
import { CSSProperties, FC } from 'react';
import { Heading } from '@/components';
import { IconsRowWithTitleAndDescriptionSliceDefault as IconRow } from '@/types';
import styles from './iconsrowwithtitleanddescription.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';

const IconsRowWithTitleAndDescription: FC<IconRow> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const iconsPerRow = slice?.primary?.icons_per_row ?? 3;
  const cardMaxWidth = slice?.primary?.card_max_width ?? 300;
  const iconsWidth = slice?.primary?.icons_widht ?? 80;
  const realIconsPerRow = iconsPerRow > 3 ? 'auto-fill' : iconsPerRow;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(styles.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        background: slice?.primary?.background_color ?? '',
        color: slice?.primary?.text_color ?? '',
        textAlign: slice?.primary?.text_align,
      }}
    >
      <div className={styles.content}>
        {slice?.primary?.title && (
          <Heading color={slice?.primary?.text_color ?? ''} level={1}>
            {slice?.primary?.title?.[0]?.text}
          </Heading>
        )}
        {slice?.primary?.description && (
          <p className={styles.content__header_description}>
            {/* {slice?.primary?.description?.[0]?.text} */}
          </p>
        )}
        <div
          className={classNames(
            styles.logos,
            'iconsrowwithtitleanddescription_logos__grid',
          )}
        >
          {slice?.items?.map((item, index) => {
            return (
              <div className={styles.logos__logo} key={index}>
                <p className={styles.logos__logo__title}>{item?.logo_title}</p>
                <p>{item?.logo_description}</p>
                <figure
                  style={
                    {
                      alignSelf: slice?.primary?.text_align,
                      '--icons-width': `${iconsWidth}px`,
                    } as CSSProperties
                  }
                >
                  <PrismicNextImage
                    fallbackAlt=""
                    field={item?.logo}
                    height={256}
                    style={{
                      objectFit: 'contain',
                    }}
                    title={item?.logo_title ?? ''}
                    width={256}
                  />
                </figure>
              </div>
            );
          })}
        </div>
        <style>
          {`
          .iconsrowwithtitleanddescription_logos__grid{
            @media (min-width: 1280px) {
                grid-template-columns: repeat(${realIconsPerRow}, minmax(${cardMaxWidth}px, 1fr)) !important;
              }
            }
        `}
        </style>
      </div>
    </section>
  );
};

export default IconsRowWithTitleAndDescription;
