/* eslint-disable max-len */
import { useMemo } from 'react';

import { useData } from '@/components';
import { getExternalStores } from '@/utils';

const useShoppingMode = () => {
  const { treezStores } = useData();
  const externalStores = getExternalStores();

  const total = treezStores.length + externalStores?.length ?? 0;

  const multiStore = total > 1;
  const singleStore = total === 1;

  const onlyDelivery = useMemo(
    () => treezStores.every(({ delivery, pickup }) => delivery && !pickup),
    [treezStores],
  );
  const onlyPickup = useMemo(
    () => treezStores.every(({ delivery, pickup }) => pickup && !delivery),
    [treezStores],
  );
  const deliveryAndPickup = !onlyPickup && !onlyDelivery;

  return {
    deliveryAndPickup,
    multiStore,
    onlyDelivery,
    onlyPickup,
    singleStore,
  };
};

export { useShoppingMode };
