import { FC } from 'react';
import {
  Heading,
  Icon,
  Link,
  Product,
  ProductPlaceholder,
  Slider,
} from '@/components';
import {
  useFeaturedFlag,
  useSelectedStore,
  useSpacingBetweenProductCard,
} from '@/hooks';
import { MenuService } from '@/data';
import { IProductRowSlice } from '@/types';
import { GProduct, LastKey } from '@/types';

import { useQuery } from '@tanstack/react-query';

import classNames from 'classnames';
import styles from './productrow.module.scss';
import { asText } from '@prismicio/client';

const ProductsRow: FC<IProductRowSlice> = slice => {
  const {
    breakpoints,
    defaultSlidePerView,
    defaultSpaciBetween,
    spacing_between_card,
  } = useSpacingBetweenProductCard();
  const { store } = useSelectedStore();

  const initProducts = slice?.products ?? [];
  const lastKey = slice?.lastKey;

  const { isProductCardLargImage } = useFeaturedFlag();

  const isFilterByStore = slice?.primary?.filter_by_store;

  const queryKey = ['searchProducts_product_row', store?.shortName];

  const { searchProducts } = new MenuService();

  const { data, isLoading } = useQuery({
    enabled: isFilterByStore,
    initialData: {
      products: initProducts,
      lastKey,
    },
    queryKey,
    queryFn: async (): Promise<{
      products: GProduct[];
      lastKey: LastKey;
    }> => {
      const ids = slice?.items?.map(item =>
        item?.product_id?.trim(),
      ) as string[];

      const data = await searchProducts({
        filters: { productId: { values: ids } },
      });

      return data;
    },
  });

  const productsCollection = data?.products;

  if (!slice?.primary?.active || !productsCollection?.length) {
    return null;
  }

  return (
    <Slider
      activatedHover
      breakpoints={breakpoints}
      centeredSlides={false}
      freeMode
      isNotPaddingMobile={spacing_between_card === 'none'}
      seeAllElement={
        <Link className={styles.products__see_all} href={'/deals'}>
          SHOP ALL <span>({initProducts.length})</span>
        </Link>
      }
      showSliderCardLink={true}
      sliderHeading={
        <Heading level={2}>{asText(slice?.primary?.products_header)}</Heading>
      }
      slidesPerView={defaultSlidePerView}
      spaceBetween={defaultSpaciBetween}
    >
      {isLoading
        ? new Array(20)
            .fill(null)
            .map((_, index) => (
              <ProductPlaceholder
                key={index}
                largeCard={isProductCardLargImage}
              />
            ))
        : productsCollection?.map(product => (
            <Product
              className={styles.slide__main}
              key={product.id}
              product={product}
            />
          ))}
      <Link
        className={classNames(
          styles.slide__main,
          styles.product__shop_all_slider,
        )}
        href={'/deals'}
      >
        <Icon name="right" />
        <p>See All</p>
      </Link>
    </Slider>
  );
};
export default ProductsRow;
