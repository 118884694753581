import { FC } from 'react';
import { Heading } from '@/components';
import { CenteredTextSlice } from '@/types';
import { PrismicRichText } from '@prismicio/react';
import styles from './centeredtext.module.scss';
import {
  getMaxWidthForDevice,
  getSlicePadding,
  htmlSerializerWithProps,
} from '@/utils';
import classNames from 'classnames';
import { useScreenWidth } from '@/hooks';

const CenteredText: FC<CenteredTextSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;

  const textMaxWidthDesktop = slice?.primary?.text_max_width_desktop;
  const textMaxWidthMobile = slice?.primary?.text_max_width_mobile;
  const headingMaxWidthDesktop = slice?.primary?.heading_max_width_desktop;
  const headingMaxWidthMobile = slice?.primary?.heading_max_width_mobile;

  const { width } = useScreenWidth();

  const textMaxWidth = getMaxWidthForDevice(
    width,
    textMaxWidthDesktop!,
    textMaxWidthMobile!,
  );

  const headingMaxWidth = getMaxWidthForDevice(
    width,
    headingMaxWidthDesktop!,
    headingMaxWidthMobile!,
  );

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), 'content', {
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        background: slice?.primary?.background ?? '',
        color: slice?.primary?.font_color ?? '',
        textAlign: slice?.primary?.text_align,
      }}
    >
      <div className={styles.container}>
        <Heading
          color={slice?.primary?.font_color ?? ''}
          level={1}
          style={{ maxWidth: headingMaxWidth, marginBottom: '16px' }}
        >
          {slice?.primary?.heading?.[0]?.text}
        </Heading>

        <PrismicRichText
          components={htmlSerializerWithProps({
            maxWidth: textMaxWidth,
          })}
          field={slice?.primary?.text}
        />
      </div>
    </section>
  );
};

export default CenteredText;
