'use client';
/* eslint-disable max-len */
import { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

import {
  Ads,
  Button,
  Close,
  Icon,
  IconSvg,
  Link,
  useAuth,
  useData,
} from '@/components';
import {
  useAnalytics,
  useEcomStoreSelector,
  useUiStoreSelector,
  useUpdateTreezCart,
} from '@/data';
import {
  useCurrentMinimumAmount,
  useFeaturedFlag,
  useIsServerSide,
  useSelectedStore,
  useSummary,
} from '@/hooks';
import {
  checkOrderForCustomDeliveryFee,
  currencyFormat,
  getTaxValue,
  mapLineItems,
} from '@/utils';
import { AdsSlice, TreezSpecial } from '@/types';
import CartBody from './CartBody';
import style from './cartnavigation.module.scss';
import { SummaryTotalSkeleton } from './SummaryTotalSkeleton';
import Discount from './Discount';

const ALLOW_GUEST_CHECKOUT =
  process.env.NEXT_PUBLIC_ALLOW_GUEST_CHECKOUT === 'true';

const GO_TO_CHECKOUT_BTN_LABEL = 'Proceed to checkout';

const CartNavigation: FC = () => {
  const { goToSignIn, goToCheckout, isAuth } = useAuth();
  const { isServerSide } = useIsServerSide();
  const { isTaxAppliedMessage: displayPostTax } = useFeaturedFlag();

  const {
    berbix_verified,
    line_items,
    treez_store_name,
    delivery_details,
    delivery_address,
    entity_id,
    setState: setStoredState,
  } = useEcomStoreSelector([
    'berbix_verified',
    'treez_store_name',
    'line_items',
    'delivery_details',
    'delivery_address',
    'entity_id',
  ]);

  const { is_cart_open, setState } = useUiStoreSelector(['is_cart_open']);
  const { apps, shoppingCart } = useData();
  const {
    serverSubtotal,
    preDiscountSubtotal,
    subtotal,
    totalGrams,
    tax,
    discounts,
    total,
  } = useSummary();
  const { currentMinAmount, delivery_schedules } = useCurrentMinimumAmount();
  const { store } = useSelectedStore();

  const isBerbixAppEnabled = useMemo(
    () => !!apps?.find(({ handler }) => handler === 'berbix'),
    [apps],
  );
  const needsBerbixValidation = useMemo(
    () => isBerbixAppEnabled && delivery_details && !berbix_verified,
    [delivery_details, berbix_verified],
  );
  const { measureViewCartGA4, measureSurfsideAction } = useAnalytics();

  // local state
  const [loading, setLoading] = useState(false);
  const [failedToLoadCartTotals, setFailedToLoadCartTotals] = useState(false);

  const [specials, setSpecials] = useState<TreezSpecial[] | undefined>(
    undefined,
  );
  const [checkoutValidation, setCheckoutValidation] = useState({
    disabled: false,
    label: GO_TO_CHECKOUT_BTN_LABEL,
  });

  const heading = shoppingCart?.heading ?? 'Shopping cart';
  const buttonColor = shoppingCart?.button_color ?? 'primary';
  const ad = shoppingCart?.slices?.find((a: AdsSlice) => a?.primary?.active);

  // this update treez cart is only to calculate the totals
  // and display it in the cart

  const order = {
    entity_id: entity_id,
    email: '', // in the future we will have the email here so we can also display group discounts at the cart level
    line_items: mapLineItems(line_items),
    provider_store_full_address: store?.full_address,
    provider_store_id: store?.shortName,
    provider_store_name: store?.name,
    provider_store_phone_number: store?.phone,
    delivery_details,
    delivery_address,
  };

  checkOrderForCustomDeliveryFee({ delivery_schedules }, order, store);

  const {
    updateCart,
    result: { loading: isFetchingTotals },
  } = useUpdateTreezCart({
    variables: {
      input: {
        order: order,
      },
    },
    onCompleted: data => {
      const res = data?.updateTreezCart;
      if (!res) {
        return;
      }

      const { status, specials, order } = res;

      if (!status) {
        setFailedToLoadCartTotals(true);
        return;
      }

      // set the specials on the local state
      setSpecials(specials as any);

      // persist the order total values on the local state
      setStoredState({
        entity_id: order?.entity_id as string,
        total_discounts: order?.total_discounts as number,
        total_tax: order?.total_tax as number,
        total_price: order?.total_price as number,
        subtotal_price: order?.subtotal_price as number,
      });

      setFailedToLoadCartTotals(false);
    },
    onError(error) {
      // eslint-disable-next-line no-console
      console.error(error?.message);
      setFailedToLoadCartTotals(true);
    },
  });

  // validate the delivery minimum amount
  useEffect(() => {
    if (!treez_store_name) {
      return;
    }

    if (delivery_details?.pickup) {
      setCheckoutValidation({
        disabled: false,
        label: GO_TO_CHECKOUT_BTN_LABEL,
      });
      return;
    }

    if (currentMinAmount === 0) {
      setCheckoutValidation({
        disabled: false,
        label: GO_TO_CHECKOUT_BTN_LABEL,
      });
      return;
    }

    setCheckoutValidation({
      disabled: currentMinAmount > subtotal,
      label:
        currentMinAmount > subtotal
          ? `$${currencyFormat(currentMinAmount)} Min For Delivery`
          : GO_TO_CHECKOUT_BTN_LABEL,
    });
  }, [delivery_details, subtotal, treez_store_name, currentMinAmount]);

  // Go to checkout button event
  const handleProceed = async () => {
    if (!ALLOW_GUEST_CHECKOUT && (!isAuth || needsBerbixValidation)) {
      setState({ is_cart_open: false });
      setStoredState({ checkout_identity_verification: true });
      await goToSignIn();
      return;
    }

    setLoading(true);

    await goToCheckout();
    setState({ is_cart_open: false });
    setLoading(false);
  };

  const goToStoreSelection = event => {
    event.stopPropagation();
    setState({ is_cart_open: false, is_store_location_open: true });
  };

  useEffect(() => {
    if (is_cart_open && line_items?.length) {
      measureViewCartGA4(line_items);
      measureSurfsideAction(line_items, 'cart');
    }
  }, [is_cart_open]);

  useEffect(() => {
    if (!is_cart_open) return;

    const timeout = setTimeout(() => {
      if (line_items?.length) {
        updateCart();
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [is_cart_open, line_items]);

  if (isServerSide) return null;

  const displayTotals =
    line_items?.length > 0 && !isFetchingTotals && !failedToLoadCartTotals;

  const displayTaxDiscountAtCheckout =
    line_items?.length > 0 && !isFetchingTotals && failedToLoadCartTotals;

  return (
    <div
      className={classNames(style.cart_nav, {
        [style.cart_nav__open]: is_cart_open,
      })}
    >
      <div className={style.cart_nav__container}>
        <div className={style.cart_nav__body}>
          <div className={style.cart__header}>
            <div className={style.cart__shopping}>
              <Link href="/" onClick={() => setState({ is_cart_open: false })}>
                <h2 className={style.cart__shopping_link}>{heading}</h2>
              </Link>
              <Close onClick={() => setState({ is_cart_open: false })} />
            </div>
            <div className={style.cart__shopping_at}>
              {store ? (
                <>
                  <figure>
                    <IconSvg name="store" />
                  </figure>
                  <div>
                    <p className={style.shopping__title}>Shopping at</p>
                    <p className={style.shopping__store}>
                      {store.name} - {store.full_address}
                    </p>
                  </div>
                </>
              ) : (
                <Button
                  className={style.select_store_button}
                  onClick={goToStoreSelection}
                >
                  Select your store
                </Button>
              )}
            </div>
          </div>
          {line_items?.length == 0 && (
            <div className={style.cart__empty}>
              {/* <Icon className={style.empty_cart_icon} name="empty-cart-icon" /> */}
              <figure className={style.empty_cart_icon}>
                <IconSvg name="empty-shopping-cart" />
              </figure>
              <p style={{ width: '100%' }}>
                Your bag is empty <br /> Let’s go shopping now!
              </p>
            </div>
          )}
          {line_items?.length > 0 && <CartBody products={line_items} />}
        </div>
        {ad && <Ads {...ad} />}
        <div className={style.cart__footer}>
          <div className={style.footer__current_shopping}>
            <div className={style.footer__grams_container}>
              <span className={style.heading}>
                Total Weight{' '}
                <Popover className={style.footer__help}>
                  <PopoverButton aria-label="Show info">
                    <Icon name="help" />
                  </PopoverButton>
                  <PopoverPanel className={style.footer__help_body}>
                    <div className={style.peak}></div>
                    State and local laws around the delivery service and who can
                    order delivery vary. The actual marijuana delivery service
                    may need to be registered separately with the state, and
                    delivery time and possession limits may apply
                  </PopoverPanel>
                </Popover>
              </span>
              <span className={style.grams}>{totalGrams?.toFixed(2)} G</span>
            </div>

            {/* Subtotal */}
            <div
              className={classNames(style.footer__grams_container, {
                [style.footer__grams_container_subtotal]:
                  isFetchingTotals || failedToLoadCartTotals,
              })}
            >
              <span className={style.heading}>Subtotal</span>
              <span className={style.grams}>
                $
                {`${
                  displayTotals
                    ? currencyFormat(serverSubtotal)
                    : currencyFormat(preDiscountSubtotal)
                }`}
              </span>
            </div>

            {/* Loading Skeleton */}
            {isFetchingTotals && <SummaryTotalSkeleton />}

            {displayTotals && (
              <>
                {/* Tax */}
                <div className={style.footer__grams_container}>
                  <span className={style.heading}>Taxes</span>

                  <span className={style.grams}>
                    {getTaxValue(displayPostTax, tax)}
                  </span>
                </div>

                {/* Discounts */}
                {discounts > 0 && (
                  <Discount discounts={discounts} specials={specials} />
                )}

                {/* Totals */}
                <p>
                  Total payment{' '}
                  <span className={style.subtotal}>
                    ${currencyFormat(total)}
                  </span>
                </p>
              </>
            )}

            {displayTaxDiscountAtCheckout && (
              <small className={style.tax_info}>
                (tax and discounts applied at check-out)
              </small>
            )}

            <Button
              className={style.proceed}
              color={buttonColor}
              disabled={line_items?.length == 0 || checkoutValidation.disabled}
              loading={loading}
              onClick={handleProceed}
            >
              <div className={style.proceed_label}>
                {checkoutValidation.label}
                <Icon name="arrow-right" />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div
        className={style.cart_nav__overlap}
        onClick={() => setState({ is_cart_open: false })}
        role="button"
      />
    </div>
  );
};

export default CartNavigation;
