import {
  ReadonlyURLSearchParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { omit } from 'lodash';

import { CollectionQueryParams } from '@/types';
import { useCallback } from 'react';

const useUpdateQueryParams = () => {
  const { push } = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();

  const dynamicSegmentsQuery = getDynamicSegmentsQuery(
    pathname as string,
    query as ReadonlyURLSearchParams,
  );

  const updateQueryParams = useCallback(
    (queryParams: CollectionQueryParams) => {
      const query = {
        ...dynamicSegmentsQuery,
        /**
         * omit productId query because it's a parameter passed to product provider
         * initialization to get the products, and since it's a page with static
         * products, you don't need to display it as an active filter. the productId
         * filter cannot be removed for the same reason that they are static products
         */
        ...omit(queryParams, 'productId'),
      };

      setTimeout(() => {
        push(
          `${pathname}?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`,
        );
      }, 0);
    },
    [dynamicSegmentsQuery, pathname, query],
  );

  return { updateQueryParams };
};

const dynamicRouteSegmentRegex = /\[\w+\]/g;

const getDynamicSegmentsQuery = (
  pathname: string,
  query: ReadonlyURLSearchParams,
) => {
  const dynamicSegments =
    pathname
      .match(dynamicRouteSegmentRegex)
      ?.map(segment => segment.slice(1, -1)) ?? [];

  return dynamicSegments.reduce(
    (acc, segment) => ({ ...acc, [segment]: query.get(segment) }),
    {},
  );
};

export { useUpdateQueryParams };
